import { db, auth, storage } from '../firebase'
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  limit,
  writeBatch // Import writeBatch
} from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getUserById } from './User'

const USERS_COLLECTION = 'users'
const PROFILE_SUBCOLLECTION = 'profiles'
const EMPLOYER_PROFILE_DOC = 'employer'
const EMPLOYER_MEMBERS_COLLECTION = 'employerMembers'

// EmployerProfile data structure
const createEmployerProfileObject = (profileData) => {
  const profile = {
    plan_type: profileData.plan_type ?? 'freetrial',
    company_website: profileData.company_website ?? null,
    company_address: profileData.company_address ?? null,
    company_phone: profileData.company_phone ?? null,
    company_description: profileData.company_description ?? null,
    website: profileData.website ?? null,
    location: profileData.location ?? null,
    work_from_home_policy: profileData.work_from_home_policy ?? null,
    top_ranking: profileData.top_ranking ?? null,
    linkedin: profileData.linkedin ?? null,
    industry: profileData.industry ?? null,
    subindustry: profileData.subindustry ?? null,
    number_of_open_jobs: profileData.number_of_open_jobs ?? 0,
    open_jobs: profileData.open_jobs ?? [],
    number_of_hires: profileData.number_of_hires ?? 0,
    benefits: profileData.benefits ?? [],
    recent_news: profileData.recent_news ?? null,
    team: profileData.team ?? null,
    profile_picture_url: profileData.profile_picture_url ?? null,
    cover_photo_url: profileData.cover_photo_url ?? null,
    logo: profileData.logo ?? null,
    photos: profileData.photos ?? [],
    video: profileData.video ?? null,
    company_facebook: profileData.company_facebook ?? null,
    additional_url: profileData.additional_url ?? null,
    full_time_employees: profileData.full_time_employees ?? null,
    expected_full_time_hires: profileData.expected_full_time_hires ?? null,
    expected_part_time_hires: profileData.expected_part_time_hires ?? null,
    hiring_goals: profileData.hiring_goals ?? {},
    hiring_challenges: profileData.hiring_challenges ?? [],
    candidate_qualities: profileData.candidate_qualities ?? [],
    company_logo_url: profileData.company_logo_url ?? null,
    intake_completed: profileData.intake_completed ?? false,
    updated_at: serverTimestamp()
  }

  // Only include company_name if it's provided
  if (profileData.company_name) {
    profile.company_name = profileData.company_name
  }

  return profile
}

// // Update specific fields of an employer profile
// export const updateEmployerProfile = async (userId, updateData) => {
//   const profileRef = doc(
//     db,
//     USERS_COLLECTION,
//     userId,
//     PROFILE_SUBCOLLECTION,
//     EMPLOYER_PROFILE_DOC
//   )
//   await updateDoc(profileRef, { ...updateData, updated_at: serverTimestamp() })
// }
export const getEmployerMembers = async (userId) => {
  try {
    console.log('Fetching members for user:', userId)
    if (!userId) {
      throw new Error('Invalid userId provided')
    }

    // First, get the user's data to find the correct employer_id
    const userDoc = await getDoc(doc(db, USERS_COLLECTION, userId))
    if (!userDoc.exists()) {
      throw new Error('User document not found')
    }
    const userData = userDoc.data()
    const employerId = userData.employer_id || userId // Use employer_id if it exists, otherwise use userId

    console.log('Using employer_id:', employerId)

    const membersQuery = query(
      collection(db, USERS_COLLECTION),
      where('employer_id', '==', employerId),
      where('role', '==', 'employerMember')
    )
    const membersSnapshot = await getDocs(membersQuery)
    const members = membersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))
    console.log(`Fetched ${members.length} members:`, members)
    return members
  } catch (error) {
    console.error('Error fetching employer members:', error)
    throw error
  }
}

export const createOrUpdateEmployerProfile = async (profileData) => {
  try {
    const user = auth.currentUser
    if (!user) throw new Error('No authenticated user')

    console.log('Current user ID:', user.uid)

    // Fetch the user document
    const userDoc = await getDoc(doc(db, USERS_COLLECTION, user.uid))
    if (!userDoc.exists()) {
      throw new Error('User document not found')
    }
    const userData = userDoc.data()
    console.log('User data:', userData)

    let employerId = userData.employer_id || user.uid

    console.log('Employer ID being used:', employerId)

    // Create the updated profile object
    const updatedData = {
      ...profileData,
      updated_at: serverTimestamp(),
      intake_completed: true,
      employer_id: employerId // Ensure employer_id is included in the profile
    }
    console.log('Updated data:', updatedData)

    const batch = writeBatch(db)

    // Update the employer's profile
    const employerProfileRef = doc(
      db,
      USERS_COLLECTION,
      user.uid,
      PROFILE_SUBCOLLECTION,
      EMPLOYER_PROFILE_DOC
    )
    batch.set(employerProfileRef, updatedData, { merge: true })
    console.log('Employer profile ref path:', employerProfileRef.path)

    // Update the user document with the employer_id
    batch.update(doc(db, USERS_COLLECTION, user.uid), {
      employer_id: employerId
    })

    // Fetch all users with the same employer_id
    const usersQuery = query(
      collection(db, USERS_COLLECTION),
      where('employer_id', '==', employerId)
    )
    const usersSnapshot = await getDocs(usersQuery)
    console.log('Number of users found:', usersSnapshot.size)

    // Update profiles for all users with the same employer_id
    usersSnapshot.forEach((userDoc) => {
      if (userDoc.id !== user.uid) {
        // Don't update the current user again
        const userProfileRef = doc(
          db,
          USERS_COLLECTION,
          userDoc.id,
          PROFILE_SUBCOLLECTION,
          EMPLOYER_PROFILE_DOC
        )
        batch.set(userProfileRef, updatedData, { merge: true })
        console.log('Updating user profile:', userDoc.id)
      }
    })

    // Commit all the updates
    await batch.commit()
    console.log('Batch committed successfully')

    console.log('Employer profile updated successfully for all related users.')
    return employerProfileRef
  } catch (error) {
    console.error('Error in createOrUpdateEmployerProfile:', error)
    throw error
  }
}
// Fetch employer profile
export const getEmployerProfile = async (userId) => {
  console.log('Attempting to fetch profile for user:', userId)

  const userData = await getUserById(userId)
  if (!userData) {
    throw new Error('User not found')
  }

  const profileRef = doc(
    db,
    USERS_COLLECTION,
    userId,
    PROFILE_SUBCOLLECTION,
    EMPLOYER_PROFILE_DOC
  )
  console.log('Profile reference path:', profileRef.path)

  const profileSnap = await getDoc(profileRef)

  if (profileSnap.exists()) {
    console.log('Profile found:', profileSnap.data())
    const profileData = profileSnap.data()
    return {
      ...profileData,
      company_name:
        profileData.company_name ||
        userData.company_name ||
        'Company Name Not Set'
    }
  } else {
    console.log('No profile found for user:', userId)
    console.log('Creating default profile...')

    const defaultProfile = {
      plan_type: 'freetrial',
      company_name: userData.company_name || 'Company Name Not Set',
      created_at: serverTimestamp(),
      updated_at: serverTimestamp()
    }

    await setDoc(profileRef, defaultProfile)
    console.log('Default profile created')

    return defaultProfile
  }
}

// Upload company logo and update employer profile
export const uploadCompanyLogo = async (file) => {
  try {
    const user = auth.currentUser
    if (!user) throw new Error('No authenticated user')

    // Check if the current user is an employer member
    const employerMemberDoc = await getDoc(
      doc(db, EMPLOYER_MEMBERS_COLLECTION, user.uid)
    )
    let employerId = user.uid

    if (employerMemberDoc.exists()) {
      // If the user is an employer member, use the employer_id from their document
      employerId = employerMemberDoc.data().employer_id
      if (!employerId) {
        throw new Error('Employer member document does not have an employer_id')
      }
      console.log('User is an employer member. Using employer ID:', employerId)
    }

    const fileName = `${Date.now()}_${file.name}`
    const storageRef = ref(storage, `company_logos/${employerId}/${fileName}`)

    console.log('Uploading file to:', storageRef.fullPath)

    const snapshot = await uploadBytes(storageRef, file)
    console.log('File uploaded successfully')

    const downloadURL = await getDownloadURL(snapshot.ref)
    console.log('Download URL obtained:', downloadURL)

    // Update the employer profile with the new logo URL
    const employerProfileRef = doc(
      db,
      USERS_COLLECTION,
      employerId,
      PROFILE_SUBCOLLECTION,
      EMPLOYER_PROFILE_DOC
    )
    await updateDoc(employerProfileRef, { company_logo_url: downloadURL })
    console.log('Company logo URL updated in profile')

    return downloadURL
  } catch (error) {
    console.error('Error uploading company logo:', error)
    if (error.code === 'storage/unauthorized') {
      console.error(
        'Storage unauthorized. Please check Firebase Storage rules.'
      )
    }
    throw error
  }
}

// EmployerMember data structure
const createEmployerMemberObject = (memberData) => ({
  employer_id: memberData.employer_id,
  member_email: memberData.member_email,
  role: memberData.role || 'employerMember',
  status: memberData.status || 'invited',
  profile_picture_url: memberData.profile_picture_url || null,
  cover_photo_url: memberData.cover_photo_url || null,
  created_at: serverTimestamp(),
  updated_at: serverTimestamp()
})

// Create an employer member
export const createEmployerMember = async (memberData) => {
  if (!memberData.employer_id) {
    throw new Error('employer_id is required to create an employer member')
  }
  const memberObject = createEmployerMemberObject(memberData)
  const memberRef = await addDoc(
    collection(db, EMPLOYER_MEMBERS_COLLECTION),
    memberObject
  )
  console.log(`Created employer member with ID: ${memberRef.id}`)
  return memberRef
}

// Update an employer member
export const updateEmployerMember = async (memberId, updateData) => {
  const memberRef = doc(db, EMPLOYER_MEMBERS_COLLECTION, memberId)
  await updateDoc(memberRef, { ...updateData, updated_at: serverTimestamp() })
}

// Get job seekers
export const getJobSeekers = async (limitCount = 10) => {
  try {
    const user = auth.currentUser
    if (!user) {
      console.error('User is not authenticated')
      throw new Error('User is not authenticated')
    }
    console.log('Authenticated user ID:', user.uid)

    const userDoc = await getDoc(doc(db, USERS_COLLECTION, user.uid))
    if (!userDoc.exists()) {
      console.error('User document does not exist')
      throw new Error('User document not found')
    }

    const userData = userDoc.data()
    console.log('User data:', userData)

    const employerProfileRef = doc(
      db,
      USERS_COLLECTION,
      user.uid,
      PROFILE_SUBCOLLECTION,
      EMPLOYER_PROFILE_DOC
    )
    const employerMemberRef = doc(db, EMPLOYER_MEMBERS_COLLECTION, user.uid)
    const [employerProfileSnap, employerMemberSnap] = await Promise.all([
      getDoc(employerProfileRef),
      getDoc(employerMemberRef)
    ])

    if (!employerProfileSnap.exists() && !employerMemberSnap.exists()) {
      console.error('User is neither an employer nor an employer member')
      throw new Error('Insufficient permissions')
    }

    const jobseekersQuery = query(
      collection(db, USERS_COLLECTION),
      where('role', '==', 'jobseeker'),
      limit(limitCount)
    )
    const jobseekersSnapshot = await getDocs(jobseekersQuery)

    const jobseekers = jobseekersSnapshot.docs.map((doc) => {
      const userData = doc.data()
      return {
        id: doc.id,
        name: `${userData.first_name} ${userData.last_name}`,
        role: userData.role || 'Not specified',
        joinDate: userData.created_at
          ? userData.created_at.toDate().toLocaleDateString()
          : 'Unknown'
      }
    })

    return jobseekers
  } catch (error) {
    console.error('Error in getJobSeekers:', error)
    throw error
  }
}

export const ensureCorrectEmployerId = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, USERS_COLLECTION, userId))
    if (!userDoc.exists()) {
      throw new Error('User document not found')
    }
    const userData = userDoc.data()

    if (!userData.employer_id) {
      console.log(
        'User does not have an employer_id, setting it to their own ID'
      )
      await updateDoc(doc(db, USERS_COLLECTION, userId), {
        employer_id: userId
      })
    }

    // If the user is an employer, ensure all their members have the correct employer_id
    if (userData.role === 'employer') {
      const membersQuery = query(
        collection(db, USERS_COLLECTION),
        where('employer_id', '==', userData.employer_id || userId)
      )
      const membersSnapshot = await getDocs(membersQuery)

      const batch = writeBatch(db)
      membersSnapshot.forEach((memberDoc) => {
        batch.update(doc(db, USERS_COLLECTION, memberDoc.id), {
          employer_id: userData.employer_id || userId
        })
      })
      await batch.commit()
      console.log('Updated employer_id for all members')
    }

    console.log('Ensured correct employer_id for user and related members')
  } catch (error) {
    console.error('Error in ensureCorrectEmployerId:', error)
    throw error
  }
}

export const updateEmployerProfile = async (userId, updateData) => {
  try {
    // Get the user's document to find the employer_id
    const userDoc = await getDoc(doc(db, USERS_COLLECTION, userId))
    if (!userDoc.exists()) {
      throw new Error('User document not found')
    }
    const userData = userDoc.data()
    const employerId = userData.employer_id || userId

    // Start a batch write
    const batch = writeBatch(db)

    // Update the main user's profile
    const mainProfileRef = doc(
      db,
      USERS_COLLECTION,
      userId,
      PROFILE_SUBCOLLECTION,
      EMPLOYER_PROFILE_DOC
    )
    batch.update(mainProfileRef, {
      ...updateData,
      updated_at: serverTimestamp()
    })

    // Find all users with the same employer_id
    const usersQuery = query(
      collection(db, USERS_COLLECTION),
      where('employer_id', '==', employerId)
    )
    const usersSnapshot = await getDocs(usersQuery)

    // Update profiles for all users with the same employer_id
    usersSnapshot.forEach((userDoc) => {
      if (userDoc.id !== userId) {
        // Skip the main user as we've already updated them
        const userProfileRef = doc(
          db,
          USERS_COLLECTION,
          userDoc.id,
          PROFILE_SUBCOLLECTION,
          EMPLOYER_PROFILE_DOC
        )
        batch.update(userProfileRef, {
          ...updateData,
          updated_at: serverTimestamp()
        })
      }
    })

    // Commit all the updates
    await batch.commit()
    console.log('Updated profiles for all related users successfully')
  } catch (error) {
    console.error('Error in updateEmployerProfile:', error)
    throw error
  }
}

export default {
  createOrUpdateEmployerProfile,
  getEmployerProfile,
  updateEmployerProfile,
  uploadCompanyLogo,
  createEmployerMember,
  getEmployerMembers,
  updateEmployerMember,
  getJobSeekers
}
