import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { Users, UserCheck, Award, Briefcase } from 'lucide-react'
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore'
import { db } from '../firebase'

const COLORS = [
  '#CAAAE1', // Your original soft purple
  '#9DC6E0', // Soft blue
  '#E5A4C4', // Soft pink
  '#A5D6D9', // Soft teal
  '#D5B6EA' // Another soft purple
]

const Container = styled.div`
  width: 100%;
  margin: 1rem 0;
`

const GridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ChartCard = styled.div`
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &:last-child {
    @media (min-width: 768px) {
      grid-column: span 2;
    }
  }
`

const CardHeader = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
`

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: #2d3748;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS[0]};

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const ChartContainer = styled.div`
  height: 16rem;
  padding: 1rem;
`

const LoadingMessage = styled.div`
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #718096;
`

const ErrorMessage = styled.div`
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e53e3e;
`

const NoDataMessage = styled.div`
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #718096;
  font-style: italic;
`

const DemographicInsights = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [demographicData, setDemographicData] = useState({
    gender: [],
    race: [],
    athlete_status: [],
    veteran_status: [],
    job_level: []
  })

  const USERS_COLLECTION = 'users'
  const PROFILE_SUBCOLLECTION = 'profiles'
  const JOBSEEKER_PROFILE_DOC = 'jobseeker'

  useEffect(() => {
    const fetchDemographics = async () => {
      setLoading(true)
      try {
        const profiles = {}
        const demographicsData = {
          gender: {},
          race: {},
          veteran_status: {},
          job_level: {},
          athlete_status: { Athlete: 0, 'Non-Athlete': 0 }
        }

        const usersCollectionRef = collection(db, USERS_COLLECTION)
        const jobseekerQuery = query(
          usersCollectionRef,
          where('role', '==', 'jobseeker')
        )

        const querySnapshot = await getDocs(jobseekerQuery)

        const profilePromises = querySnapshot.docs.map(async (userDoc) => {
          try {
            const profileRef = doc(
              db,
              USERS_COLLECTION,
              userDoc.id,
              PROFILE_SUBCOLLECTION,
              JOBSEEKER_PROFILE_DOC
            )
            const profileSnap = await getDoc(profileRef)

            if (profileSnap.exists()) {
              const data = profileSnap.data()

              if (data.gender) {
                demographicsData.gender[data.gender] =
                  (demographicsData.gender[data.gender] || 0) + 1
              }

              if (data.race && Array.isArray(data.race)) {
                data.race.forEach((r) => {
                  demographicsData.race[r] = (demographicsData.race[r] || 0) + 1
                })
              }

              if (data.veteran_status) {
                demographicsData.veteran_status[data.veteran_status] =
                  (demographicsData.veteran_status[data.veteran_status] || 0) +
                  1
              }

              if (data.job_level) {
                demographicsData.job_level[data.job_level] =
                  (demographicsData.job_level[data.job_level] || 0) + 1
              }

              if (data.athlete_status) {
                demographicsData.athlete_status['Athlete'] += 1
              } else {
                demographicsData.athlete_status['Non-Athlete'] += 1
              }

              profiles[userDoc.id] = data
            }
          } catch (error) {
            console.error(
              `Error fetching profile for user ${userDoc.id}:`,
              error
            )
          }
        })

        await Promise.all(profilePromises)

        const transformedData = {
          gender: Object.entries(demographicsData.gender).map(
            ([name, value]) => ({
              name,
              value
            })
          ),
          race: Object.entries(demographicsData.race).map(([name, value]) => ({
            name,
            value
          })),
          athlete_status: Object.entries(demographicsData.athlete_status).map(
            ([name, value]) => ({
              name,
              value
            })
          ),
          veteran_status: Object.entries(demographicsData.veteran_status).map(
            ([name, value]) => ({
              name,
              value
            })
          ),
          job_level: Object.entries(demographicsData.job_level).map(
            ([name, value]) => ({
              name,
              value
            })
          )
        }

        setDemographicData(transformedData)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching demographics:', error)
        setError(error.message)
        setLoading(false)
      }
    }

    fetchDemographics()
  }, [])

  const renderChart = (data, title, Icon, chartType = 'pie') => {
    if (loading) {
      return (
        <ChartCard>
          <CardHeader>
            <CardTitle>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {title}
            </CardTitle>
          </CardHeader>
          <LoadingMessage>Loading data...</LoadingMessage>
        </ChartCard>
      )
    }

    if (error) {
      return (
        <ChartCard>
          <CardHeader>
            <CardTitle>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {title}
            </CardTitle>
          </CardHeader>
          <ErrorMessage>Error loading data: {error}</ErrorMessage>
        </ChartCard>
      )
    }

    if (!data || data.length === 0) {
      return (
        <ChartCard>
          <CardHeader>
            <CardTitle>
              <IconWrapper>
                <Icon />
              </IconWrapper>
              {title}
            </CardTitle>
          </CardHeader>
          <NoDataMessage>No data available</NoDataMessage>
        </ChartCard>
      )
    }

    return (
      <ChartCard>
        <CardHeader>
          <CardTitle>
            <IconWrapper>
              <Icon />
            </IconWrapper>
            {title}
          </CardTitle>
        </CardHeader>
        <ChartContainer>
          <ResponsiveContainer width="100%" height="100%">
            {chartType === 'pie' ? (
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            ) : (
              <BarChart data={data}>
                <XAxis
                  dataKey="name"
                  tick={{ fill: '#718096' }}
                  axisLine={{ stroke: '#E2E8F0' }}
                />
                <YAxis
                  tick={{ fill: '#718096' }}
                  axisLine={{ stroke: '#E2E8F0' }}
                />
                <Tooltip
                  cursor={{ fill: 'rgba(202, 170, 225, 0.1)' }}
                  contentStyle={{
                    background: 'white',
                    border: `1px solid ${COLORS[0]}`,
                    borderRadius: '4px',
                    padding: '8px'
                  }}
                />
                <Bar dataKey="value" radius={[4, 4, 0, 0]}>
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            )}
          </ResponsiveContainer>
        </ChartContainer>
      </ChartCard>
    )
  }

  return (
    <Container>
      <GridContainer>
        {renderChart(demographicData.gender, 'Gender Distribution', Users)}
        {renderChart(
          demographicData.race,
          'Race/Ethnicity Distribution',
          Users,
          'bar'
        )}
        {renderChart(demographicData.athlete_status, 'Athlete Status', Award)}
        {renderChart(
          demographicData.veteran_status,
          'Veteran Status',
          UserCheck
        )}
        {renderChart(
          demographicData.job_level,
          'Job Level Distribution',
          Briefcase,
          'bar'
        )}
      </GridContainer>
    </Container>
  )
}

export default DemographicInsights
