import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' // Add this
import { useParams } from 'react-router-dom'

import styled from 'styled-components'
import {
  Twitter,
  Facebook,
  Linkedin,
  Mail,
  Plus,
  Briefcase,
  Edit,
  NotebookPen,
  Save,
  Users,
  Building,
  Globe,
  Book,
  Check,
  MapPin,
  Trash2,
  Home,
  Link,
  Phone,
  Heart, // Add these new icons
  Calendar,
  GraduationCap,
  Laptop,
  Smile,
  Monitor,
  Train,
  Coffee,
  Shield,
  BanknoteIcon
} from 'lucide-react'
import {
  getEmployerProfile,
  updateEmployerProfile
} from '../models/EmployerProfile'
import { getUserById } from '../models/User'
import EmployerNav from './EmployerNav'
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from 'firebase/storage'
import {
  doc,
  getDoc,
  writeBatch,
  collection,
  query,
  where,
  getDocs,
  serverTimestamp
} from 'firebase/firestore'
import { db, auth, storage } from '../firebase'
import { getActiveEmployerJobPostings } from '../models/JobPosting'

const USERS_COLLECTION = 'users'
const PROFILE_SUBCOLLECTION = 'profiles'
const EMPLOYER_PROFILE_DOC = 'employer'

const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'E-Sports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f4f8;
  color: #2d3748;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`

const CompanyName = styled.h1`
  font-size: 32px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  margin: 0;
`

const CompanyWebsite = styled.a`
  color: #0066cc;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #caaae1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #b38fd1;
  }
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  margin-bottom: 40px;
`

const Card = styled.div`
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 24px;
  margin-top: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const CardTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`

const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`

const Textarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 100px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
`

const ContactLinks = styled.div`
  display: flex;
  gap: 16px;
`

const ContactLink = styled.a`
  color: #333;
  &:hover {
    color: #0066cc;
  }
`

const TeamMemberRole = styled.p`
  font-size: 14px;
  color: #666;
  margin: 4px 0 0;
`

const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #ff4444;
`

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 24px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableHeader = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f0f0f0;
  font-weight: bold;
`

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
`

const TableCell = styled.td`
  padding: 12px;
`
const CoverPhotoSection = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #f0f4f8;
  margin-bottom: 0;
  border-radius: 8px;
`

const CoverPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`

const HeaderWrapper = styled.div`
  position: relative;
  margin-top: -100px;
  padding: 0 2rem;
`

const HeaderContent = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`
const EmptyPositions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  gap: 16px;
`

const EmptySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  gap: 16px;
`

const EmptyText = styled.p`
  color: #666;
  margin: 0;
  font-size: 16px;
`

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 32px;
  margin-bottom: 24px;
`

const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`

const TeamMemberAvatar = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 16px;
  object-fit: cover;
`

const TeamMemberInfo = styled.div`
  text-align: center;
  width: 100%;
`

const TeamMemberName = styled.h3`
  font-size: 18px;
  margin: 0 0 4px 0;
  color: #2d3748;
`

const TeamMemberTitle = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0 0 12px 0;
`

const TeamMemberLinkedIn = styled.a`
  color: #0077b5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration: none;
  font-size: 14px;
  margin-top: 8px;

  &:hover {
    text-decoration: underline;
  }
`

const TeamInputGroup = styled.div`
  margin-bottom: 12px;
  width: 100%;
`

const TeamInputLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  color: #4a5568;
`
const ImageUploadZone = styled.div`
  border: 2px dashed ${(props) => (props.isDragging ? '#CAAAE1' : '#e2e8f0')};
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  background: ${(props) => (props.isDragging ? '#f8f4fb' : '#f8fafc')};
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 16px;

  &:hover {
    border-color: #caaae1;
    background: #f8f4fb;
  }
`

const UploadProgress = styled.div`
  width: 100%;
  height: 4px;
  background: #e2e8f0;
  border-radius: 2px;
  margin-top: 8px;
  overflow: hidden;
`

const ProgressBar = styled.div`
  width: ${(props) => props.progress}%;
  height: 100%;
  background: #caaae1;
  transition: width 0.3s ease;
`

const ModernInput = styled.div`
  margin-bottom: 20px;
`

const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #4a5568;
  font-weight: 500;
`

const StyledInput = styled.input`
  width: 90%;
  padding: 10px 16px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.2s ease;
  background: white;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }

  &::placeholder {
    color: #a0aec0;
  }
`

const TeamMemberModal = styled.div`
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

const ModalHeader = styled.div`
  margin-bottom: 24px;
  text-align: center;
`

const ModalTitle = styled.h3`
  margin: 0;
  color: #2d3748;
  font-size: 20px;
  font-weight: 600;
`

const BenefitCard = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  text-align: center;
  transition: all 0.2s ease;
  position: relative;
`

const BenefitIcon = styled.div`
  width: 48px;
  height: 48px;
  background-color: #f8f4fb;
  color: #caaae1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
`

const BenefitForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  margin: 0 auto;
`

const IconSelect = styled(Select)`
  padding-left: 40px;
  background-position: 12px center;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1000;
  max-width: 500px;
  width: 90%;
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const Grid = styled.div`
  display: grid;
  gap: 24px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
  margin: 0;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding: 12px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #caaae1;
    border-radius: 4px;
  }
`

const CheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
  color: #2d3748;
  transition: all 0.2s ease;

  &:hover {
    background: #f8f4fb;
  }
`

const CheckboxCustom = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid ${(props) => (props.checked ? '#caaae1' : '#cbd5e0')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.checked ? '#caaae1' : 'white')};
  transition: all 0.2s ease;

  svg {
    color: white;
  }
`

const SelectionsDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const SelectionCategory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const SelectionTitle = styled.span`
  font-weight: 500;
  color: #4a5568;
  font-size: 14px;
`

const SelectionText = styled.span`
  color: #2d3748;
  font-size: 14px;
`

export default function Component() {
  const { employerId } = useParams()
  const [profile, setProfile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const navigate = useNavigate()
  const [activeJobs, setActiveJobs] = useState([])

  const [editedProfile, setEditedProfile] = useState(null)
  const [showBenefitModal, setShowBenefitModal] = useState(false)
  const [newBenefit, setNewBenefit] = useState({
    category: '',
    description: ''
  })
  // Add this to your constants section
  const benefitCategories = [
    { value: 'Healthcare', label: 'Healthcare', icon: Heart },
    { value: 'Time Off', label: 'Time Off', icon: Calendar },
    {
      value: 'Learning & Development',
      label: 'Learning & Development',
      icon: GraduationCap
    },
    { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
    { value: 'Wellness', label: 'Wellness', icon: Smile },
    { value: 'Equipment', label: 'Equipment', icon: Monitor },
    { value: 'Family', label: 'Family', icon: Users },
    { value: 'Commuter', label: 'Commuter', icon: Train },
    { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
    { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
    { value: 'Insurance', label: 'Insurance', icon: Shield }
  ]
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = auth.currentUser
        if (!currentUser) throw new Error('No authenticated user')

        const userData = await getUserById(currentUser.uid)
        const employerProfileData = await getEmployerProfile(currentUser.uid)

        const normalizedProfile = {
          ...employerProfileData,
          industry: Array.isArray(employerProfileData.industry)
            ? employerProfileData.industry
            : employerProfileData.industry
            ? [employerProfileData.industry]
            : [],
          sub_industry: Array.isArray(employerProfileData.sub_industry)
            ? employerProfileData.sub_industry
            : employerProfileData.sub_industry
            ? [employerProfileData.sub_industry]
            : []
        }

        setProfile(normalizedProfile)
        setEditedProfile(normalizedProfile)
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchActiveJobs = async () => {
      try {
        if (!auth.currentUser) return
        const jobs = await getActiveEmployerJobPostings(auth.currentUser.uid)
        setActiveJobs(jobs)
      } catch (error) {
        console.error('Error fetching active jobs:', error)
      }
    }

    fetchActiveJobs()
  }, [])
  const getAllowedSubIndustries = (selectedIndustries) => {
    return selectedIndustries.reduce((acc, industry) => {
      return [...acc, ...(allSubcategories[industry] || [])]
    }, [])
  }
  const getIconComponent = (categoryValue) => {
    const iconMap = {
      healthcare: Heart,
      vacation: Calendar,
      learning: GraduationCap,
      remote: Laptop,
      wellness: Smile,
      equipment: Monitor,
      family: Users,
      commuter: Train,
      food: Coffee,
      retirement: BanknoteIcon,
      insurance: Shield,
      other: Plus
    }
    return iconMap[categoryValue] || Plus
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  const getSubcategories = (industry) => {
    return industry ? allSubcategories[industry] || [] : []
  }

  const handleSave = async () => {
    try {
      const currentUser = auth.currentUser
      if (!currentUser) throw new Error('No authenticated user')

      // Get the user's document to find the employer_id
      const userDoc = await getDoc(doc(db, USERS_COLLECTION, currentUser.uid))
      if (!userDoc.exists()) {
        throw new Error('User document not found')
      }
      const userData = userDoc.data()
      const employerId = userData.employer_id || currentUser.uid

      // Start a batch write
      const batch = writeBatch(db)

      // Update the main user's profile
      const mainProfileRef = doc(
        db,
        USERS_COLLECTION,
        currentUser.uid,
        PROFILE_SUBCOLLECTION,
        EMPLOYER_PROFILE_DOC
      )
      batch.update(mainProfileRef, {
        ...editedProfile,
        updated_at: serverTimestamp()
      })

      // Find all users with the same employer_id
      const usersQuery = query(
        collection(db, USERS_COLLECTION),
        where('employer_id', '==', employerId)
      )
      const usersSnapshot = await getDocs(usersQuery)

      // Update profiles for all users with the same employer_id
      usersSnapshot.forEach((userDoc) => {
        if (userDoc.id !== currentUser.uid) {
          const userProfileRef = doc(
            db,
            USERS_COLLECTION,
            userDoc.id,
            PROFILE_SUBCOLLECTION,
            EMPLOYER_PROFILE_DOC
          )
          batch.update(userProfileRef, {
            ...editedProfile,
            updated_at: serverTimestamp()
          })
        }
      })

      // Commit all the updates
      await batch.commit()

      setProfile(editedProfile)
      setIsEditing(false)
    } catch (error) {
      alert('Error updating profile. Please try again.')
    }
  }

  const handleAddBenefit = async () => {
    try {
      if (!newBenefit.category || !newBenefit.description) {
        alert('Please fill in all fields')
        return
      }

      const currentUser = auth.currentUser
      if (!currentUser) throw new Error('No authenticated user')

      // Get the employer_id
      const userDoc = await getDoc(doc(db, USERS_COLLECTION, currentUser.uid))
      if (!userDoc.exists()) {
        throw new Error('User document not found')
      }
      const userData = userDoc.data()
      const employerId = userData.employer_id || currentUser.uid

      // Start a batch write
      const batch = writeBatch(db)

      // Prepare the updated benefits array
      const updatedBenefits = [
        ...(editedProfile.benefits || []),
        { ...newBenefit }
      ]

      // Update main profile
      const mainProfileRef = doc(
        db,
        USERS_COLLECTION,
        currentUser.uid,
        PROFILE_SUBCOLLECTION,
        EMPLOYER_PROFILE_DOC
      )
      batch.update(mainProfileRef, {
        benefits: updatedBenefits,
        updated_at: serverTimestamp()
      })

      // Update related profiles
      const usersQuery = query(
        collection(db, USERS_COLLECTION),
        where('employer_id', '==', employerId)
      )
      const usersSnapshot = await getDocs(usersQuery)

      usersSnapshot.forEach((userDoc) => {
        if (userDoc.id !== currentUser.uid) {
          const userProfileRef = doc(
            db,
            USERS_COLLECTION,
            userDoc.id,
            PROFILE_SUBCOLLECTION,
            EMPLOYER_PROFILE_DOC
          )
          batch.update(userProfileRef, {
            benefits: updatedBenefits,
            updated_at: serverTimestamp()
          })
        }
      })

      // Commit all the updates
      await batch.commit()

      // Update local state
      setEditedProfile((prev) => ({
        ...prev,
        benefits: updatedBenefits
      }))
      setProfile((prev) => ({
        ...prev,
        benefits: updatedBenefits
      }))

      // Reset form and close modal
      setNewBenefit({ category: '', description: '' })
      setShowBenefitModal(false)
    } catch (error) {
      alert('Error adding benefit. Please try again.')
    }
  }

  const handleRemoveBenefit = async (index) => {
    try {
      const currentUser = auth.currentUser
      if (!currentUser) throw new Error('No authenticated user')

      // Get the employer_id
      const userDoc = await getDoc(doc(db, USERS_COLLECTION, currentUser.uid))
      if (!userDoc.exists()) {
        throw new Error('User document not found')
      }
      const userData = userDoc.data()
      const employerId = userData.employer_id || currentUser.uid

      // Prepare the updated benefits array
      const updatedBenefits = editedProfile.benefits.filter(
        (_, i) => i !== index
      )

      // Start a batch write
      const batch = writeBatch(db)

      // Update main profile
      const mainProfileRef = doc(
        db,
        USERS_COLLECTION,
        currentUser.uid,
        PROFILE_SUBCOLLECTION,
        EMPLOYER_PROFILE_DOC
      )
      batch.update(mainProfileRef, {
        benefits: updatedBenefits,
        updated_at: serverTimestamp()
      })

      // Update related profiles
      const usersQuery = query(
        collection(db, USERS_COLLECTION),
        where('employer_id', '==', employerId)
      )
      const usersSnapshot = await getDocs(usersQuery)

      usersSnapshot.forEach((userDoc) => {
        if (userDoc.id !== currentUser.uid) {
          const userProfileRef = doc(
            db,
            USERS_COLLECTION,
            userDoc.id,
            PROFILE_SUBCOLLECTION,
            EMPLOYER_PROFILE_DOC
          )
          batch.update(userProfileRef, {
            benefits: updatedBenefits,
            updated_at: serverTimestamp()
          })
        }
      })

      // Commit all the updates
      await batch.commit()

      // Update local state
      setEditedProfile((prev) => ({
        ...prev,
        benefits: updatedBenefits
      }))
      setProfile((prev) => ({
        ...prev,
        benefits: updatedBenefits
      }))
    } catch (error) {
      alert('Error removing benefit. Please try again.')
    }
  }

  const handleTeamMemberChange = (index, field, value) => {
    setEditedProfile((prev) => {
      const updatedTeam = [...(prev.team || [])]
      if (!updatedTeam[index]) {
        updatedTeam[index] = {}
      }
      updatedTeam[index] = {
        ...updatedTeam[index],
        [field]: value
      }
      return {
        ...prev,
        team: updatedTeam
      }
    })
  }

  const handleAddTeamMember = () => {
    setEditedProfile((prev) => {
      const currentTeam = prev.team || []
      if (currentTeam.length >= 3) {
        alert('Maximum of 3 team members allowed')
        return prev
      }
      return {
        ...prev,
        team: [
          ...currentTeam,
          {
            name: '',
            title: '',
            photo_url: '/default-avatar.png',
            linkedin_url: ''
          }
        ]
      }
    })
  }
  const debugUploadPath = (file, userId) => {
    const timestamp = Date.now()
    const uniqueFileName = `${timestamp}_${file.name}`
    // Match the path structure from storage rules: team-members/{userId}/{fileName}
    const filePath = `team-members/${userId}/${uniqueFileName}`
    return filePath
  }

  const uploadTeamMemberImage = async (file, teamMemberIndex) => {
    try {
      // Validation checks
      if (!file || !file.type.startsWith('image/')) {
        throw new Error('Please upload a valid image file')
      }

      if (file.size > 5 * 1024 * 1024) {
        throw new Error('Image size should be less than 5MB')
      }

      const currentUser = auth.currentUser
      if (!currentUser) {
        throw new Error('No authenticated user')
      }

      // Generate and log the upload path - matching storage rules structure
      const filePath = debugUploadPath(file, currentUser.uid)

      // Create storage reference with the correct path
      const fileRef = storageRef(storage, filePath)

      // Upload with metadata
      const metadata = {
        contentType: file.type,
        customMetadata: {
          uploadedBy: currentUser.uid,
          originalFileName: file.name
        }
      }

      const uploadResult = await uploadBytes(fileRef, file, metadata)

      const downloadURL = await getDownloadURL(fileRef)

      return downloadURL
    } catch (error) {
      if (error.code === 'storage/unauthorized') {
        console.error(
          'Auth state:',
          auth.currentUser ? 'Logged in' : 'Not logged in'
        )
        throw new Error(`Permission denied. User ID: ${auth.currentUser?.uid}`)
      }
      throw error
    }
  }

  // Also update cleanup function to handle the nested path structure
  const cleanupTeamMemberImage = async (photoUrl) => {
    if (!photoUrl || photoUrl === '/default-avatar.png') return

    try {
      // Extract the path from the URL
      const urlPath = new URL(photoUrl).pathname
      const imagePath = urlPath.split('/o/')[1]
      const decodedPath = decodeURIComponent(imagePath)

      const imageRef = storageRef(storage, decodedPath)
      await deleteObject(imageRef)
    } catch (error) {}
  }
  const handleInputChange = (field, value) => {
    setEditedProfile((prev) => {
      if (field === 'industry') {
        // When industry changes, filter out invalid sub-industries
        const validSubIndustries = prev.sub_industry.filter((subInd) =>
          getAllowedSubIndustries(value).includes(subInd)
        )
        return {
          ...prev,
          [field]: value,
          sub_industry: validSubIndustries
        }
      }
      return { ...prev, [field]: value }
    })
  }

  // Kee

  const handleRemoveTeamMember = async (index) => {
    try {
      const memberToRemove = editedProfile.team[index]
      const updatedTeam = editedProfile.team.filter((_, i) => i !== index)

      // Update local state first
      setEditedProfile((prev) => ({
        ...prev,
        team: updatedTeam
      }))

      const currentUser = auth.currentUser
      if (!currentUser) throw new Error('No authenticated user')

      // Get the employer_id
      const userDoc = await getDoc(doc(db, USERS_COLLECTION, currentUser.uid))
      if (!userDoc.exists()) {
        throw new Error('User document not found')
      }
      const userData = userDoc.data()
      const employerId = userData.employer_id || currentUser.uid

      // Start a batch write
      const batch = writeBatch(db)

      // Update all profiles
      const profileUpdates = {
        team: updatedTeam,
        updated_at: serverTimestamp()
      }

      // Update main profile
      const mainProfileRef = doc(
        db,
        USERS_COLLECTION,
        currentUser.uid,
        PROFILE_SUBCOLLECTION,
        EMPLOYER_PROFILE_DOC
      )
      batch.update(mainProfileRef, profileUpdates)

      // Update related profiles
      const usersQuery = query(
        collection(db, USERS_COLLECTION),
        where('employer_id', '==', employerId)
      )
      const usersSnapshot = await getDocs(usersQuery)

      usersSnapshot.forEach((userDoc) => {
        if (userDoc.id !== currentUser.uid) {
          const userProfileRef = doc(
            db,
            USERS_COLLECTION,
            userDoc.id,
            PROFILE_SUBCOLLECTION,
            EMPLOYER_PROFILE_DOC
          )
          batch.update(userProfileRef, profileUpdates)
        }
      })

      // Commit the updates
      await batch.commit()

      // Cleanup the removed member's image
      if (memberToRemove.photo_url) {
        await cleanupTeamMemberImage(memberToRemove.photo_url)
      }

      // Update the main profile state
      setProfile((prev) => ({
        ...prev,
        team: updatedTeam
      }))
    } catch (error) {
      console.error('Error removing team member:', error)
      alert('Error removing team member. Please try again.')

      // Rollback local state
      setEditedProfile((prev) => ({
        ...prev,
        team: profile.team
      }))
    }
  }
  const handlePostJob = () => {
    navigate('/job-post-form')
  }

  if (loading) return <div>Loading...</div>
  if (!profile) return <div>No profile data available</div>

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <CoverPhotoSection>
          <CoverPhoto src="/images/cover-photo.png.jpg" alt="Company Cover" />
        </CoverPhotoSection>
        <HeaderWrapper>
          <HeaderContent>
            <Header>
              <CompanyInfo>
                <Avatar
                  src={profile.company_logo_url || '/default-logo.png'}
                  alt={profile.company_name}
                />
                <div>
                  <CompanyName>
                    {profile.company_name || 'Company Name Not Set'}
                  </CompanyName>
                  <CompanyWebsite
                    href={profile.company_website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* {isEditing ? (
                      <Input
                        value={editedProfile.company_website}
                        onChange={(e) =>
                          handleInputChange('company_website', e.target.value)
                        }
                        placeholder="Enter company website"
                      />
                    ) : ( */}
                    {profile.company_website}
                    {/* )} */}
                  </CompanyWebsite>
                </div>
              </CompanyInfo>
              <ButtonGroup>
                {isEditing ? (
                  <Button onClick={handleSave}>
                    <Save size={16} /> Save
                  </Button>
                ) : (
                  <Button onClick={handleEdit}>
                    <Edit size={16} /> Edit
                  </Button>
                )}
                <Button onClick={handlePostJob}>
                  <Briefcase size={16} /> Post a Job
                </Button>
              </ButtonGroup>
            </Header>
          </HeaderContent>
        </HeaderWrapper>
        <CardGrid>
          <Card>
            <CardTitle>
              <Home size={20} /> Work Policy
            </CardTitle>
            {isEditing ? (
              <Select
                value={editedProfile.work_policy}
                onChange={(e) =>
                  handleInputChange('work_policy', e.target.value)
                }
              >
                <option value="">Select work policy</option>
                <option value="In-Office">In-office</option>
                <option value="Hybrid">Hybrid</option>
                <option value="Remote">Remote</option>
              </Select>
            ) : (
              profile.work_policy || 'Work policy not set'
            )}
          </Card>
          <Card>
            <CardTitle>
              <Users size={20} /> Employees
            </CardTitle>
            {isEditing ? (
              <Input
                value={editedProfile.full_time_employees || ''}
                onChange={(e) =>
                  handleInputChange('full_time_employees', e.target.value)
                }
                placeholder="Enter number of employees"
                type="number"
              />
            ) : (
              profile.full_time_employees || 'Not specified'
            )}{' '}
            Employees
          </Card>
          <Card>
            <CardTitle>
              <Building size={20} /> Industry & Sub-Industries
            </CardTitle>
            {isEditing ? (
              <Grid>
                <Section>
                  <SectionTitle>Industries</SectionTitle>
                  <CheckboxContainer>
                    {industries.map((industry) => (
                      <CheckboxWrapper key={industry}>
                        <CheckboxInput
                          type="checkbox"
                          id={`industry-${industry}`}
                          checked={
                            Array.isArray(editedProfile.industry)
                              ? editedProfile.industry.includes(industry)
                              : editedProfile.industry === industry
                          }
                          onChange={(e) => {
                            const currentIndustries = Array.isArray(
                              editedProfile.industry
                            )
                              ? editedProfile.industry
                              : editedProfile.industry
                              ? [editedProfile.industry]
                              : []

                            const updatedIndustries = e.target.checked
                              ? [...currentIndustries, industry]
                              : currentIndustries.filter(
                                  (ind) => ind !== industry
                                )

                            handleInputChange('industry', updatedIndustries)

                            // Clear sub-industries that no longer apply
                            const currentSubIndustries = Array.isArray(
                              editedProfile.sub_industry
                            )
                              ? editedProfile.sub_industry
                              : editedProfile.sub_industry
                              ? [editedProfile.sub_industry]
                              : []

                            const validSubIndustries =
                              currentSubIndustries.filter((subInd) =>
                                updatedIndustries.some((ind) =>
                                  getSubcategories(ind).includes(subInd)
                                )
                              )

                            handleInputChange(
                              'sub_industry',
                              validSubIndustries
                            )
                          }}
                        />
                        <CheckboxLabel htmlFor={`industry-${industry}`}>
                          <CheckboxCustom
                            checked={
                              Array.isArray(editedProfile.industry)
                                ? editedProfile.industry.includes(industry)
                                : editedProfile.industry === industry
                            }
                          >
                            {Array.isArray(editedProfile.industry) &&
                              editedProfile.industry.includes(industry) && (
                                <Check size={12} />
                              )}
                          </CheckboxCustom>
                          {industry}
                        </CheckboxLabel>
                      </CheckboxWrapper>
                    ))}
                  </CheckboxContainer>
                </Section>

                <Section>
                  <SectionTitle>Sub-Industries</SectionTitle>
                  <CheckboxContainer>
                    {(Array.isArray(editedProfile.industry)
                      ? editedProfile.industry
                      : [editedProfile.industry]
                    )
                      .filter(Boolean)
                      .flatMap((industry) => getSubcategories(industry))
                      .map((subCategory) => (
                        <CheckboxWrapper key={subCategory}>
                          <CheckboxInput
                            type="checkbox"
                            id={`sub-${subCategory}`}
                            checked={
                              Array.isArray(editedProfile.sub_industry)
                                ? editedProfile.sub_industry.includes(
                                    subCategory
                                  )
                                : editedProfile.sub_industry === subCategory
                            }
                            onChange={(e) => {
                              const currentSubIndustries = Array.isArray(
                                editedProfile.sub_industry
                              )
                                ? editedProfile.sub_industry
                                : editedProfile.sub_industry
                                ? [editedProfile.sub_industry]
                                : []

                              const updatedSubIndustries = e.target.checked
                                ? [...currentSubIndustries, subCategory]
                                : currentSubIndustries.filter(
                                    (sub) => sub !== subCategory
                                  )

                              handleInputChange(
                                'sub_industry',
                                updatedSubIndustries
                              )
                            }}
                          />
                          <CheckboxLabel htmlFor={`sub-${subCategory}`}>
                            <CheckboxCustom
                              checked={
                                Array.isArray(editedProfile.sub_industry)
                                  ? editedProfile.sub_industry.includes(
                                      subCategory
                                    )
                                  : editedProfile.sub_industry === subCategory
                              }
                            >
                              {Array.isArray(editedProfile.sub_industry) &&
                                editedProfile.sub_industry.includes(
                                  subCategory
                                ) && <Check size={12} />}
                            </CheckboxCustom>
                            {subCategory}
                          </CheckboxLabel>
                        </CheckboxWrapper>
                      ))}
                  </CheckboxContainer>
                </Section>
              </Grid>
            ) : (
              <SelectionsDisplay>
                <SelectionCategory>
                  <SelectionTitle>Industries:</SelectionTitle>
                  <SelectionText>
                    {Array.isArray(editedProfile.industry) &&
                    editedProfile.industry.length > 0
                      ? editedProfile.industry.join(', ')
                      : editedProfile.industry || 'Not specified'}
                  </SelectionText>
                </SelectionCategory>

                <SelectionCategory>
                  <SelectionTitle>Sub-Industries:</SelectionTitle>
                  <SelectionText>
                    {Array.isArray(editedProfile.sub_industry) &&
                    editedProfile.sub_industry.length > 0
                      ? editedProfile.sub_industry.join(', ')
                      : editedProfile.sub_industry || 'Not specified'}
                  </SelectionText>
                </SelectionCategory>
              </SelectionsDisplay>
            )}
          </Card>
        </CardGrid>

        <Card>
          <CardTitle>
            <Book size={20} /> About
          </CardTitle>
          {isEditing ? (
            <Textarea
              value={editedProfile.company_description}
              onChange={(e) =>
                handleInputChange('company_description', e.target.value)
              }
              placeholder="Enter company description"
            />
          ) : (
            profile.company_description || 'Company description not set'
          )}
        </Card>

        <Card>
          <CardTitle>
            <Users size={20} /> Team
          </CardTitle>
          {(editedProfile.team || []).length > 0 ? (
            <>
              <TeamGrid>
                {editedProfile.team.map((member, index) => (
                  <TeamMember key={index}>
                    {isEditing ? (
                      <TeamMemberModal>
                        <ModalHeader>
                          <ModalTitle>Team Member Details</ModalTitle>
                          <RemoveButton
                            onClick={() => handleRemoveTeamMember(index)}
                          >
                            <Trash2 size={16} />
                          </RemoveButton>
                        </ModalHeader>

                        <ImageUploadZone
                          onClick={() =>
                            document
                              .getElementById(`team-member-image-${index}`)
                              .click()
                          }
                          onDragOver={(e) => {
                            e.preventDefault()
                            e.currentTarget.style.borderColor = '#CAAAE1'
                          }}
                          onDragLeave={(e) => {
                            e.preventDefault()
                            e.currentTarget.style.borderColor = '#e2e8f0'
                          }}
                          onDrop={async (e) => {
                            e.preventDefault()
                            e.currentTarget.style.borderColor = '#e2e8f0'
                            const file = e.dataTransfer.files[0]
                            if (file && file.type.startsWith('image/')) {
                              try {
                                const downloadURL = await uploadTeamMemberImage(
                                  file,
                                  index
                                )
                                handleTeamMemberChange(
                                  index,
                                  'photo_url',
                                  downloadURL
                                )
                              } catch (error) {
                                alert(error.message)
                              }
                            } else {
                              alert('Please upload an image file')
                            }
                          }}
                        >
                          <input
                            type="file"
                            id={`team-member-image-${index}`}
                            hidden
                            accept="image/*"
                            onChange={async (e) => {
                              const file = e.target.files[0]
                              if (file) {
                                try {
                                  const downloadURL =
                                    await uploadTeamMemberImage(file, index)
                                  handleTeamMemberChange(
                                    index,
                                    'photo_url',
                                    downloadURL
                                  )
                                } catch (error) {
                                  alert(error.message)
                                }
                              }
                            }}
                          />
                          <TeamMemberAvatar
                            src={member.photo_url || '/default-avatar.png'}
                            alt={member.name || 'Team member'}
                          />
                          <p
                            style={{
                              margin: '8px 0 0',
                              color: '#666',
                              fontSize: '14px'
                            }}
                          >
                            Drag and drop an image or click to upload
                          </p>
                        </ImageUploadZone>

                        <ModernInput>
                          <InputLabel>Name</InputLabel>
                          <StyledInput
                            value={member.name || ''}
                            onChange={(e) =>
                              handleTeamMemberChange(
                                index,
                                'name',
                                e.target.value
                              )
                            }
                            placeholder="Enter team member's name"
                          />
                        </ModernInput>

                        <ModernInput>
                          <InputLabel>Title</InputLabel>
                          <StyledInput
                            value={member.title || ''}
                            onChange={(e) =>
                              handleTeamMemberChange(
                                index,
                                'title',
                                e.target.value
                              )
                            }
                            placeholder="Enter team member's title or role"
                          />
                        </ModernInput>

                        <ModernInput>
                          <InputLabel>LinkedIn URL</InputLabel>
                          <StyledInput
                            value={member.linkedin_url || ''}
                            onChange={(e) =>
                              handleTeamMemberChange(
                                index,
                                'linkedin_url',
                                e.target.value
                              )
                            }
                            placeholder="Enter LinkedIn profile URL"
                          />
                        </ModernInput>
                      </TeamMemberModal>
                    ) : (
                      <TeamMemberInfo>
                        <TeamMemberAvatar
                          src={member.photo_url || '/default-avatar.png'}
                          alt={member.name}
                        />
                        <TeamMemberName>{member.name}</TeamMemberName>
                        <TeamMemberTitle>{member.title}</TeamMemberTitle>
                        {member.linkedin_url && (
                          <TeamMemberLinkedIn
                            href={member.linkedin_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Linkedin size={16} /> LinkedIn
                          </TeamMemberLinkedIn>
                        )}
                      </TeamMemberInfo>
                    )}
                  </TeamMember>
                ))}
              </TeamGrid>
              {isEditing && editedProfile.team.length < 3 && (
                <Button
                  onClick={handleAddTeamMember}
                  style={{ marginTop: '24px' }}
                >
                  <Plus size={16} /> Add Team Member
                </Button>
              )}
            </>
          ) : (
            <EmptySection>
              <EmptyText>No team members added yet</EmptyText>
              {isEditing && (
                <Button onClick={handleAddTeamMember}>
                  <Plus size={16} /> Add Team Member
                </Button>
              )}
            </EmptySection>
          )}
        </Card>

        <Card>
          <CardTitle>
            <Heart size={20} /> Benefits
          </CardTitle>
          {(editedProfile.benefits || []).length > 0 ? (
            <>
              <BenefitsGrid>
                {editedProfile.benefits.map((benefit, index) => (
                  <BenefitCard key={index}>
                    {isEditing && (
                      <RemoveButton onClick={() => handleRemoveBenefit(index)}>
                        <Trash2 size={16} />
                      </RemoveButton>
                    )}
                    <BenefitIcon>
                      {React.createElement(getIconComponent(benefit.category), {
                        size: 24
                      })}
                    </BenefitIcon>
                    <h3>
                      {
                        benefitCategories.find(
                          (cat) => cat.value === benefit.category
                        )?.label
                      }
                    </h3>
                    <p>{benefit.description}</p>
                  </BenefitCard>
                ))}
              </BenefitsGrid>
              {isEditing && editedProfile.benefits.length < 6 && (
                <Button
                  onClick={() => setShowBenefitModal(true)}
                  style={{ marginTop: '16px' }}
                >
                  <Plus size={16} /> Add Benefit
                </Button>
              )}
            </>
          ) : (
            <EmptySection>
              <EmptyText>No benefits listed yet</EmptyText>
              {isEditing && (
                <Button onClick={() => setShowBenefitModal(true)}>
                  <Plus size={16} /> Add Benefit
                </Button>
              )}
            </EmptySection>
          )}

          {/* Benefit Add/Edit Modal */}
          {showBenefitModal && (
            <>
              <Modal>
                <ModalHeader>
                  <ModalTitle>Add Benefit</ModalTitle>
                  <RemoveButton onClick={() => setShowBenefitModal(false)}>
                    <Trash2 size={16} />{' '}
                    {/* Close icon, or any other close method */}
                  </RemoveButton>
                </ModalHeader>
                <BenefitForm>
                  <ModernInput>
                    <InputLabel>Benefit Category</InputLabel>
                    <Select
                      value={newBenefit.category}
                      onChange={(e) =>
                        setNewBenefit({
                          ...newBenefit,
                          category: e.target.value
                        })
                      }
                    >
                      <option value="">Select a category</option>
                      {benefitCategories.map((category) => (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      ))}
                    </Select>
                  </ModernInput>
                  <ModernInput>
                    <InputLabel>Benefit Description</InputLabel>
                    <StyledInput
                      value={newBenefit.description}
                      onChange={(e) =>
                        setNewBenefit({
                          ...newBenefit,
                          description: e.target.value
                        })
                      }
                      placeholder="Enter benefit details"
                    />
                  </ModernInput>
                  <Button
                    onClick={handleAddBenefit}
                    style={{ marginTop: '8px' }}
                  >
                    Add Benefit
                  </Button>
                </BenefitForm>
              </Modal>

              {/* Overlay for modal */}
              <ModalOverlay onClick={() => setShowBenefitModal(false)} />
            </>
          )}
        </Card>
        <Card>
          <CardTitle>
            <NotebookPen size={20} /> Open Positions
          </CardTitle>
          {activeJobs.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <TableHeader>Position</TableHeader>
                  <TableHeader>Details</TableHeader>
                  <TableHeader>Salary</TableHeader>
                </tr>
              </thead>
              <tbody>
                {activeJobs.map((job) => (
                  <TableRow
                    key={job.id}
                    onClick={() => navigate(`/job-details/${job.id}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <strong>{job.title}</strong>
                    </TableCell>
                    <TableCell>
                      <div>{job.department}</div>
                      <div style={{ color: '#666', fontSize: '14px' }}>
                        {job.location} • {job.work_policy}
                      </div>
                      <div style={{ color: '#666', fontSize: '14px' }}>
                        {job.type.join(', ')} • {job.level}
                      </div>
                    </TableCell>
                    <TableCell>
                      {job.salary.type === 'unpaid'
                        ? 'Unpaid'
                        : job.salary.type === 'credit'
                        ? 'School Credit'
                        : `${new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 0
                          }).format(job.salary.range_start)} -
                ${new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0
                }).format(job.salary.range_end)}${
                            job.salary.type === 'hourly' ? '/hr' : '/year'
                          }`}
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          ) : (
            <EmptyPositions>
              <EmptyText>No open positions yet</EmptyText>
              <Button onClick={handlePostJob}>
                <Briefcase size={16} /> Post a Job
              </Button>
            </EmptyPositions>
          )}
        </Card>
        <Card>
          <CardTitle>
            <Link size={20} /> Social Media
          </CardTitle>
          {isEditing ? (
            <div style={{ display: 'grid', gap: '16px' }}>
              <div>
                <Input
                  value={editedProfile.company_facebook || ''}
                  onChange={(e) =>
                    handleInputChange('company_facebook', e.target.value)
                  }
                  placeholder="Facebook URL"
                />
              </div>
              <div>
                <Input
                  value={editedProfile.company_linkedin || ''}
                  onChange={(e) =>
                    handleInputChange('linkedin', e.target.value)
                  }
                  placeholder="LinkedIn URL"
                />
              </div>
              <div>
                <Input
                  value={editedProfile.additional_url || ''}
                  onChange={(e) =>
                    handleInputChange('additional_url', e.target.value)
                  }
                  placeholder="Additional URL"
                />
              </div>
            </div>
          ) : (
            <ContactLinks>
              {profile.company_facebook && (
                <ContactLink
                  href={profile.company_facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook size={24} />
                </ContactLink>
              )}
              {profile.company_linkedin && (
                <ContactLink
                  href={profile.linkedin}
                  target="_blank"
                  style={{ cursor: 'pointer' }}
                  rel="noopener noreferrer"
                >
                  <Linkedin size={24} />
                </ContactLink>
              )}
              {profile.additional_url && (
                <ContactLink
                  href={profile.additional_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Globe size={24} />
                </ContactLink>
              )}
              {!profile.company_facebook &&
                !profile.company_linkedin &&
                !profile.additional_url && (
                  <EmptySection>
                    <EmptyText>No social media links added yet</EmptyText>
                    {isEditing && (
                      <Button onClick={handleEdit}>
                        <Plus size={16} /> Add Social Media Links
                      </Button>
                    )}
                  </EmptySection>
                )}
            </ContactLinks>
          )}
        </Card>
      </MainContent>
    </Container>
  )
}
