import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { auth } from './firebase'
import SplashPage from './components/SplashPage'
import JobSeekerDashboard from './components/JobSeekerDashboard'
import EmployerDashboard from './components/EmployerDashboard'
import EmployerIntakeForm from './components/EmployerIntakeForm'
import AddEmployers from './components/AddEmployers'
import EmployerProfile from './components/EmployerProfile'
import CandidateSourcing from './components/CandidateSourcing'
import JobPostForm from './components/PostJob'
import JobPostings from './components/JobPostings'
import JobDetails from './components/JobDetails'
import Account from './components/Account'
import JobseekerIntake from './components/JobSeekerIntake'
import DemographicIntake from './components/DemographicIntake'
import EmployerProfileJobSeekerView from './components/EmployerProfileJobSeekerView'
import JobSeekerProfile from './components/JobSeekerProfile'
import CompanySearch from './components/CompanySearch'
import JobSearch from './components/JobSearch'
import JobSeekerProfileView from './components/JobSeekerProfileEmployerview'
import JobSeekerJobView from './components/JobSeekerJobView'
import ApplicationTracking from './components/ApplicationTracking'

function App() {
  const [authLoading, setAuthLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user)
      setAuthLoading(false)
    })

    return () => unsubscribe()
  }, [])

  if (authLoading) {
    return <div>Loading...</div>
  }

  function ProtectedRoute({ children }) {
    return user ? children : <Navigate to="/" />
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SplashPage />} />
          <Route
            path="/jobseeker-dashboard"
            element={
              <ProtectedRoute>
                <JobSeekerDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-intake"
            element={
              <ProtectedRoute>
                <EmployerIntakeForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-dashboard"
            element={
              <ProtectedRoute>
                <EmployerDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-profile"
            element={
              <ProtectedRoute>
                <EmployerProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/candidate-sourcing"
            element={
              <ProtectedRoute>
                <CandidateSourcing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-post-form"
            element={
              <ProtectedRoute>
                <JobPostForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-postings"
            element={
              <ProtectedRoute>
                <JobPostings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-details/:jobId"
            element={
              <ProtectedRoute>
                <JobDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-intake"
            element={
              <ProtectedRoute>
                <JobseekerIntake />
              </ProtectedRoute>
            }
          />
          <Route
            path="/demographic-intake"
            element={
              <ProtectedRoute>
                <DemographicIntake />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-profile"
            element={
              <ProtectedRoute>
                <JobSeekerProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-profile-view/:employerId"
            element={
              <ProtectedRoute>
                <EmployerProfileJobSeekerView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobseeker-profile-view/:jobseekerId"
            element={
              <ProtectedRoute>
                <JobSeekerProfileView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/company-search"
            element={
              <ProtectedRoute>
                <CompanySearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-search"
            element={
              <ProtectedRoute>
                <JobSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-opening/:jobpostingId"
            element={
              <ProtectedRoute>
                <JobSeekerJobView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/application-tracking"
            element={
              <ProtectedRoute>
                <ApplicationTracking />
              </ProtectedRoute>
            }
          />

          <Route path="/add-employers" element={<AddEmployers />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
