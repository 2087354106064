import { db } from '../firebase'
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  serverTimestamp,
  doc,
  increment
} from 'firebase/firestore'

const JOB_APPLICATIONS_COLLECTION = 'jobApplications'
const JOB_POSTINGS_COLLECTION = 'jobPostings'

// JobApplication data structure
const createJobApplicationObject = (applicationData) => ({
  jobseeker_id: applicationData.jobseeker_id,
  job_posting_id: applicationData.job_posting_id,
  cover_letter: applicationData.cover_letter || null,
  resume_url: applicationData.resume_url || null,
  status: applicationData.status || 'submitted',
  company_name: applicationData.company_name, // Ensure company name is directly used from applicationData
  company_logo_url: applicationData.company_logo_url, // Ensure company logo URL is directly used
  job_title: applicationData.job_title, // Ensure job title is directly used
  rejection_reasoning: applicationData.rejection_reasoning || null,
  created_at: serverTimestamp(),
  updated_at: serverTimestamp()
})

// Increment the number of applicants for a job posting
const incrementJobPostingApplicants = async (jobPostingId) => {
  try {
    const jobRef = doc(db, JOB_POSTINGS_COLLECTION, jobPostingId)
    await updateDoc(jobRef, {
      number_of_applicants: increment(1)
    })
  } catch (error) {
    console.error('Error incrementing job posting applicants:', error)
  }
}

// Decrement the number of applicants for a job posting
const decrementJobPostingApplicants = async (jobPostingId) => {
  try {
    const jobRef = doc(db, JOB_POSTINGS_COLLECTION, jobPostingId)
    await updateDoc(jobRef, {
      number_of_applicants: increment(-1)
    })
  } catch (error) {
    console.error('Error decrementing job posting applicants:', error)
  }
}

// Create a job application
export const createJobApplication = async (applicationData) => {
  try {
    // Log the application data to verify values
    console.log('Creating application with data:', applicationData)

    const applicationRef = await addDoc(
      collection(db, JOB_APPLICATIONS_COLLECTION),
      createJobApplicationObject(applicationData)
    )
    console.log('Job application created with ID:', applicationRef.id)

    // Increment the number of applicants for the job posting
    await incrementJobPostingApplicants(applicationData.job_posting_id)

    return applicationRef
  } catch (error) {
    console.error('Error creating job application:', error)
    throw error
  }
}

// Get a specific job application
export const getJobApplication = async (applicationId) => {
  try {
    const applicationRef = doc(db, JOB_APPLICATIONS_COLLECTION, applicationId)
    const applicationSnap = await getDoc(applicationRef)

    if (applicationSnap.exists()) {
      return { id: applicationSnap.id, ...applicationSnap.data() }
    } else {
      throw new Error('Job application not found')
    }
  } catch (error) {
    console.error('Error fetching job application:', error)
    throw error
  }
}

// Get all job applications for a specific job posting
export const getJobApplicationsForPosting = async (jobPostingId) => {
  try {
    const q = query(
      collection(db, JOB_APPLICATIONS_COLLECTION),
      where('job_posting_id', '==', jobPostingId)
    )

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  } catch (error) {
    console.error('Error fetching job applications for posting:', error)
    throw error
  }
}

// Get all job applications for a specific jobseeker
export const getJobseekerApplications = async (jobseekerId) => {
  try {
    const q = query(
      collection(db, JOB_APPLICATIONS_COLLECTION),
      where('jobseeker_id', '==', jobseekerId)
    )

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  } catch (error) {
    console.error('Error fetching jobseeker applications:', error)
    throw error
  }
}

// Update a job application
export const updateJobApplication = async (applicationId, updateData) => {
  try {
    const applicationRef = doc(db, JOB_APPLICATIONS_COLLECTION, applicationId)
    await updateDoc(applicationRef, {
      ...updateData,
      updated_at: serverTimestamp()
    })
  } catch (error) {
    console.error('Error updating job application:', error)
    throw error
  }
}

// Delete a job application
export const deleteJobApplication = async (applicationId) => {
  try {
    const applicationRef = doc(db, JOB_APPLICATIONS_COLLECTION, applicationId)
    const applicationSnap = await getDoc(applicationRef)

    if (applicationSnap.exists()) {
      const applicationData = applicationSnap.data()
      await deleteDoc(applicationRef)

      // Decrement the number of applicants for the job posting
      await decrementJobPostingApplicants(applicationData.job_posting_id)
      console.log('Job application deleted and applicant count updated')
    } else {
      throw new Error('Job application not found')
    }
  } catch (error) {
    console.error('Error deleting job application:', error)
    throw error
  }
}

export default {
  createJobApplication,
  getJobApplication,
  getJobApplicationsForPosting,
  getJobseekerApplications,
  updateJobApplication,
  deleteJobApplication
}
