import React, { useState } from 'react'
import styled from 'styled-components'
import { auth, db } from '../firebase' // Adjust path if necessary
import { createUserWithEmailAndPassword } from 'firebase/auth'
import {
  doc,
  setDoc,
  serverTimestamp,
  addDoc,
  collection
} from 'firebase/firestore'

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f3f4f6;
`

const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  max-width: 600px;
  width: 100%;
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #3730a3;
  margin-bottom: 1rem;
`

const SubTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: #4f46e5;
  margin-bottom: 1.5rem;
`

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 9999px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #8b5cf6;
    box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.1);
  }
`

const Button = styled.button`
  background-color: #8b5cf6;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem 1rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #7c3aed;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(139, 92, 246, 0.25);
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.875rem;
`

const SuccessMessage = styled.p`
  color: green;
  font-size: 0.875rem;
`

const MemberGroup = styled.div`
  margin-bottom: 1rem;
`

const AddEmployers = () => {
  // Form state for employer
  const [employerData, setEmployerData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    companyName: ''
  })

  // Form state for employer members (optional)
  const [employerMembers, setEmployerMembers] = useState([]) // Initially empty array
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  // Handle input change for employer form
  const handleEmployerChange = (e) => {
    setEmployerData({
      ...employerData,
      [e.target.name]: e.target.value
    })
  }

  // Handle input change for employer members
  const handleMemberChange = (index, e) => {
    const newMembers = [...employerMembers]
    newMembers[index][e.target.name] = e.target.value
    setEmployerMembers(newMembers)
  }

  // Add a new empty member to the form
  const addNewMember = () => {
    setEmployerMembers([
      ...employerMembers,
      { firstName: '', lastName: '', email: '', password: '' }
    ])
  }

  const addEmployerToFirebase = async (e) => {
    e.preventDefault()
    try {
      // Create the main employer in Firebase Authentication
      const employerCredential = await createUserWithEmailAndPassword(
        auth,
        employerData.email,
        employerData.password
      )
      const employerUser = employerCredential.user

      // Add employer details to Firestore
      const userRef = doc(db, 'users', employerUser.uid)
      await setDoc(userRef, {
        email: employerData.email,
        firebase_uid: employerUser.uid,
        role: 'employer',
        first_name: employerData.firstName,
        last_name: employerData.lastName,
        company_name: employerData.companyName,
        created_at: serverTimestamp(),
        updated_at: serverTimestamp()
      })

      // Add employer members only if there are members filled in the form
      if (employerMembers.length > 0) {
        for (const member of employerMembers) {
          if (member.email && member.password) {
            // Create member in Firebase Authentication
            const memberCredential = await createUserWithEmailAndPassword(
              auth,
              member.email,
              member.password
            )
            const memberUser = memberCredential.user

            // Add employer member to Firestore as a user with role 'employerMember'
            const memberRef = doc(db, 'users', memberUser.uid)
            await setDoc(memberRef, {
              email: member.email,
              firebase_uid: memberUser.uid,
              role: 'employerMember',
              first_name: member.firstName,
              last_name: member.lastName,
              company_name: employerData.companyName, // Link to employer's company
              created_at: serverTimestamp(),
              updated_at: serverTimestamp()
            })
          }
        }
      }

      setSuccessMessage(
        `Successfully added employer${
          employerMembers.length > 0 ? ' and members' : ''
        } for: ${employerData.companyName}`
      )
      setError('')
      setEmployerData({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        companyName: ''
      })
      setEmployerMembers([]) // Reset the member list
    } catch (error) {
      console.error('Error adding employer or members:', error)
      setError('Failed to add employer or members. Please try again.')
      setSuccessMessage('')
    }
  }

  return (
    <Container>
      <FormContainer>
        <Title>Add Employer</Title>
        <form onSubmit={addEmployerToFirebase}>
          {/* Employer details form */}
          <SubTitle>Employer Information</SubTitle>
          <Input
            type="email"
            name="email"
            placeholder="Employer Email"
            value={employerData.email}
            onChange={handleEmployerChange}
            required
          />
          <Input
            type="password"
            name="password"
            placeholder="Employer Password"
            value={employerData.password}
            onChange={handleEmployerChange}
            required
          />
          <Input
            type="text"
            name="firstName"
            placeholder="Employer First Name"
            value={employerData.firstName}
            onChange={handleEmployerChange}
            required
          />
          <Input
            type="text"
            name="lastName"
            placeholder="Employer Last Name"
            value={employerData.lastName}
            onChange={handleEmployerChange}
            required
          />
          <Input
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={employerData.companyName}
            onChange={handleEmployerChange}
            required
          />

          {/* Employer members form */}
          <SubTitle>Employer Members (Optional)</SubTitle>
          {employerMembers.map((member, index) => (
            <MemberGroup key={index}>
              <Input
                type="text"
                name="firstName"
                placeholder="Member First Name"
                value={member.firstName}
                onChange={(e) => handleMemberChange(index, e)}
              />
              <Input
                type="text"
                name="lastName"
                placeholder="Member Last Name"
                value={member.lastName}
                onChange={(e) => handleMemberChange(index, e)}
              />
              <Input
                type="email"
                name="email"
                placeholder="Member Email"
                value={member.email}
                onChange={(e) => handleMemberChange(index, e)}
              />
              <Input
                type="password"
                name="password"
                placeholder="Member Password"
                value={member.password}
                onChange={(e) => handleMemberChange(index, e)}
              />
            </MemberGroup>
          ))}
          <Button type="button" onClick={addNewMember}>
            Add Another Member
          </Button>

          <Button type="submit">Add Employer and Members</Button>
        </form>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      </FormContainer>
    </Container>
  )
}

export default AddEmployers
