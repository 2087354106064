import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import {
  Search,
  Briefcase,
  MapPin,
  Building,
  Clock,
  X,
  Heart,
  Calendar,
  GraduationCap,
  Laptop,
  Smile,
  Monitor,
  Users,
  Train,
  Coffee,
  Shield,
  ChevronDown,
  ChevronUp,
  Lock
} from 'lucide-react'
import { getEmployerProfile } from '../models/EmployerProfile'
import { BanknoteIcon } from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { useNavigate } from 'react-router-dom'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748;
`

const SearchBar = styled.div`
  display: flex;
  margin-bottom: 2rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const SearchInput = styled.input`
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 1rem;
  color: #4a5568;
  margin-right: 8px;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 1rem;
  color: #4a5568;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a5568;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2d3748;
  }
`

const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #caaae1;

  &:hover {
    background-color: #3182ce;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
`

const Sidebar = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: fit-content;
  position: sticky;
  top: 2rem;
`

const FilterCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
`

const FilterTitle = styled.h2`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`

const FilterSection = styled.div`
  margin-bottom: 16px;
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 0;
  border-bottom: 1px solid #e2e8f0;
`

const FilterContent = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  max-height: 300px;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
`

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 8px;

  &:focus {
    outline: none;
    border-color: #94a3b8;
  }
`

const Checkbox = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #caaae1;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: #caaae1;
  }

  &:checked::after {
    content: '✓';
    font-size: 12px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.5);
  }
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 4px;
  cursor: pointer;
`

const ClearButton = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    background-color: #b68fd8;
  }
`

const JobList = styled.div`
  flex-grow: 1;
`

const JobListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`

const JobCard = styled.div`
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1.5rem;
  background-color: white;
  margin-bottom: 1.5rem;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`

const JobCardContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  cursor: pointer;
`

const CompanyLogo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  object-fit: contain;
  background-color: #f8f9fa;
`

const JobInfo = styled.div`
  flex: 1;
`

const JobTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #1a202c;
  margin: 0 0 8px 0;
`

const CompanyName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4a5568;
  margin-bottom: 12px;
`

const JobMetadata = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  font-size: 14px;
  color: #4a5568;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

const SkeletonCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
`

const SkeletonContent = styled.div`
  display: flex;
  gap: 16px;
`

const SkeletonImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background: #f1f5f9;
`

const SkeletonText = styled.div`
  flex: 1;

  div {
    height: ${(props) => props.height || '20px'};
    width: ${(props) => props.width || '100%'};
    background: #f1f5f9;
    border-radius: 4px;
    margin-bottom: 8px;
  }
`

const JobCardWrapper = styled.div`
  position: relative; // Added to establish positioning context

  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 16px;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`
const MatchBubble = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: #22c55e;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: help;
  z-index: 1; // Added to ensure bubble appears above other content

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`

const Tooltip = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 8px;
  background-color: #1f2937;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  transition: all 0.2s ease;
  z-index: 10;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    right: 16px;
    border-width: 6px;
    border-style: solid;
    border-color: #1f2937 transparent transparent transparent;
  }
`

const employmentTypes = [
  'Full-Time',
  'Part-time',
  'Internship',
  'Contract',
  'Hourly'
]

const levelOptions = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]

const workPolicyOptions = ['Remote', 'In-Office', 'Hybrid']

const benefitCategories = [
  { value: 'Healthcare', label: 'Healthcare', icon: Heart },
  { value: 'Time Off', label: 'Time Off', icon: Calendar },
  {
    value: 'Learning & Development',
    label: 'Learning & Development',
    icon: GraduationCap
  },
  { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
  { value: 'Wellness', label: 'Wellness', icon: Smile },
  { value: 'Equipment', label: 'Equipment', icon: Monitor },
  { value: 'Family', label: 'Family', icon: Users },
  { value: 'Commuter', label: 'Commuter', icon: Train },
  { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
  { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
  { value: 'Insurance', label: 'Insurance', icon: Shield }
]

const CollapsibleFilterSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <FilterSection>
      <FilterHeader onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </FilterHeader>
      <FilterContent isOpen={isOpen}>{children}</FilterContent>
    </FilterSection>
  )
}

const SearchableCheckboxList = ({
  options,
  selectedOptions,
  onChange,
  name
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <>
      <Input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {filteredOptions.map((option) => (
        <CheckboxLabel key={option}>
          <Checkbox
            type="checkbox"
            name={name}
            value={option}
            checked={selectedOptions.includes(option)}
            onChange={onChange}
          />
          {option}
        </CheckboxLabel>
      ))}
    </>
  )
}

const JobSearch = () => {
  const [jobPostings, setJobPostings] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    searchTerm: '',
    companies: [],
    titles: [],
    recommendedSkills: [],
    locations: [],
    workPolicies: [],
    departments: [],
    levels: [],
    types: [],
    salaryMin: '',
    salaryMax: '',
    willingToSponsor: false,
    benefits: []
  })

  const [filteredJobs, setFilteredJobs] = useState([])
  const [filterOptions, setFilterOptions] = useState({
    companies: [],
    titles: [],
    recommendedSkills: [],
    locations: [],
    departments: []
  })

  useEffect(() => {
    const fetchEmployersAndJobs = async () => {
      try {
        setLoading(true)

        const employersQuery = query(
          collection(db, 'users'),
          where('role', '==', 'employer')
        )
        const employersSnapshot = await getDocs(employersQuery)
        console.log(`Found ${employersSnapshot.size} employers`)

        const employerProfiles = {}

        for (const employerDoc of employersSnapshot.docs) {
          const userData = employerDoc.data()
          console.log('Processing employer:', userData.company_name)

          try {
            const profileData = await getEmployerProfile(employerDoc.id)
            console.log('Found profile data:', profileData)

            if (profileData) {
              const employerId = profileData.employer_id || userData.employer_id
              if (employerId) {
                employerProfiles[employerId] = {
                  company_name:
                    profileData.company_name || userData.company_name,
                  company_logo_url:
                    profileData.company_logo_url || '/api/placeholder/50/50',
                  company_website: profileData.company_website,
                  industry: profileData.industry
                }
                console.log('Mapped profile:', employerProfiles[employerId])
              }
            } else {
              if (userData.employer_id) {
                employerProfiles[userData.employer_id] = {
                  company_name: userData.company_name,
                  company_logo_url: '/api/placeholder/50/50'
                }
              }
            }
          } catch (error) {
            console.log('Using basic data for:', userData.company_name)
            if (userData.employer_id) {
              employerProfiles[userData.employer_id] = {
                company_name: userData.company_name,
                company_logo_url: '/api/placeholder/50/50'
              }
            }
          }
        }

        console.log('Final employer mappings:', employerProfiles)

        const jobsQuery = query(
          collection(db, 'jobPostings'),
          where('status', '==', 'active')
        )
        const snapshot = await getDocs(jobsQuery)

        const postings = snapshot.docs.map((doc) => {
          const jobData = doc.data()
          const employerId = jobData.employer_id?.toString()
          console.log('Processing job:', {
            title: jobData.title,
            employerId: employerId
          })

          const employerProfile = employerProfiles[employerId]
          console.log('Found employer profile for job:', employerProfile)

          return {
            id: doc.id,
            ...jobData,
            employerProfile: employerProfile || {
              company_name: jobData.company_name || 'Unknown Company',
              company_logo_url: '/api/placeholder/50/50'
            }
          }
        })

        setJobPostings(postings)
        setFilteredJobs(postings)

        // Extract unique filter options from job postings
        const options = postings.reduce(
          (acc, job) => {
            acc.companies.add(job.employerProfile.company_name)
            acc.titles.add(job.title)
            job.recommended_skills.forEach((skill) =>
              acc.recommendedSkills.add(skill)
            )
            acc.locations.add(job.location)
            acc.departments.add(job.department)
            return acc
          },
          {
            companies: new Set(),
            titles: new Set(),
            recommendedSkills: new Set(),
            locations: new Set(),
            departments: new Set()
          }
        )

        setFilterOptions({
          companies: Array.from(options.companies),
          titles: Array.from(options.titles),
          recommendedSkills: Array.from(options.recommendedSkills),
          locations: Array.from(options.locations),
          departments: Array.from(options.departments)
        })
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchEmployersAndJobs()
  }, [])

  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...jobPostings]

      if (filters.searchTerm) {
        const searchLower = filters.searchTerm.toLowerCase()
        filtered = filtered.filter(
          (job) =>
            job.title.toLowerCase().includes(searchLower) ||
            job.employerProfile?.company_name
              .toLowerCase()
              .includes(searchLower) ||
            job.description.toLowerCase().includes(searchLower)
        )
      }

      if (filters.companies.length > 0) {
        filtered = filtered.filter((job) =>
          filters.companies.includes(job.employerProfile?.company_name)
        )
      }

      if (filters.titles.length > 0) {
        filtered = filtered.filter((job) => filters.titles.includes(job.title))
      }

      if (filters.recommendedSkills.length > 0) {
        filtered = filtered.filter((job) =>
          filters.recommendedSkills.some((skill) =>
            job.recommended_skills.includes(skill)
          )
        )
      }

      if (filters.locations.length > 0) {
        filtered = filtered.filter((job) =>
          filters.locations.includes(job.location)
        )
      }

      if (filters.workPolicies.length > 0) {
        filtered = filtered.filter((job) =>
          filters.workPolicies.includes(job.work_policy)
        )
      }

      if (filters.departments.length > 0) {
        filtered = filtered.filter((job) =>
          filters.departments.includes(job.department)
        )
      }

      if (filters.levels.length > 0) {
        filtered = filtered.filter((job) => filters.levels.includes(job.level))
      }

      if (filters.types.length > 0) {
        filtered = filtered.filter((job) =>
          filters.types.some((type) => job.type.includes(type))
        )
      }

      if (filters.salaryMin) {
        filtered = filtered.filter(
          (job) => job.salary.range_start >= parseInt(filters.salaryMin)
        )
      }

      if (filters.salaryMax) {
        filtered = filtered.filter(
          (job) => job.salary.range_end <= parseInt(filters.salaryMax)
        )
      }

      if (filters.willingToSponsor) {
        filtered = filtered.filter((job) => job.willing_to_sponsor)
      }

      if (filters.benefits.length > 0) {
        filtered = filtered.filter((job) =>
          filters.benefits.every((benefit) => job.benefits.includes(benefit))
        )
      }

      setFilteredJobs(filtered)
    }

    applyFilters()
  }, [filters, jobPostings])

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      if (name === 'willingToSponsor') {
        setFilters((prev) => ({ ...prev, [name]: checked }))
      } else {
        setFilters((prev) => ({
          ...prev,
          [name]: checked
            ? [...prev[name], value]
            : prev[name].filter((item) => item !== value)
        }))
      }
    } else {
      setFilters((prev) => ({ ...prev, [name]: value }))
    }
  }

  const clearFilters = () => {
    setFilters({
      searchTerm: '',
      companies: [],
      titles: [],
      recommendedSkills: [],
      locations: [],
      workPolicies: [],
      departments: [],
      levels: [],
      types: [],
      salaryMin: '',
      salaryMax: '',
      willingToSponsor: false,
      benefits: []
    })
  }

  const handleJobClick = (jobId, job) => {
    navigate(`/job-opening/${jobId}`, {
      state: {
        companyName: job.employerProfile?.company_name,
        companyLogoUrl: job.employerProfile?.company_logo_url
      }
    })
  }
  const JobCard = ({ job }) => (
    <JobCardWrapper onClick={() => handleJobClick(job.id, job)}>
      <MatchBubble>
        <Lock size={14} />% Match
        <Tooltip className="tooltip">
          AI matches will be available October 31st
        </Tooltip>
      </MatchBubble>
      <JobCardContent>
        <CompanyLogo
          src={
            job.employerProfile?.company_logo_url &&
            job.employerProfile.company_logo_url !== '/api/placeholder/50/50'
              ? job.employerProfile.company_logo_url
              : '/api/placeholder/50/50'
          }
          alt={`${job.employerProfile?.company_name || 'Company'} logo`}
          onError={(e) => {
            e.target.src = '/api/placeholder/50/50'
          }}
        />
        <JobInfo>
          <JobTitle>{job.title}</JobTitle>
          <CompanyName>
            <Building size={16} />
            <span>
              {job.employerProfile?.company_name || 'Unknown Company'}
            </span>
          </CompanyName>
          <JobMetadata>
            {job.location && (
              <div>
                <MapPin size={16} />
                <span>{job.location}</span>
              </div>
            )}
            {job.level && (
              <div>
                <Briefcase size={16} />
                <span>{job.level}</span>
              </div>
            )}
            {job.type && (
              <div>
                <Clock size={16} />
                <span>
                  {Array.isArray(job.type) ? job.type.join(', ') : job.type}
                </span>
              </div>
            )}
          </JobMetadata>
        </JobInfo>
      </JobCardContent>
    </JobCardWrapper>
  )

  return (
    <PageWrapper>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>

      <MainContent>
        <Header>
          <Title>🔍 Job Search</Title>
        </Header>

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search by title, company, or keywords"
            value={filters.searchTerm}
            onChange={(e) => handleFilterChange(e)}
            name="searchTerm"
          />
          <SearchButton onClick={() => {}}>
            <Search size={18} />
            Search
          </SearchButton>
        </SearchBar>

        <ContentWrapper>
          <Sidebar>
            <FilterCard>
              <FilterTitle>Filters</FilterTitle>
              <CollapsibleFilterSection title="Companies">
                <SearchableCheckboxList
                  options={filterOptions.companies}
                  selectedOptions={filters.companies}
                  onChange={handleFilterChange}
                  name="companies"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Job Titles">
                <SearchableCheckboxList
                  options={filterOptions.titles}
                  selectedOptions={filters.titles}
                  onChange={handleFilterChange}
                  name="titles"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Skills">
                <SearchableCheckboxList
                  options={filterOptions.recommendedSkills}
                  selectedOptions={filters.recommendedSkills}
                  onChange={handleFilterChange}
                  name="recommendedSkills"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Locations">
                <SearchableCheckboxList
                  options={filterOptions.locations}
                  selectedOptions={filters.locations}
                  onChange={handleFilterChange}
                  name="locations"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Work Policy">
                <SearchableCheckboxList
                  options={workPolicyOptions}
                  selectedOptions={filters.workPolicies}
                  onChange={handleFilterChange}
                  name="workPolicies"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Department">
                <SearchableCheckboxList
                  options={filterOptions.departments}
                  selectedOptions={filters.departments}
                  onChange={handleFilterChange}
                  name="departments"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Level">
                <SearchableCheckboxList
                  options={levelOptions}
                  selectedOptions={filters.levels}
                  onChange={handleFilterChange}
                  name="levels"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Type">
                <SearchableCheckboxList
                  options={employmentTypes}
                  selectedOptions={filters.types}
                  onChange={handleFilterChange}
                  name="types"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Salary Range">
                <Input
                  type="number"
                  name="salaryMin"
                  placeholder="Min salary"
                  value={filters.salaryMin}
                  onChange={handleFilterChange}
                />
                <Input
                  type="number"
                  name="salaryMax"
                  placeholder="Max salary"
                  value={filters.salaryMax}
                  onChange={handleFilterChange}
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Benefits">
                <SearchableCheckboxList
                  options={benefitCategories.map((b) => b.label)}
                  selectedOptions={filters.benefits}
                  onChange={handleFilterChange}
                  name="benefits"
                />
              </CollapsibleFilterSection>
              <CollapsibleFilterSection title="Other">
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    name="willingToSponsor"
                    checked={filters.willingToSponsor}
                    onChange={handleFilterChange}
                  />
                  Willing to Sponsor
                </CheckboxLabel>
              </CollapsibleFilterSection>
              <ClearButton onClick={clearFilters}>
                <X size={16} style={{ marginRight: '8px' }} />
                Clear Filters
              </ClearButton>
            </FilterCard>
          </Sidebar>

          <JobList>
            <JobListHeader>
              <h2>Available Jobs ({filteredJobs.length})</h2>
            </JobListHeader>
            {loading ? (
              <>
                {[...Array(3)].map((_, i) => (
                  <SkeletonCard key={i}>
                    <SkeletonContent>
                      <SkeletonImage />
                      <SkeletonText>
                        <div style={{ width: '33%', height: '24px' }} />
                        <div style={{ width: '25%' }} />
                        <div style={{ width: '40%' }} />
                      </SkeletonText>
                    </SkeletonContent>
                  </SkeletonCard>
                ))}
              </>
            ) : (
              <>
                {filteredJobs.map((job) => (
                  <JobCard key={job.id} job={job} />
                ))}
              </>
            )}
          </JobList>
        </ContentWrapper>
      </MainContent>
    </PageWrapper>
  )
}

export default JobSearch
