// src/components/JobDetails.js
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { auth } from '../firebase'
import { getJobPosting, updateJobPosting } from '../models/JobPosting'
import { getJobApplicationsForPosting } from '../models/JobApplication'
import { getUserById } from '../models/User'
import { getJobseekerProfile } from '../models/JobseekerProfile'
import { updateJobApplication } from '../models/JobApplication'
import { getAllJobSeekerProfiles } from '../models/JobseekerProfile'
import EmployerNav from './EmployerNav'
import {
  Edit,
  Save,
  X,
  ArrowLeft,
  Trash2,
  Plus,
  CheckCircle,
  AlertCircle,
  Building,
  MapPin,
  Globe,
  Briefcase,
  GraduationCap,
  BanknoteIcon,
  AlignLeft,
  ClipboardCheck,
  Star,
  Code,
  Gift,
  Hash,
  Heart,
  Calendar,
  Laptop,
  Smile,
  Lock,
  Monitor,
  Users,
  Train,
  Coffee,
  Shield
} from 'lucide-react'
import { Bar, Pie } from 'react-chartjs-2'

const benefitCategories = [
  { value: 'Healthcare', label: 'Healthcare', icon: Heart },
  { value: 'Time Off', label: 'Time Off', icon: Calendar },
  {
    value: 'Learning & Development',
    label: 'Learning & Development',
    icon: GraduationCap
  },
  { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
  { value: 'Wellness', label: 'Wellness', icon: Smile },
  { value: 'Equipment', label: 'Equipment', icon: Monitor },
  { value: 'Family', label: 'Family', icon: Users },
  { value: 'Commuter', label: 'Commuter', icon: Train },
  { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
  { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
  { value: 'Insurance', label: 'Insurance', icon: Shield }
]

// Styled Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f4f8;
  color: #2d3748;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e2e8f0;
  background-color: white;
  margin-bottom: 2rem;
  border-radius: 12px;
`

const TopBarActions = styled.div`
  display: flex;
  gap: 1rem;
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  background: none;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    background: #f1f5f9;
    color: #334155;
  }
`

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  ${(props) => {
    if (props.variant === 'delete') {
      return `
        background-color: #fee2e2;
        color: #dc2626;
        border: 1px solid #fecaca;

        &:hover {
          background-color: #fecaca;
        }
      `
    } else if (props.variant === 'hired') {
      return `
        background-color: #dcfce7;
        color: #16a34a;
        border: 1px solid #bbf7d0;

        &:hover {
          background-color: #bbf7d0;
        }
      `
    }
    return `
      background-color: #caaae1;
      color: white;
      border: none;

      &:hover {
        background-color: #b38fd1;
      }
    `
  }}
`

const Section = styled.section`
  background: white;
  border-radius: 16px;
  padding: 2.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const JobTitle = styled.h1`
  font-size: 2.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
`

const StatusBadge = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 1rem;

  ${(props) =>
    props.status === 'hired'
      ? `
    background-color: #dcfce7;
    color: #16a34a;
  `
      : `
    background-color: #f8f4fb;
    color: #805ad5;
  `}
`

const QuickInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 1rem;
`

const QuickInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background-color: #f8fafc;
  border-radius: 8px;

  svg {
    color: #caaae1;
  }
`

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
  padding: 2rem;
  background-color: #f8fafc;
  border-radius: 12px;
`

const ContentSection = styled.div`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  svg {
    color: #caaae1;
  }
`

const TextContent = styled.div`
  white-space: pre-wrap;
  color: #475569;
  line-height: 1.8;
  font-size: 1rem;
`

const EditableInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: ${(props) => (props.large ? '1.5rem' : '1rem')};
  font-weight: ${(props) => (props.large ? '600' : '400')};
  margin-bottom: 1rem;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`
const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
`

const MainContentArea = styled.div`
  flex: 1;
`

const EditableTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  min-height: 120px;
  font-family: inherit;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4999;
  backdrop-filter: blur(2px);
`

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
`

const SkillTag = styled.span`
  background-color: #f8f4fb;
  color: #805ad5;
  padding: 0.75rem 1.25rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #caaae1;
  }
`

const SalaryRange = styled.div`
  background-color: #f8f4fb;
  color: #805ad5;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  font-size: 1.25rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1.5rem; /* Adjust margin-top for spacing */

  svg {
    color: #caaae1;
  }
`

const EditableSelect = styled.select`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
`

const BenefitCard = styled.div`
  position: relative;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const BenefitIcon = styled.div`
  width: 48px;
  height: 48px;
  background-color: #f8f4fb;
  color: #caaae1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 5000;
  width: 90%;
  max-width: 500px;
`

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
`

const ModalText = styled.div`
  color: #475569;
  margin-bottom: 2rem;
  font-size: 0.95rem;
  line-height: 1.6;
`

const WarningList = styled.ul`
  margin: 1rem 0;
  padding-left: 1.5rem;

  li {
    margin-bottom: 0.5rem;
    color: #64748b;

    &::marker {
      color: ${(props) => (props.variant === 'delete' ? '#dc2626' : '#16a34a')};
    }
  }
`

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const ModalButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  ${(props) => {
    if (props.variant === 'cancel') {
      return `
        background-color: #f1f5f9;
        color: #64748b;
        border: none;

        &:hover {
          background-color: #e2e8f0;
        }
      `
    } else if (props.variant === 'delete') {
      return `
        background-color: #dc2626;
        color: white;
        border: none;

        &:hover {
          background-color: #b91c1c;
        }
      `
    } else if (props.variant === 'hired') {
      return `
        background-color: #16a34a;
        color: white;
        border: none;

        &:hover {
          background-color: #15803d;
        }
      `
    }
  }}
`

const ApplicantsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ApplicantInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

// const ApplicantAvatar = styled.div`
//   width: 48px;
//   height: 48px;
//   background-color: #f8f4fb;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #805ad5;
//   font-weight: 600;
//   font-size: 1.125rem;
// `

const ApplicantName = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
`

const ApplicantStatus = styled.span`
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  ${(props) => {
    switch (props.status) {
      case 'submitted':
        return `
          background-color: #f8f4fb;
          color: #805ad5;
        `
      case 'reviewing':
        return `
          background-color: #fef3c7;
          color: #d97706;
        `
      case 'accepted':
        return `
          background-color: #dcfce7;
          color: #16a34a;
        `
      case 'rejected':
        return `
          background-color: #fee2e2;
          color: #dc2626;
        `
      default:
        return `
          background-color: #f1f5f9;
          color: #64748b;
        `
    }
  }}
`

const ApplicantCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    border-color: #caaae1;
  }
`

const ApplicantDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

// const ApplicantNameSection = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
// `

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const ResumeLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: #805ad5;
  font-size: 0.875rem;
  text-decoration: none;
  margin-top: 0.125rem;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
`

const JobInfo = styled.div`
  font-size: 0.875rem;
  color: #64748b;
  margin-top: 0.25rem;
`

const ApplicantMetadata = styled.div`
  display: flex;
  gap: 1rem;
  color: #64748b;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`

const MatchBubble = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #dcfce7; /* Light green background */
  color: #16a34a; /* Dark green text */
  border-radius: 9999px;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;

  &:hover .tooltip {
    display: block;
  }
`

const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: -1.5rem;
  right: 0;
  background-color: #2d3748;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const LockIconStyled = styled(Lock)`
  color: #16a34a;
`

// Update the styled components
const ApplicantAvatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #f8f4fb;
  color: #805ad5;
  font-weight: 600;
  font-size: 1.125rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ApplicantNameSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Pronouns = styled.span`
  color: #64748b;
  font-size: 0.875rem;
  padding: 0.125rem 0.5rem;
  background-color: #f8fafc;
  border-radius: 4px;
`

const JobDetails = () => {
  const { jobId } = useParams()
  const navigate = useNavigate()
  const [jobData, setJobData] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editedData, setEditedData] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showHiredModal, setShowHiredModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [applicants, setApplicants] = useState([])
  const [applicantDetails, setApplicantDetails] = useState({})
  const [applicantProfiles, setApplicantProfiles] = useState({})
  const [loadingApplicants, setLoadingApplicants] = useState(true)
  const [demographicsData, setDemographicsData] = useState({
    gender: {},
    race: {},
    veteran_status: {},
    job_level: {}
  })

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const data = await getJobPosting(jobId)
        if (!data) {
          throw new Error('Job posting not found')
        }
        setJobData(data)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching job details:', error)
        alert('Error loading job details')
        navigate('/job-postings')
      }
    }

    fetchJobDetails()
  }, [jobId, navigate])

  const handleStartEdit = () => {
    setEditedData({ ...jobData })
    setIsEditing(true)
  }
  useEffect(() => {
    const fetchApplicantsAndDemographics = async () => {
      if (!jobId) return

      try {
        setLoadingApplicants(true)
        const applicantsData = await getJobApplicationsForPosting(jobId)
        setApplicants(applicantsData)

        // Get just the applicant IDs for this specific job
        const applicantIds = applicantsData.map(
          (applicant) => applicant.jobseeker_id
        )

        // Fetch user details and profiles for each applicant
        const detailsPromises = applicantsData.map(async (applicant) => {
          const userDetails = await getUserById(applicant.jobseeker_id)
          const profileDetails = await getJobseekerProfile(
            applicant.jobseeker_id
          )

          return {
            id: applicant.jobseeker_id,
            user: userDetails,
            profile: profileDetails
          }
        })

        const details = await Promise.all(detailsPromises)

        const userMap = {}
        const profileMap = {}
        details.forEach(({ id, user, profile }) => {
          userMap[id] = user
          profileMap[id] = profile
        })

        setApplicantDetails(userMap)
        setApplicantProfiles(profileMap)

        if (applicantIds.length > 0) {
          // Get demographics only for the applicants of this job
          const { demographicsData: demographics } =
            await getAllJobSeekerProfiles(applicantIds)
          setDemographicsData(demographics)
        } else {
          setDemographicsData({
            gender: {},
            race: {},
            veteran_status: {},
            job_level: {},
            athlete_status: { Athlete: 0, 'Non-Athlete': 0 }
          })
        }
      } catch (error) {
        console.error('Error fetching applicants and demographics:', error)
      } finally {
        setLoadingApplicants(false)
      }
    }

    fetchApplicantsAndDemographics()
  }, [jobId])

  const handleCardClick = async (applicant, event) => {
    // Prevent navigation if clicking on resume link
    if (event.target.closest('a')) {
      return
    }

    try {
      // Update application status to 'reviewing' if it's currently 'submitted'
      if (applicant.status === 'submitted') {
        await updateJobApplication(applicant.id, { status: 'reviewing' })

        // Update local state
        setApplicants((prev) =>
          prev.map((app) =>
            app.id === applicant.id ? { ...app, status: 'reviewing' } : app
          )
        )
      }

      // Navigate to jobseeker profile with application data
      navigate(`/jobseeker-profile-view/${applicant.jobseeker_id}`, {
        state: {
          applicationData: {
            status: applicant.status,
            applicationId: applicant.id,
            jobId: jobId,
            jobTitle: jobData.title, // Add this if you have job title
            applicationDate: applicant.created_at,
            resumeUrl: applicant.resume_url
          }
        }
      })
    } catch (error) {
      console.error('Error updating application status:', error)
    }
  }

  const handleFilterChange = (filterName, value, checked) => {
    // Implement filter logic here
    console.log('Filter changed:', filterName, value, checked)
    // You might want to filter the applicants based on these values
  }
  const handleCancelEdit = () => {
    setEditedData(null)
    setIsEditing(false)
  }
  const handleSaveChanges = async () => {
    try {
      await updateJobPosting(jobId, editedData)
      setJobData(editedData)
      setIsEditing(false)
      setEditedData(null)
    } catch (error) {
      console.error('Error updating job:', error)
      alert('Error saving changes')
    }
  }

  const handleDelete = async () => {
    try {
      await updateJobPosting(jobId, { status: 'deleted' })
      navigate('/job-postings')
    } catch (error) {
      console.error('Error deleting job:', error)
      alert('Error deleting job posting')
    }
  }

  const handleMarkAsHired = async () => {
    try {
      await updateJobPosting(jobId, { status: 'hired' })
      setJobData((prev) => ({ ...prev, status: 'hired' }))
      setShowHiredModal(false)
    } catch (error) {
      console.error('Error marking job as hired:', error)
      alert('Error updating job status')
    }
  }

  const formatSalary = (salary) => {
    if (!salary) return 'Not specified'
    if (salary.type === 'unpaid') return 'Unpaid'
    if (salary.type === 'credit') return 'School Credit'

    const formatNumber = (num) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(num)

    const range = `${formatNumber(salary.range_start)} - ${formatNumber(
      salary.range_end
    )}`
    return salary.type === 'hourly' ? `${range}/hr` : range + '/year'
  }

  const renderApplicantsSection = () => (
    <Section>
      <SectionTitle>
        <Users size={24} />
        Applicants ({applicants.length})
      </SectionTitle>

      {loadingApplicants ? (
        <div>Loading applicants...</div>
      ) : applicants.length === 0 ? (
        <div style={{ color: '#64748b', textAlign: 'center', padding: '2rem' }}>
          No applicants yet
        </div>
      ) : (
        <ApplicantsList>
          {applicants.map((applicant) => {
            const userDetails = applicantDetails[applicant.jobseeker_id] || {}
            const profileDetails =
              applicantProfiles[applicant.jobseeker_id] || {}
            const firstName = userDetails?.first_name || ''
            const lastName = userDetails?.last_name || ''
            const currentTitle =
              profileDetails?.current_title ||
              profileDetails?.work_history?.[0]?.title
            const currentCompany =
              profileDetails?.current_employer ||
              profileDetails?.work_history?.[0]?.employer
            const pronouns = profileDetails?.pronouns
            const profilePicture = profileDetails?.profile_picture
            const initials =
              firstName && lastName
                ? `${firstName[0]}${lastName[0]}`
                : applicant.jobseeker_id[0]

            return (
              <ApplicantCard
                key={applicant.id}
                onClick={(e) => handleCardClick(applicant, e)}
              >
                <MatchBubble>
                  <Lock size={14} />% Match
                  <Tooltip className="tooltip">
                    AI Matches will be available October 31st
                  </Tooltip>
                </MatchBubble>
                {/* Existing card content */}
                <ApplicantInfo>
                  <ApplicantAvatar>
                    {profilePicture ? (
                      <img
                        src={profilePicture}
                        alt={`${firstName} ${lastName}`}
                      />
                    ) : (
                      initials.toUpperCase()
                    )}
                  </ApplicantAvatar>
                  <InfoSection>
                    <ApplicantNameSection>
                      <ApplicantName>
                        {firstName && lastName
                          ? `${firstName} ${lastName}`
                          : applicant.jobseeker_id}
                      </ApplicantName>
                      {pronouns && <Pronouns>{pronouns}</Pronouns>}
                    </ApplicantNameSection>

                    {applicant.resume_url && (
                      <ResumeLink
                        href={applicant.resume_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Resume
                      </ResumeLink>
                    )}

                    {(currentTitle || currentCompany) && (
                      <JobInfo>
                        {[currentTitle, currentCompany]
                          .filter(Boolean)
                          .join(' | ')}
                      </JobInfo>
                    )}

                    <ApplicantMetadata>
                      <span>
                        Applied{' '}
                        {new Date(
                          applicant.created_at?.seconds * 1000
                        ).toLocaleDateString()}
                      </span>
                    </ApplicantMetadata>
                  </InfoSection>
                </ApplicantInfo>
                <ApplicantStatus status={applicant.status}>
                  {applicant.status.charAt(0).toUpperCase() +
                    applicant.status.slice(1)}
                </ApplicantStatus>
              </ApplicantCard>
            )
          })}
        </ApplicantsList>
      )}
    </Section>
  )
  const renderDemographicChart = (data, title) => {
    return (
      <div>
        <h3>{title}</h3>
        <Pie
          data={{
            labels: Object.keys(data),
            datasets: [
              {
                data: Object.values(data),
                backgroundColor: ['#C471ED', '#CAAAE1', '#B38FD1', '#DC2626'],
                hoverBackgroundColor: [
                  '#805AD5',
                  '#64748b',
                  '#2d3748',
                  '#dc2626'
                ]
              }
            ]
          }}
        />
      </div>
    )
  }

  const renderDemographicsSection = () => (
    <Section>
      <SectionTitle>Applicant Demographics</SectionTitle>
      <div style={{ display: 'flex', gap: '2rem' }}>
        {renderDemographicChart(demographicsData.gender, 'Gender')}
        {renderDemographicChart(demographicsData.race, 'Race')}
        {renderDemographicChart(
          demographicsData.veteran_status,
          'Veteran Status'
        )}
        {renderDemographicChart(demographicsData.job_level, 'Job Level')}
      </div>
    </Section>
  )

  if (loading) return <div>Loading...</div>

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <TopBar>
          <BackButton onClick={() => navigate('/job-postings')}>
            <ArrowLeft size={16} />
            Back to Job Postings
          </BackButton>
          <TopBarActions>
            {!isEditing ? (
              <>
                <ActionButton
                  variant="delete"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <Trash2 size={16} />
                  Delete Job
                </ActionButton>
                {jobData.status !== 'hired' && (
                  <ActionButton
                    variant="hired"
                    onClick={() => setShowHiredModal(true)}
                  >
                    <CheckCircle size={16} />
                    Mark as Hired
                  </ActionButton>
                )}
                <ActionButton onClick={handleStartEdit}>
                  <Edit size={16} />
                  Edit Job
                </ActionButton>
              </>
            ) : (
              <>
                <ActionButton onClick={handleCancelEdit}>
                  <X size={16} />
                  Cancel
                </ActionButton>
                <ActionButton onClick={handleSaveChanges}>
                  <Save size={16} />
                  Save Changes
                </ActionButton>
              </>
            )}
          </TopBarActions>
        </TopBar>
        <ContentWrapper>
          <MainContentArea>
            <Section>
              {isEditing ? (
                <EditableInput
                  large
                  value={editedData.title}
                  onChange={(e) =>
                    setEditedData((prev) => ({
                      ...prev,
                      title: e.target.value
                    }))
                  }
                  placeholder="Job Title"
                />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <JobTitle>{jobData.title}</JobTitle>
                  {jobData.status === 'hired' && (
                    <StatusBadge status="hired">
                      <CheckCircle size={16} />
                      Position Filled
                    </StatusBadge>
                  )}
                </div>
              )}

              <QuickInfo>
                <QuickInfoItem>
                  <Building size={18} />
                  {isEditing ? (
                    <EditableInput
                      value={editedData.department}
                      onChange={(e) =>
                        setEditedData((prev) => ({
                          ...prev,
                          department: e.target.value
                        }))
                      }
                      placeholder="Department"
                    />
                  ) : (
                    jobData.department
                  )}
                </QuickInfoItem>
                <QuickInfoItem>
                  <MapPin size={18} />
                  {isEditing ? (
                    <EditableInput
                      value={editedData.location}
                      onChange={(e) =>
                        setEditedData((prev) => ({
                          ...prev,
                          location: e.target.value
                        }))
                      }
                      placeholder="Location"
                    />
                  ) : (
                    jobData.location
                  )}
                </QuickInfoItem>
                <QuickInfoItem>
                  <Globe size={18} />
                  {isEditing ? (
                    <EditableSelect
                      value={editedData.work_policy}
                      onChange={(e) =>
                        setEditedData((prev) => ({
                          ...prev,
                          work_policy: e.target.value
                        }))
                      }
                    >
                      <option value="">Select Work Policy</option>
                      <option value="Remote">Remote</option>
                      <option value="In-Office">In-Office</option>
                      <option value="Hybrid">Hybrid</option>
                    </EditableSelect>
                  ) : (
                    jobData.work_policy
                  )}
                </QuickInfoItem>

                <QuickInfoItem>
                  <BanknoteIcon size={20} />
                  {!isEditing && <div>{formatSalary(jobData.salary)}</div>}
                </QuickInfoItem>
              </QuickInfo>

              <ContentSection>
                <SectionTitle>
                  <AlignLeft size={24} />
                  Job Summary
                </SectionTitle>
                {isEditing ? (
                  <EditableTextarea
                    value={editedData.description}
                    onChange={(e) =>
                      setEditedData((prev) => ({
                        ...prev,
                        description: e.target.value
                      }))
                    }
                    placeholder="Job Description"
                  />
                ) : (
                  <TextContent>{jobData.description}</TextContent>
                )}
              </ContentSection>

              <ContentSection>
                <SectionTitle>
                  <ClipboardCheck size={24} />
                  Responsibilities
                </SectionTitle>
                {isEditing ? (
                  <EditableTextarea
                    value={editedData.responsibilities}
                    onChange={(e) =>
                      setEditedData((prev) => ({
                        ...prev,
                        responsibilities: e.target.value
                      }))
                    }
                    placeholder="Responsibilities"
                  />
                ) : (
                  <TextContent>{jobData.responsibilities}</TextContent>
                )}
              </ContentSection>

              <ContentSection>
                <SectionTitle>
                  <ClipboardCheck size={24} />
                  Required Skills
                </SectionTitle>
                <SkillsContainer>
                  {(isEditing ? editedData : jobData).recommended_skills.map(
                    (skill, index) => (
                      <SkillTag key={index}>
                        <Hash size={16} />
                        {skill}
                      </SkillTag>
                    )
                  )}
                </SkillsContainer>
              </ContentSection>

              <ContentSection>
                <SectionTitle>
                  <ClipboardCheck size={24} />
                  Preferred Qualifications
                </SectionTitle>
                {isEditing ? (
                  <EditableTextarea
                    value={editedData.nice_to_haves}
                    onChange={(e) =>
                      setEditedData((prev) => ({
                        ...prev,
                        nice_to_haves: e.target.value
                      }))
                    }
                    placeholder="Preferred Qualifications"
                  />
                ) : (
                  <TextContent>{jobData.nice_to_haves}</TextContent>
                )}
              </ContentSection>

              {jobData.benefits && jobData.benefits.length > 0 && (
                <ContentSection>
                  <SectionTitle>
                    <Gift size={24} />
                    Benefits
                  </SectionTitle>
                  <BenefitsGrid>
                    {jobData.benefits.map((benefit, index) => {
                      const categoryInfo = benefitCategories.find(
                        (cat) => cat.value === benefit.category
                      )
                      const Icon = categoryInfo?.icon || Plus
                      return (
                        <BenefitCard key={index}>
                          <BenefitIcon>
                            <Icon size={24} />
                          </BenefitIcon>
                          <h3 style={{ marginBottom: '0.5rem' }}>
                            {categoryInfo?.label || 'Other'}
                          </h3>
                          <p style={{ fontSize: '14px', color: '#666' }}>
                            {benefit.description}
                          </p>
                        </BenefitCard>
                      )
                    })}
                  </BenefitsGrid>
                </ContentSection>
              )}
            </Section>
            {showDeleteModal && (
              <>
                <Modal>
                  <ModalTitle>
                    <AlertCircle size={24} style={{ color: '#dc2626' }} />
                    Confirm Job Post Deletion
                  </ModalTitle>
                  <ModalText>
                    Are you sure you want to delete this job post? This will:
                    <WarningList variant="delete">
                      <li>Remove the listing immediately</li>
                      <li>Archive all received applications</li>
                      <li>
                        Delete the post from your dashboard. This action cannot
                        be undone.
                      </li>
                    </WarningList>
                  </ModalText>
                  <ModalActions>
                    <ModalButton
                      variant="cancel"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No, Cancel
                    </ModalButton>
                    <ModalButton variant="delete" onClick={handleDelete}>
                      <Trash2 size={16} />
                      Yes, Delete Job Post
                    </ModalButton>
                  </ModalActions>
                </Modal>
                <ModalOverlay onClick={() => setShowDeleteModal(false)} />
              </>
            )}
            {showHiredModal && (
              <>
                <Modal>
                  <ModalTitle>
                    <CheckCircle size={24} style={{ color: '#16a34a' }} />
                    Confirm Marking Job as Filled
                  </ModalTitle>
                  <ModalText>
                    Are you sure you want to mark this position as filled? This
                    will:
                    <WarningList variant="hired">
                      <li>Remove the job post from public view</li>
                      <li>Archive the listing</li>
                      <li>Stop accepting new applications</li>
                    </WarningList>
                  </ModalText>
                  <ModalActions>
                    <ModalButton
                      variant="cancel"
                      onClick={() => setShowHiredModal(false)}
                    >
                      No, Cancel
                    </ModalButton>
                    <ModalButton variant="hired" onClick={handleMarkAsHired}>
                      <CheckCircle size={16} />
                      Yes, Mark as Filled
                    </ModalButton>
                  </ModalActions>
                </Modal>
                <ModalOverlay onClick={() => setShowHiredModal(false)} />
              </>
            )}
            <div>{renderDemographicsSection()}</div>

            <div>{renderApplicantsSection()}</div>
          </MainContentArea>
        </ContentWrapper>
      </MainContent>
    </Container>
  )
}

export default JobDetails
