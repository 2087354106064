import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { updateJobApplication } from '../models/JobApplication'

import {
  Mail,
  MapPin,
  Linkedin,
  Github,
  FileText,
  GraduationCap,
  Briefcase,
  TrophyIcon,
  Upload,
  ArrowLeft,
  LineChart,
  Users,
  Globe,
  Clock
} from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { getJobseekerProfile } from '../models/JobseekerProfile'
import { getUserById } from '../models/User'
import EmployerNav from './EmployerNav'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f3f4f6;
  overflow-y: auto;
  padding: 1rem;
`

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 30px auto;
  padding: 2rem;
  position: relative;
`

const ProfileContent = styled.div`
  display: flex;
  margin-top: -5rem;
  position: relative;
  z-index: 20;
`

const MainContent = styled.div`
  width: 65%;
  padding-right: 2rem;
`

const Sidebar = styled.div`
  width: 35%;
  margin-right: 30px;
`

const ProfileCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
`

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const ProfilePicture = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
  border: 4px solid white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`

const CoverPhoto = styled.div`
  height: 250px;
  background-image: url(${(props) => props.src || '/images/default-cover.jpg'});
  background-size: cover;
  background-position: center;
  border-radius: 8px 8px 0 0;
  position: relative;
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #4b5563;
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    color: #333;
  }

  svg {
    margin-right: 0.5rem;
  }
`

const ProfileInfo = styled.div``

const ProfileName = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const ProfileTitle = styled.p`
  font-size: 1.1rem;
  color: #4b5563;
  margin-top: -3px;
`

const SectionTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 0;
  color: #333;
`

const WorkHistoryItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const WorkDetails = styled.div`
  flex-grow: 1;
  font-size: 1rem;
`

const DetailParagraph = styled.p`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
`

const LinksCard = styled(ProfileCard)`
  padding: 1rem;
`

const PortfolioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

const PortfolioItem = styled.div`
  flex: 0 0 48%;
  text-align: center;
`

const PortfolioImage = styled.div`
  width: 100%;
  height: 150px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 8px;
`

const PortfolioTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #caaae1;
`

const ResumePageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  margin-top: -12rem;
  background-color: #f3f4f6;
  padding: 1rem;
  margin-left: -12px;
  position: relative;
`

const ResumeViewer = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`

const ResumeFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`

const ToggleLabel = styled.label`
  margin-right: 0.5rem;
  font-size: 1rem;
  color: #4b5563;
`

const ToggleSwitch = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.checked ? '#10B981' : '#E5E7EB')};
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`

const ToggleSlider = styled.div`
  position: absolute;
  top: 2px;
  left: ${(props) => (props.checked ? '22px' : '2px')};
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.3s ease;
`

const StrengthBubble = styled.span`
  display: inline-block;
  background-color: #caaae1;
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 0.9rem;
  margin-right: 5px;
  margin-bottom: 5px;
`

const TopActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  z-index: 30;
`

const ApplicationStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`

const ApplicationInfo = styled.div`
  font-size: 0.875rem;
  color: #64748b;
`

const StatusDropdown = styled.select`
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  margin-left: 1rem;
  background-color: white;
  cursor: pointer;
  font-size: 0.875rem;
  color: ${(props) => {
    switch (props.value) {
      case 'shortlisted':
        return '#805ad5'
      case 'interview':
        return '#d97706'
      case 'offer':
        return '#16a34a'
      case 'rejected':
        return '#dc2626'
      default:
        return '#64748b'
    }
  }};

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  background: ${(props) => (props.checked ? '#f8f4fb' : 'white')};

  &:hover {
    background: #f8f4fb;
  }
`

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;

  ${(props) =>
    props.variant === 'secondary'
      ? `
    background: #f1f5f9;
    color: #64748b;
    border: none;

    &:hover {
      background: #e2e8f0;
    }
  `
      : `
    background: #dc2626;
    color: white;
    border: none;

    &:hover {
      background: #b91c1c;
    }
  `}
`
const ApplicationStatusBadge = styled.select`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
  padding-right: 2.5rem;

  ${({ status }) => {
    switch (status) {
      case 'reviewed':
        return `
          background-color: #EAE2F8;
          color: #805AD5;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23805AD5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      case 'shortlisted':
        return `
          background-color: #FFEDD5;
          color: #DD6B20;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23DD6B20' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      case 'interview':
        return `
          background-color: #FEFCBF;
          color: #D69E2E;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23D69E2E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      case 'offer':
        return `
          background-color: #F0FFF4;
          color: #38A169;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2338A169' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      case 'rejected':
        return `
          background-color: #FFF5F5;
          color: #E53E3E;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23E53E3E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `

      default:
        return `
          background-color: #EDF2F7;
          color: #4A5568;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
    }
  }}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  option {
    background: white;
    color: #4a5568;
    padding: 8px;
  }
`

const JobSeekerProfileView = () => {
  const { jobseekerId } = useParams()
  const navigate = useNavigate()
  const [profile, setProfile] = useState(null)
  const [user, setUser] = useState(null)
  const [showResume, setShowResume] = useState(false)
  const [resumeUrl, setResumeUrl] = useState('')
  const location = useLocation()
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)

  const [rejectionReason, setRejectionReason] = useState('')

  const applicationData = location.state?.applicationData

  useEffect(() => {
    const fetchData = async () => {
      if (jobseekerId) {
        const profileData = await getJobseekerProfile(jobseekerId)
        setProfile(profileData)
        setResumeUrl(profileData?.resume_url || '')

        const userData = await getUserById(jobseekerId)
        setUser(userData)
      }
    }
    fetchData()
  }, [jobseekerId])

  const formatDate = (dateString) => {
    if (!dateString) return 'Present'
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
  }

  const handleToggle = () => {
    setShowResume(!showResume)
  }

  const handleBackClick = () => {
    navigate(-1)
  }

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value
    if (!applicationData) return

    if (newStatus === 'rejected') {
      setShowRejectionModal(true)
    } else {
      try {
        await updateJobApplication(applicationData.applicationId, {
          status: newStatus
        })

        // Update local state immediately for UI feedback
        if (location.state) {
          location.state.applicationData = {
            ...location.state.applicationData,
            status: newStatus
          }
        }
        // Force a re-render
        setForceUpdate((prev) => !prev) // Add this state if you haven't already
      } catch (error) {
        console.error('Error updating application status:', error)
        alert('Failed to update application status')
      }
    }
  }

  const handleRejection = async () => {
    if (!rejectionReason) {
      alert('Please select a reason for rejection')
      return
    }

    try {
      await updateJobApplication(applicationData.applicationId, {
        status: 'rejected',
        rejection_reasoning: rejectionReason
      })
      // Update local state
      location.state.applicationData.status = 'rejected'
      setShowRejectionModal(false)
    } catch (error) {
      console.error('Error rejecting application:', error)
      alert('Failed to reject application')
    }
  }

  return (
    <PageWrapper>
      <NavbarWrapper>
        <EmployerNav />
      </NavbarWrapper>
      <PageContainer>
        <ContentContainer>
          <TopActionsContainer>
            <BackButton onClick={handleBackClick}>
              <ArrowLeft size={16} /> Back
            </BackButton>

            {applicationData && (
              <ApplicationStatusContainer>
                <ApplicationStatusBadge
                  value={applicationData.status}
                  onChange={handleStatusChange}
                  status={applicationData.status}
                >
                  <option value="reviewed">Reviewed</option>
                  <option value="shortlisted">Shortlisted</option>
                  <option value="interview">Interview</option>
                  <option value="offer">Offer</option>
                  <option value="rejected">Rejected</option>
                </ApplicationStatusBadge>
                <ApplicationInfo>
                  Applied{' '}
                  {new Date(
                    applicationData.applicationDate?.seconds * 1000
                  ).toLocaleDateString()}{' '}
                  • {applicationData.jobTitle}
                </ApplicationInfo>
              </ApplicationStatusContainer>
            )}
          </TopActionsContainer>
          {showRejectionModal && (
            <>
              <Modal>
                <h2>Select Rejection Reason</h2>
                <RadioGroup>
                  {[
                    'Position filled internally',
                    'Not enough experience',
                    'Too much experience',
                    'Salary expectations too high',
                    'Requires visa sponsorship',
                    'Based outside of country',
                    'Not willing to relocate',
                    'Position closed/not hired'
                  ].map((reason) => (
                    <RadioLabel
                      key={reason}
                      checked={rejectionReason === reason}
                    >
                      <input
                        type="radio"
                        name="rejectionReason"
                        value={reason}
                        checked={rejectionReason === reason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                      />
                      {reason}
                    </RadioLabel>
                  ))}
                </RadioGroup>
                <ModalButtons>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowRejectionModal(false)
                      setRejectionReason('')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleRejection}>Confirm Rejection</Button>
                </ModalButtons>
              </Modal>
              <Overlay onClick={() => setShowRejectionModal(false)} />
            </>
          )}
          <ToggleContainer>
            <ToggleLabel>
              {showResume ? 'Back to Profile' : 'View  Resume'}
            </ToggleLabel>
            <ToggleSwitch checked={showResume} onClick={handleToggle}>
              <ToggleSlider checked={showResume} />
            </ToggleSwitch>
          </ToggleContainer>

          <CoverPhoto src="/images/cover-photo.png.jpg" />

          {!showResume ? (
            <ProfileContent>
              <MainContent>
                <ProfileCard>
                  <ProfileHeader>
                    <ProfilePicture
                      src={profile?.profile_picture || 'default-profile.png'}
                    />
                    <ProfileInfo>
                      <ProfileName>
                        {user?.first_name} {user?.last_name}
                      </ProfileName>
                      <ProfileTitle>
                        {profile?.work_history?.[0]?.title || 'Student'}
                      </ProfileTitle>
                    </ProfileInfo>
                  </ProfileHeader>
                </ProfileCard>

                <ProfileCard>
                  <SectionTitle>Work History</SectionTitle>
                  {profile?.work_history?.map((job, index) => (
                    <WorkHistoryItem key={index}>
                      <IconContainer>
                        <Briefcase size={20} />
                      </IconContainer>
                      <WorkDetails>
                        <h4>
                          {job.title || 'Title not provided'} at{' '}
                          {job.employer || 'Employer not provided'}
                        </h4>
                        <p>
                          {formatDate(job.start_date)} -{' '}
                          {formatDate(job.end_date)}
                        </p>
                      </WorkDetails>
                    </WorkHistoryItem>
                  ))}
                </ProfileCard>

                <ProfileCard>
                  <SectionTitle>Education</SectionTitle>
                  {profile?.education?.map((edu, index) => (
                    <WorkHistoryItem key={index}>
                      <IconContainer>
                        <GraduationCap size={20} />
                      </IconContainer>
                      <div>
                        {edu.degree_type} in {edu.field_of_study},{' '}
                        {edu.institution}
                      </div>
                    </WorkHistoryItem>
                  ))}
                </ProfileCard>

                {profile?.top_strengths?.length > 0 && (
                  <ProfileCard>
                    <SectionTitle>Top Strengths</SectionTitle>
                    <div>
                      {profile.top_strengths.map((strength, index) => (
                        <StrengthBubble key={index}>{strength}</StrengthBubble>
                      ))}
                    </div>
                  </ProfileCard>
                )}

                {profile?.athletic_history?.length > 0 && (
                  <ProfileCard>
                    <SectionTitle>Athletic Experience</SectionTitle>
                    {profile.athletic_history.map((history, index) => (
                      <WorkHistoryItem key={index}>
                        <IconContainer>
                          <TrophyIcon size={20} />
                        </IconContainer>
                        <WorkDetails>
                          <h4>
                            {history.sport || 'Sport not provided'} |{' '}
                            {history.league || 'League not provided'}
                          </h4>
                          <p>
                            {formatDate(history.start_date)} -{' '}
                            {formatDate(history.end_date)}
                          </p>
                          <p>
                            {history.achievements || 'Add Achievements Here'}
                          </p>
                        </WorkDetails>
                      </WorkHistoryItem>
                    ))}
                  </ProfileCard>
                )}

                {profile?.portfolio_profile_links?.length > 0 && (
                  <ProfileCard>
                    <SectionTitle>Portfolio</SectionTitle>
                    <PortfolioContainer>
                      {profile.portfolio_profile_links.map((item, index) => (
                        <PortfolioItem key={index}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <PortfolioImage src={item.imageUrl} />
                            <PortfolioTitle>{item.title}</PortfolioTitle>
                          </a>
                        </PortfolioItem>
                      ))}
                    </PortfolioContainer>
                  </ProfileCard>
                )}
              </MainContent>

              <Sidebar>
                <ProfileCard>
                  <SectionTitle>Contact</SectionTitle>
                  <DetailParagraph>
                    <IconContainer>
                      <Mail size={18} />
                    </IconContainer>
                    {user?.email || 'No email provided'}
                  </DetailParagraph>
                  <DetailParagraph>
                    <IconContainer>
                      <MapPin size={18} />
                    </IconContainer>
                    {profile?.city && profile?.state
                      ? `${profile.city}, ${profile.state}`
                      : 'Location not provided'}
                  </DetailParagraph>
                </ProfileCard>

                <ProfileCard>
                  <SectionTitle>Additional Info</SectionTitle>
                  <DetailParagraph>
                    <IconContainer>
                      <Globe size={18} />
                    </IconContainer>
                    Languages:{' '}
                    {profile?.languages?.join(', ') || 'Not specified'}
                  </DetailParagraph>
                  <DetailParagraph>
                    <IconContainer>
                      <Clock size={18} />
                    </IconContainer>
                    Years of Experience:{' '}
                    {profile?.years_experience || 'Not specified'}
                  </DetailParagraph>
                  <DetailParagraph>
                    <IconContainer>
                      <LineChart size={18} />
                    </IconContainer>
                    Startup Experience:{' '}
                    {profile?.startup_experience ? 'Yes' : 'No'}
                  </DetailParagraph>
                  <DetailParagraph>
                    <IconContainer>
                      <Users size={18} />
                    </IconContainer>
                    Job Level: {profile?.job_level || 'Not specified'}
                  </DetailParagraph>
                </ProfileCard>

                <LinksCard>
                  <SectionTitle>Links</SectionTitle>
                  {profile?.linkedin && (
                    <DetailParagraph>
                      <IconContainer>
                        <Linkedin size={18} />
                      </IconContainer>
                      <a
                        href={profile.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </DetailParagraph>
                  )}
                  {profile?.github && (
                    <DetailParagraph>
                      <IconContainer>
                        <Github size={18} />
                      </IconContainer>
                      <a
                        href={profile.github}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        GitHub
                      </a>
                    </DetailParagraph>
                  )}
                  {profile?.portfolio && (
                    <DetailParagraph>
                      <IconContainer>
                        <FileText size={18} />
                      </IconContainer>
                      <a
                        href={profile.portfolio}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Portfolio
                      </a>
                    </DetailParagraph>
                  )}
                </LinksCard>
              </Sidebar>
            </ProfileContent>
          ) : (
            <ResumePageContainer>
              <ResumeViewer>
                {resumeUrl ? (
                  <ResumeFrame
                    src={`${resumeUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                    title="Resume Viewer"
                  />
                ) : (
                  <p>No resume uploaded yet.</p>
                )}
              </ResumeViewer>
              <Upload size={48} />
            </ResumePageContainer>
          )}
        </ContentContainer>
      </PageContainer>
    </PageWrapper>
  )
}

export default JobSeekerProfileView
