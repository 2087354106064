import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  Mail,
  MapPin,
  Linkedin,
  FileText,
  Edit,
  Landmark,
  Save,
  LineChart,
  Briefcase,
  Inbox,
  Search,
  Building,
  FileCheck,
  MessageSquare,
  Settings,
  LogOut,
  Image,
  Video,
  Github,
  Flag,
  Globe,
  Clock,
  MapPinIcon,
  Upload,
  X,
  VideotapeIcon,
  Instagram,
  Users,
  TrophyIcon,
  ChevronLeft,
  GraduationCap,
  ChevronRight
} from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../models/JobseekerProfile'
import { getUserByFirebaseUid } from '../models/User'
import { auth, db } from '../firebase'
import { storage } from '../firebase' // Adjust the path if needed

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const leagues = [
  'NCAA Division I (National Collegiate Athletics Association)',
  'NCAA Division II (National Collegiate Athletics Association)',
  'NCAA Division III (National Collegiate Athletics Association)',
  'NAIA (National Association of Intercollegiate Athletics)',
  'NJCAA (National Junior College Athletic Association)',
  'AHL (American Hockey League)',
  'APP (Association of Pickleball Professionals)',
  'ATP (Association of Tennis Professionals)',
  'AUDL (American Ultimate Disc League)',
  'ECHL (East Coast Hockey League)',
  'IFL (Indoor Football League)',
  'LOVB (League One Volleyball)',
  'LPGA (Ladies Professional Golf Association)',
  'MLB (Major League Baseball)',
  'MLL (Major League Lacrosse)',
  'MLP (Major League Pickleball)',
  'MLR (Major League Rugby)',
  'MLS (Major League Soccer)',
  'NBA (National Basketball Association)',
  'G League (NBA G League)',
  'NFL (National Football League)',
  'NHL (National Hockey League)',
  'NISA (National Independent Soccer Association)',
  'NLL (National Lacrosse League)',
  'NPF (National Pro Fastpitch)',
  'NPL (National Pickleball League)',
  "NWSL (National Women's Soccer League)",
  'PBA (Professional Bowlers Association)',
  'PBR (Professional Bull Riders)',
  'PGA (Professional Golf Association)',
  'PHF (Premier Hockey Federation)',
  'PLL (Premier Lacrosse League)',
  'PPA (Professional Pickleball Association)',
  'PVL (Pro Volleyball League)',
  "PWBA (Professional Women's Bowling Association)",
  'USL (United Soccer League)',
  "WFA (Women's Football Alliance)",
  "WNBA (Women's National Basketball Association)",
  "WPLL (Women's Professional Lacrosse League)",
  "WTA (Women's Tennis Association)",
  'XFL (Xtreme Football League)',
  'Olympic Team Member',
  'Olympic Trials Qualifier',
  'Olympic National Team Member',
  'Paralympic Team Member',
  'Paralympic National Team Member',
  'Paralympic Trials Qualifier',
  'Olympic Junior National Team',
  'Youth Olympic Games',
  'Other'
]

const experienceLevels = [
  'None/Student',
  '1-2 years',
  '3-5 years',
  '6-10 years',
  '11-15 years',
  '16+ years'
]
const currentLevels = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]
const languageOptions = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'French',
  'German',
  'Japanese',
  'Russian',
  'Portuguese',
  'Arabic',
  'Hindi',
  'Italian',
  'Korean',
  'Dutch',
  'Turkish',
  'Swedish',
  'Polish',
  'Greek',
  'Vietnamese',
  'Hebrew',
  'Thai',
  'Indonesian',
  'Danish',
  'Finnish',
  'Norwegian',
  'Czech'
].sort()
const workPreferences = [
  'Fully In-Office',
  'Fully Remote',
  'Hybrid (1 day a week from home)',
  'Hybrid (2 days a week from home)',
  'Hybrid (3 days a week from home)',
  'Hybrid (4 days a week from home)'
]

const strengthsOptions = [
  'Communication',
  'Leadership',
  'Problem Solving',
  'Teamwork',
  'Adaptability',
  'Creativity',
  'Time Management',
  'Critical Thinking',
  'Attention to Detail',
  'Emotional Intelligence',
  'Strategic Planning',
  'Networking',
  'Decision Making',
  'Conflict Resolution',
  'Analytical Skills',
  'Project Management',
  'Negotiation',
  'Public Speaking',
  'Customer Service',
  'Technical Proficiency',
  'Data Analysis',
  'Research',
  'Writing',
  'Multitasking',
  'Sales',
  'Marketing',
  'Budgeting',
  'Coaching',
  'Innovation',
  'Interpersonal Skills'
]

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;

  @media (max-width: 768px) {
  }
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;

  @media (max-width: 768px) {
    height: 100vh;
  }
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f3f4f6;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 30px auto;
  padding: 2rem;
  position: relative;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const ProfileContent = styled.div`
  display: flex;
  margin-top: -5rem;
  position: relative;
  z-index: 20;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
  }
`

const MainContent = styled.div`
  width: 65%;
  padding-right: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`

const CoverPhoto = styled.div`
  height: 250px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 8px 8px 0 0;
  position: relative;

  @media (max-width: 768px) {
    height: 60px; // Reduced height for mobile devices
  }
`

const EditButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`

const ProfileCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
`

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const ProfilePicture = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
  border: 4px solid white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  position: relative;

  @media (max-width: 768px) {
    width: 70px;
    height: 50px;
  }
`

const ProfileInfo = styled.div``

const ProfileName = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const ProfileTitle = styled.p`
  font-size: 1.1rem;
  color: #4b5563;
  margin-top: -3px;
`

const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0.35rem 0.8rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${(props) =>
    props.variant === 'success' ? '#10B981' : '#E5E7EB'};
  color: ${(props) => (props.variant === 'success' ? 'white' : '#4B5563')};
  margin-bottom: 1rem;
`

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  font-size: 1rem;
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  min-height: 100px;
  font-size: 1rem;
`

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  color: #4b5563;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #1f2937;
  }

  svg {
    margin-right: 0.5rem;
  }
`

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
`

const DetailParagraph = styled.p`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const FlagText = styled.span`
  margin-left: 0.5rem;
`

const SkillTag = styled(Badge)`
  background-color: #caaae1;
  color: white;
`

const FileInput = styled.input`
  display: none;
`

const ModernInput = styled.input`
  width: 95%;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-family: 'Open sans', sans-serif;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  outline: none;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.2);
  }
`

const ModernTextArea = styled.textarea`
  width: 95%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 14px;
  font-family: 'Open sans', sans-serif;
  transition: border-color 0.3s ease;
  outline: none;
  min-height: 100px;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.2);
  }
`
const Sidebar = styled.div`
  width: 35%;
  margin-right: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`

const ModernSelect = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  outline: none;
  margin-top: 10px;
  margin-bottom: 15px;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.2);
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CheckboxInput = styled.input`
  margin-right: 0.5rem;
`

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ef4444;
  padding: 0.25rem;
  margin-left: 0.5rem;

  &:hover {
    color: #dc2626;
  }
`

const AddButton = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    background-color: #b799d4;
  }
`

const AthleteStatusEntry = styled.div`
  border-bottom: 1px solid #d1d5db;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const IconWrapper = styled.div`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
`

const SectionTitle = styled.h2`
  font-size: 1.75rem; /* Increased font size for larger section title */
  font-weight: 700;
  margin-bottom: 0;
  color: #333;
`

const WorkHistoryItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  position: relative;
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const WorkDetails = styled.div`
  flex-grow: 1;
  font-size: 1rem;
`

const TitleEmployer = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const DateRange = styled.p`
  font-size: 0.95rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
`

const JobDetails = styled.p`
  font-size: 1rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
`

const EditIconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #caaae1;
  display: flex;
  align-items: center;

  &:hover {
    color: #b799d4;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-gap: 1.5rem; /* Space between items */
  margin-bottom: 2rem;
`

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
`

const GridItemFullWidth = styled.div`
  grid-column: span 2; /* Spans across both columns */
  display: flex;
  flex-direction: column;
`

const EditLabel = styled.label`
  font-size: 0.9rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
`

const EditInput = styled.input`
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.3);
  }
`

const EditTextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 1rem;
  min-height: 120px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.3);
  }
`

const ButtonRow = styled.div`
  grid-column: span 2;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const SaveButton = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #b799d4;
  }
`

const CancelButton = styled.button`
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #dc2626;
  }
`
const StrengthBubble = styled.div`
  background-color: #caaae1; // Your specified bubble color
  border-radius: 15px;
  padding: 8px 12px;
  margin: 5px;
  color: white;
  display: inline-flex;
  align-items: center;
`

const PortfolioContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 30px;
`

const PortfolioScroller = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows the items to wrap to the next line if they exceed container width */
  justify-content: space-between; /* Optional: evenly spaces the items */
  gap: 20px; /* Optional: space between items */
  overflow: visible; /* Remove the scroll behavior */
  width: 100%; /* Ensure it uses full width */
`

const PortfolioItem = styled.div`
  flex: 0 0 auto;
  width: 200px;
  margin-right: 20px;
  text-align: center;
`

const PortfolioImage = styled.div`
  width: 100%;
  height: 150px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
`

const PortfolioTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #caaae1;
  text-decoration: underline;
`

const ScrollButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(202, 170, 225, 0.8);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background-color: rgba(202, 170, 225, 1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const LeftScrollButton = styled(ScrollButton)`
  left: 0;
`

const RightScrollButton = styled(ScrollButton)`
  right: 0;
`

const FileInputLabel = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #caaae1;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 0.5rem;

  &:hover {
    background-color: #b799d4;
  }

  svg {
    margin-right: 0.5rem;
  }
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: none; // Hide the toggle container on mobile devices
  }
`

const ToggleLabel = styled.label`
  margin-right: 0.5rem;
  font-size: 1rem;
  color: #4b5563;
`

const ToggleSwitch = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.checked ? '#10B981' : '#E5E7EB')};
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`

const ToggleSlider = styled.div`
  position: absolute;
  top: 2px;
  left: ${(props) => (props.checked ? '22px' : '2px')};
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.3s ease;
`

const ResumePageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f3f4f6;
  padding: 1rem;
  position: relative;
`

const ResumeViewer = styled.div`
  width: 100%;
  height: calc(100vh - 80px); // Account for padding and button height
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`

const ResumeFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

const UploadButton = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;

  &:hover {
    background-color: #b799d4;
  }
`

const NoResumeMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  color: #6b7280;
  font-size: 1.1rem;
`

const UploadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
`
const ResumeContainer = styled.div`
  min-height: calc(100vh - 120px); // Account for navbar and toggle
  width: 100%;
  background-color: #f3f4f6;
  position: relative;
`

export default function JobSeekerProfile() {
  const [editingSections, setEditingSections] = useState({})
  const [profile, setProfile] = useState(null)

  const [user, setUser] = useState(null)

  const [editingAboutMe, setEditingAboutMe] = useState(false)
  const [tempAboutMe, setTempAboutMe] = useState(profile?.about || '')
  const [editingProfilePicture, setEditingProfilePicture] = useState(false)
  const [tempProfilePicture, setTempProfilePicture] = useState(
    profile?.profile_picture || ''
  )
  const [editingEducation, setEditingEducation] = useState(false)
  const [editingPortfolio, setEditingPortfolio] = useState(false)
  const portfolioRef = useRef(null)
  const [portfolioItems, setPortfolioItems] = useState([])
  const [editingAdditionalDetails, setEditingAdditionalDetails] =
    useState(false)

  const hasWorkHistory = profile?.work_history?.length > 0
  const [showResume, setShowResume] = useState(false)
  const [resumeUrl, setResumeUrl] = useState('')
  const [editingResume, setEditingResume] = useState(false)
  const [tempResumeFile, setTempResumeFile] = useState(null)
  const firstJob = profile?.work_history?.[0]
  const [editing, setEditing] = useState(false)
  const [uploading, setUploading] = useState(false)

  const [tempWorkHistory, setTempWorkHistory] = useState([])
  const [tempAthleticHistory, setTempAthleticHistory] = useState([])
  const [selectedStrengths, setSelectedStrengths] = useState([])
  const [editingStrengths, setEditingStrengths] = useState(false)
  const [editingVideo, setEditingVideo] = useState(false)
  const [tempVideoUrl, setTempVideoUrl] = useState(
    profile?.videos?.[0]?.url || ''
  )
  const [tempVideoTitle, setTempVideoTitle] = useState(
    profile?.videos?.[0]?.title || ''
  )
  const [tempVideoFile, setTempVideoFile] = useState(null) // Store video file for upload
  const fileInputRef = useRef(null)

  useEffect(() => {
    const fetchProfileData = async () => {
      const currentUser = auth.currentUser
      if (currentUser) {
        try {
          const userData = await getUserByFirebaseUid(currentUser.uid)
          setUser(userData)

          const jobseekerProfileData = await getJobseekerProfile(
            currentUser.uid
          )
          setProfile(jobseekerProfileData)
          setTempWorkHistory(jobseekerProfileData?.work_history || [])
          setTempAthleticHistory(jobseekerProfileData?.athletic_history || [])

          // Set selected strengths from fetched profile data
          setPortfolioItems(jobseekerProfileData?.portfolio_profile_links || [])

          setSelectedStrengths(jobseekerProfileData?.top_strengths || [])
          setResumeUrl(jobseekerProfileData?.resume_url || '')
        } catch (error) {
          console.error('Error fetching profile data:', error)
        }
      }
    }

    fetchProfileData()
  }, [])

  const formatDate = (dateString) => {
    if (!dateString) return 'Present'
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
  }

  const handleImageUpload = async (e) => {
    const file = e.target.files[0]

    if (file) {
      const fileRef = storage
        .ref()
        .child(`profile-photos/${user.id}/${file.name}`)

      try {
        await fileRef.put(file)
        const newProfilePictureUrl = await fileRef.getDownloadURL()
        setTempProfilePicture(newProfilePictureUrl)
      } catch (error) {
        console.error('Error uploading profile picture:', error)
      }
    }
  }

  const handleEditClick = () => {
    setEditing(true)
  }

  const handleCancel = () => {
    setEditing(false)
    setTempWorkHistory(profile?.work_history || []) // Reset changes
  }

  const handleEditAboutMeClick = () => {
    setEditingAboutMe(true)
    setTempAboutMe(profile?.about || '')
  }
  const handleSaveProfilePicture = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          profile_picture: tempProfilePicture
        })
        setProfile({ ...profile, profile_picture: tempProfilePicture })
        setEditingProfilePicture(false)
      } catch (error) {
        console.error('Error updating profile picture:', error)
      }
    } else {
      console.error('User ID is undefined')
    }
  }

  const handleCancelProfilePicture = () => {
    setEditingProfilePicture(false)
    setTempProfilePicture(profile?.profile_picture || '')
  }

  const handleSaveAboutMe = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, { about: tempAboutMe })
        setProfile({ ...profile, about: tempAboutMe })
        setEditingAboutMe(false)
      } catch (error) {
        console.error('Error updating About Me:', error)
      }
    } else {
      console.error('User ID is undefined')
    }
  }

  const handleCancelAboutMe = () => {
    setEditingAboutMe(false)
    setTempAboutMe(profile?.about || '')
  }

  const handlePresentToggle = (index) => {
    const updatedWorkHistory = [...tempWorkHistory]
    updatedWorkHistory[index].currentJob = !updatedWorkHistory[index].currentJob
    // Set end_date to null if currentJob is true, or keep the existing date
    updatedWorkHistory[index].end_date = updatedWorkHistory[index].currentJob
      ? null
      : updatedWorkHistory[index].end_date
    setTempWorkHistory(updatedWorkHistory)
  }

  const handleSave = async () => {
    if (user && user.id) {
      // Check if user and user ID exist
      try {
        await updateJobseekerProfile(user.id, {
          work_history: tempWorkHistory,
          athletic_history: tempAthleticHistory
        })
        setProfile({
          ...profile,
          work_history: tempWorkHistory,
          athletic_history: tempAthleticHistory
        })
        setEditing(false) // Exit editing mode
      } catch (error) {
        console.error('Error updating profile:', error)
      }
    } else {
      console.error('User ID is undefined') // Log if user ID is missing
    }
  }

  const handleChange = (index, type, field, value) => {
    if (type === 'work') {
      const updatedWorkHistory = [...tempWorkHistory]
      updatedWorkHistory[index][field] = value
      setTempWorkHistory(updatedWorkHistory)
    } else if (type === 'athletic') {
      const updatedAthleticHistory = [...tempAthleticHistory]
      updatedAthleticHistory[index][field] = value
      setTempAthleticHistory(updatedAthleticHistory)
    }
  }

  const addWorkHistory = () => {
    const newWorkHistory = {
      title: '',
      employer: '',
      start_date: '',
      end_date: '',
      details: ''
    }
    setTempWorkHistory([...tempWorkHistory, newWorkHistory])
  }

  const addAthleticHistory = () => {
    const newAthleticHistory = {
      sport: '',
      league: '',
      start_date: '',
      end_date: '',
      achievements: ''
    }
    setTempAthleticHistory([...tempAthleticHistory, newAthleticHistory])
  }

  const removeHistoryEntry = (index, type) => {
    if (type === 'work') {
      const updatedWorkHistory = [...tempWorkHistory]
      updatedWorkHistory.splice(index, 1)
      setTempWorkHistory(updatedWorkHistory)
    } else if (type === 'athletic') {
      const updatedAthleticHistory = [...tempAthleticHistory]
      updatedAthleticHistory.splice(index, 1)
      setTempAthleticHistory(updatedAthleticHistory)
    }
  }
  const renderProfilePictureSection = () => {
    return (
      <ProfilePicture
        src={profile?.profile_picture || 'default-profile.png'}
      ></ProfilePicture>
    )
  }

  const handleAthleticChange = (index, field, value) => {
    const updatedAthleticHistory = [...tempAthleticHistory] // Make a copy of the current athletic history state
    updatedAthleticHistory[index][field] = value // Update the specific field for the athletic history at the given index
    setTempAthleticHistory(updatedAthleticHistory) // Update the state with the new data
  }

  const renderAboutMeSection = () => {
    if (editingAboutMe) {
      return (
        <div>
          <ModernTextArea
            value={tempAboutMe}
            onChange={(e) => setTempAboutMe(e.target.value)}
            placeholder="Tell us about yourself"
          />
          <ButtonRow>
            <SaveButton onClick={handleSaveAboutMe}>
              <Save size={16} /> Save
            </SaveButton>
            <CancelButton onClick={handleCancelAboutMe}>
              <X size={16} /> Cancel
            </CancelButton>
          </ButtonRow>
        </div>
      )
    }

    return (
      <div>
        <p>{profile?.about || 'Add an about me paragraph'}</p>
        <EditIconButton onClick={handleEditAboutMeClick}>
          <Edit size={16} /> Edit
        </EditIconButton>
      </div>
    )
  }

  const renderWorkHistory = () => {
    if (editing) {
      return (
        <>
          <GridContainer>
            {/* Render Work History */}
            {tempWorkHistory.map((job, index) => (
              <div key={index}>
                <GridItem>
                  <EditLabel>Job Title</EditLabel>
                  <EditInput
                    type="text"
                    value={job.title || ''}
                    onChange={(e) =>
                      handleChange(index, 'work', 'title', e.target.value)
                    }
                    placeholder="Title"
                  />
                </GridItem>
                <GridItem>
                  <EditLabel>Employer</EditLabel>
                  <EditInput
                    type="text"
                    value={job.employer || ''}
                    onChange={(e) =>
                      handleChange(index, 'work', 'employer', e.target.value)
                    }
                    placeholder="Employer"
                  />
                </GridItem>
                <GridItem>
                  <EditLabel>Start Date</EditLabel>
                  <EditInput
                    type="date"
                    value={job.start_date || ''}
                    onChange={(e) =>
                      handleChange(index, 'work', 'start_date', e.target.value)
                    }
                  />
                </GridItem>
                <GridItem>
                  <EditLabel>End Date</EditLabel>
                  {!job.currentJob ? (
                    <EditInput
                      type="date"
                      value={job.end_date || ''}
                      onChange={(e) =>
                        handleChange(index, 'work', 'end_date', e.target.value)
                      }
                    />
                  ) : (
                    <p>Present</p>
                  )}
                  <CheckboxLabel>
                    <CheckboxInput
                      type="checkbox"
                      checked={job.currentJob || false}
                      onChange={() => handlePresentToggle(index)}
                    />
                    Present
                  </CheckboxLabel>
                </GridItem>
                <GridItemFullWidth>
                  <EditLabel>Job Details</EditLabel>
                  <EditTextArea
                    value={job.details || ''}
                    onChange={(e) =>
                      handleChange(index, 'work', 'details', e.target.value)
                    }
                    placeholder="Add Job Details Here"
                  />
                </GridItemFullWidth>
                {/* Red "X" button to remove work history */}
                <DeleteButton onClick={() => removeHistoryEntry(index, 'work')}>
                  <X size={20} /> Remove
                </DeleteButton>
              </div>
            ))}
          </GridContainer>

          {/* Add Work History button */}
          <AddButton onClick={addWorkHistory}>Add Work History</AddButton>

          {/* Render Athletic History */}
          {tempAthleticHistory?.length > 0 && (
            <>
              <h3>Athletic Experience</h3> {/* Title for Athletic Experience */}
              <GridContainer>
                {tempAthleticHistory.map((history, index) => (
                  <div key={index}>
                    <GridItem>
                      <EditLabel>Sport</EditLabel>
                      <EditInput
                        type="text"
                        value={history.sport || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'sport',
                            e.target.value
                          )
                        }
                        placeholder="Sport"
                      />
                    </GridItem>
                    <GridItem>
                      <EditLabel>League</EditLabel>
                      <EditInput
                        type="text"
                        value={history.league || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'league',
                            e.target.value
                          )
                        }
                        placeholder="League"
                      />
                    </GridItem>
                    <GridItem>
                      <EditLabel>Start Date</EditLabel>
                      <EditInput
                        type="date"
                        value={history.start_date || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'start_date',
                            e.target.value
                          )
                        }
                      />
                    </GridItem>
                    <GridItem>
                      <EditLabel>End Date</EditLabel>
                      <EditInput
                        type="date"
                        value={history.end_date || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'end_date',
                            e.target.value
                          )
                        }
                      />
                    </GridItem>
                    <GridItemFullWidth>
                      <EditLabel>Achievements</EditLabel>
                      <EditTextArea
                        value={history.achievements || ''}
                        onChange={(e) =>
                          handleChange(
                            index,
                            'athletic',
                            'achievements',
                            e.target.value
                          )
                        }
                        placeholder="Add Achievements Here"
                      />
                    </GridItemFullWidth>
                    {/* Red "X" button to remove athletic history */}
                    <DeleteButton
                      onClick={() => removeHistoryEntry(index, 'athletic')}
                    >
                      <X size={20} /> Remove
                    </DeleteButton>
                  </div>
                ))}
              </GridContainer>
            </>
          )}

          {/* Option to add athletic history */}
          {editing && (
            <AddButton onClick={addAthleticHistory}>
              Add Athletic History
            </AddButton>
          )}

          {/* Save and Cancel buttons */}
          <ButtonRow>
            <SaveButton onClick={handleSave}>
              <Save size={16} /> Save
            </SaveButton>
            <CancelButton onClick={handleCancel}>
              <X size={16} /> Cancel
            </CancelButton>
          </ButtonRow>
        </>
      )
    }

    // Normal view when not editing
    return (
      <>
        {tempWorkHistory?.map((job, index) => (
          <WorkHistoryItem key={index}>
            <IconWrapper>
              <Briefcase size={20} />
            </IconWrapper>
            <WorkDetails>
              <TitleEmployer>
                {job.title || 'Title not provided'} |{' '}
                {job.employer || 'Employer not provided'}
              </TitleEmployer>
              <DateRange>
                {formatDate(job.start_date)} - {formatDate(job.end_date)}
              </DateRange>
              <JobDetails>{job.details || 'Add Job Details Here'}</JobDetails>
            </WorkDetails>
          </WorkHistoryItem>
        ))}

        {/* Render athletic history if it exists */}
        {tempAthleticHistory?.length > 0 && (
          <>
            <h3>Athletic Experience</h3> {/* Title for Athletic Experience */}
            {tempAthleticHistory.map((history, index) => (
              <WorkHistoryItem key={index}>
                <IconWrapper>
                  <TrophyIcon size={20} />
                </IconWrapper>
                <WorkDetails>
                  <TitleEmployer>
                    {history.sport || 'Sport not provided'} |{' '}
                    {history.league || 'League not provided'}
                  </TitleEmployer>
                  <DateRange>
                    {formatDate(history.start_date)} -{' '}
                    {formatDate(history.end_date)}
                  </DateRange>
                  <JobDetails>
                    {history.achievements || 'Add Achievements Here'}
                  </JobDetails>
                </WorkDetails>
              </WorkHistoryItem>
            ))}
          </>
        )}
      </>
    )
  }

  const addEducation = () => {
    const newEducation = {
      degree_type: '',
      field_of_study: '',
      institution: '',
      graduation_date: '' // Add this field
    }
    setProfile((prev) => ({
      ...prev,
      education: [...(prev.education || []), newEducation]
    }))
  }
  const handleEditEducation = () => {
    setEditingEducation((prev) => !prev)
  }
  const removeEducation = (index) => {
    setProfile((prev) => ({
      ...prev,
      education: prev.education.filter((_, i) => i !== index)
    }))
  }
  const handleEducationHistoryChange = (index, field, value) => {
    const newEducationHistory = [...profile.education]
    newEducationHistory[index][field] = value // Update the specific field
    setProfile({ ...profile, education: newEducationHistory }) // Update state
  }

  const handleSaveEducation = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          education: profile.education
        })
        setEditingEducation(false) // Close the editing section after saving
      } catch (error) {
        console.error('Error updating education:', error)
      }
    } else {
      console.error('User ID is undefined')
    }
  }

  const handleEditStrengths = () => {
    setEditingStrengths((prev) => !prev)
  }

  const addStrength = (strength) => {
    if (selectedStrengths.length < 3 && !selectedStrengths.includes(strength)) {
      setSelectedStrengths([...selectedStrengths, strength])
    }
  }

  const removeStrength = (strength) => {
    setSelectedStrengths(selectedStrengths.filter((s) => s !== strength))
  }

  const handleSaveStrengths = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          top_strengths: selectedStrengths
        })
        setEditingStrengths(false)
      } catch (error) {
        console.error('Error updating strengths:', error)
      }
    } else {
      console.error('User ID is undefined')
    }
  }

  const addPortfolioItem = () => {
    if (portfolioItems.length < 3) {
      setPortfolioItems([
        ...portfolioItems,
        { imageUrl: '', title: '', link: '' }
      ])
    }
  }
  const handleEditSection = (section) => {
    setEditingSections((prev) => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  const handleCheckboxChange = (e, field) => {
    const { value, checked } = e.target

    if (!Array.isArray(profile[field])) {
      setProfile((prev) => ({
        ...prev,
        [field]: []
      }))
    }

    const updatedList = checked
      ? [...(profile[field] || []), value]
      : profile[field].filter((item) => item !== value)

    setProfile((prev) => ({
      ...prev,
      [field]: updatedList
    }))
  }

  const updatePortfolioItem = (index, field, value) => {
    const newItems = [...portfolioItems]
    newItems[index][field] = value
    setPortfolioItems(newItems)
  }

  const handleProfileDetailChange = (e, field) => {
    const { value } = e.target

    if (field === 'location') {
      // Assuming location is entered as "City, State"
      const [city, state] = value.split(',').map((s) => s.trim())
      setProfile((prev) => ({
        ...prev,
        city: city || '', // Set city and state separately
        state: state || ''
      }))
    } else {
      setProfile((prev) => ({
        ...prev,
        [field]: value // General case for fields like email
      }))
    }
  }

  const handlePortfolioImageUpload = async (e, index) => {
    const file = e.target.files[0]
    if (file) {
      const storageRef = ref(
        storage,
        `portfolio-photos/${user.id}/${file.name}`
      )

      try {
        // Upload the file
        await uploadBytes(storageRef, file)

        // Get the download URL
        const newImageUrl = await getDownloadURL(storageRef)

        // Update the portfolio item with the new image URL
        updatePortfolioItem(index, 'imageUrl', newImageUrl)
      } catch (error) {
        console.error('Error uploading portfolio image:', error)
      }
    }
  }

  const removePortfolioItem = (index) => {
    const newItems = [...portfolioItems]
    newItems.splice(index, 1)
    setPortfolioItems(newItems)
  }
  useEffect(() => {
    if (profile && Array.isArray(profile.portfolio_profile_links)) {
      setPortfolioItems(profile.portfolio_profile_links)
    } else {
      setPortfolioItems([]) // Ensure it's always an array
    }
  }, [profile])

  const handleSavePortfolio = async () => {
    try {
      await updateJobseekerProfile(user.id, {
        portfolio_profile_links: portfolioItems
      })
      setProfile({ ...profile, portfolio_profile_links: portfolioItems })
      setEditingPortfolio(false)
    } catch (error) {
      console.error('Error updating portfolio:', error)
    }
  }

  const handlePortfolioScroll = (direction) => {
    if (portfolioRef.current) {
      const scrollAmount = direction === 'left' ? -220 : 220
      portfolioRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' })
    }
  }

  const handleEditVideo = () => {
    setEditingVideo(true)
    setTempVideoUrl(profile?.videos?.[0]?.url || '')
    setTempVideoTitle(profile?.videos?.[0]?.title || '')
    setTempVideoFile(null) // Reset any previously selected video
  }
  const handleCancelVideoEdit = () => {
    setEditingVideo(false)
    setTempVideoUrl(profile?.videos?.[0]?.url || '')
    setTempVideoTitle(profile?.videos?.[0]?.title || '')
    setTempVideoFile(null) // Clear selected video
  }
  const handleSaveVideo = async () => {
    if (!tempVideoFile) {
      console.error('No video file selected')
      return
    }

    if (tempVideoFile.size > 10 * 1024 * 1024) {
      // Limit the file size to 10MB
      alert('Video must be less than 10MB.')
      return
    }

    const videoDuration = await getVideoDuration(tempVideoFile)
    if (videoDuration > 60) {
      alert('Video must be less than 1 minute.')
      return
    }

    const storageRef = ref(
      storage,
      `profile-videos/${user.id}/${tempVideoFile.name}`
    )

    try {
      // Upload the video file
      await uploadBytes(storageRef, tempVideoFile)
      const videoUrl = await getDownloadURL(storageRef)

      // Save video URL and title to Firestore
      const updatedVideos = [{ url: videoUrl, title: tempVideoTitle }]
      await updateJobseekerProfile(user.id, { videos: updatedVideos })

      // Update local state
      setProfile({ ...profile, videos: updatedVideos })
      setEditingVideo(false)
      alert('Video uploaded and saved successfully!')
    } catch (error) {
      console.error('Error uploading video:', error)
    }
  }

  // Function to get the duration of the video
  const getVideoDuration = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src)
        resolve(video.duration)
      }

      video.src = URL.createObjectURL(file)
    })
  }
  const handleCancelVideo = () => {
    setEditingVideo(false)
    setTempVideoUrl(profile?.videos?.[0]?.url || '')
    setTempVideoTitle(profile?.videos?.[0]?.title || '')
    setTempVideoFile(null)
  }
  const handleProfileChange = (field, value) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      [field]: value
    }))
  }
  const handleEditAdditionalDetails = () => {
    setEditingAdditionalDetails(true)
  }

  const handleSaveLinks = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, {
          linkedin: profile?.linkedin,
          github: profile?.github,
          personal_website: profile?.personal_website,
          additional_website_2: profile?.additional_website_2
        })
        setEditingSections((prev) => ({ ...prev, links: false })) // Exit editing mode
      } catch (error) {
        console.error('Error saving links:', error)
      }
    }
  }

  const handleSaveAdditionalDetails = async () => {
    if (user && user.id) {
      try {
        await updateJobseekerProfile(user.id, profile)
        setEditingAdditionalDetails(false) // Exit edit mode after saving
      } catch (error) {
        console.error('Error saving additional details:', error)
      }
    }
  }

  const handleCancelAdditionalDetails = () => {
    setEditingAdditionalDetails(false) // Exit edit mode without saving
  }

  const handleToggle = () => {
    setShowResume(!showResume)
  }

  const handleResumeUpload = async (e) => {
    const file = e.target.files[0]
    if (!file || file.type !== 'application/pdf') {
      alert('Please upload a PDF file.')
      return
    }

    try {
      setUploading(true)
      const storageRef = ref(storage, `resumes/${user.id}/${file.name}`)
      await uploadBytes(storageRef, file)
      const newResumeUrl = await getDownloadURL(storageRef)

      // Update profile in database
      await updateJobseekerProfile(user.id, { resume_url: newResumeUrl })

      // Update local state
      setResumeUrl(newResumeUrl)
      setProfile((prev) => ({ ...prev, resume_url: newResumeUrl }))

      alert('Resume uploaded successfully!')
    } catch (error) {
      console.error('Error uploading resume:', error)
      alert('Error uploading resume. Please try again.')
    } finally {
      setUploading(false)
    }
  }

  const handleSaveResume = async () => {
    if (!tempResumeFile) return

    try {
      await updateJobseekerProfile('userId', { resume_url: resumeUrl })
      setEditingResume(false)
      alert('Resume uploaded and saved successfully!')
    } catch (error) {
      console.error('Error saving resume:', error)
    }
  }

  return (
    <PageWrapper>
      <NavbarWrapper>
        <JobSeekerNav />
      </NavbarWrapper>
      <PageContainer>
        <ContentContainer>
          <ToggleContainer>
            <ToggleLabel htmlFor="resume-toggle">
              {showResume ? 'Back to Profile' : 'View and Update Resume'}
            </ToggleLabel>
            <ToggleSwitch checked={showResume} onClick={handleToggle}>
              <ToggleSlider checked={showResume} />
            </ToggleSwitch>
          </ToggleContainer>

          {showResume ? (
            <ResumePageContainer>
              <ResumeViewer>
                {resumeUrl ? (
                  <ResumeFrame
                    src={`${resumeUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                    title="Resume Viewer"
                  />
                ) : (
                  <NoResumeMessage>
                    <Upload size={48} color="#6b7280" />
                    <p>No resume uploaded yet</p>
                  </NoResumeMessage>
                )}
              </ResumeViewer>

              <FileInput
                type="file"
                ref={fileInputRef}
                accept="application/pdf"
                onChange={handleResumeUpload}
              />

              <UploadButton
                onClick={() => fileInputRef.current?.click()}
                title="Upload new resume"
              >
                <Upload size={20} />
                {resumeUrl ? 'Update Resume' : 'Upload Resume'}
              </UploadButton>

              {uploading && (
                <UploadingOverlay>Uploading resume...</UploadingOverlay>
              )}
            </ResumePageContainer>
          ) : (
            <>
              <CoverPhoto src="/images/cover-photo.png.jpg"></CoverPhoto>
              <ProfileContent>
                <MainContent>
                  <ProfileCard>
                    <ProfileHeader>
                      {renderProfilePictureSection()}
                      <ProfileInfo>
                        <ProfileName>
                          {user?.first_name} {user?.last_name}{' '}
                          {profile?.pronouns && (
                            <small
                              style={{ fontSize: '0.85rem', color: '#6B7280' }}
                            >
                              ({profile?.pronouns})
                            </small>
                          )}
                        </ProfileName>
                        <ProfileTitle>
                          {hasWorkHistory
                            ? `${firstJob?.title || 'Title not provided'} at ${
                                firstJob?.employer || 'Employer not provided'
                              }`
                            : 'Student'}
                        </ProfileTitle>
                      </ProfileInfo>
                    </ProfileHeader>

                    <SectionTitle>About Me</SectionTitle>
                    {renderAboutMeSection()}
                  </ProfileCard>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Work History</SectionTitle>
                      {!editing && (
                        <EditIconButton onClick={handleEditClick}>
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>
                    {renderWorkHistory()}
                  </ProfileCard>

                  <ProfileCard>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <SectionTitle>Education</SectionTitle>
                      {!editingEducation && (
                        <EditIconButton onClick={handleEditEducation}>
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </div>
                    {editingEducation ? (
                      <>
                        <GridContainer>
                          {profile?.education.map((edu, index) => (
                            <div key={index}>
                              <WorkHistoryItem>
                                <IconWrapper>
                                  <GraduationCap size={20} />
                                </IconWrapper>
                                <div style={{ width: '100%' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginBottom: '1rem'
                                    }}
                                  >
                                    <h4>Education {index + 1}</h4>
                                    <DeleteButton
                                      onClick={() => removeEducation(index)}
                                    >
                                      <X size={16} />
                                    </DeleteButton>
                                  </div>

                                  <GridItem>
                                    <EditLabel>Degree Type</EditLabel>
                                    <ModernInput
                                      name="degree_type"
                                      value={edu.degree_type}
                                      onChange={(e) =>
                                        handleEducationHistoryChange(
                                          index,
                                          'degree_type',
                                          e.target.value
                                        )
                                      }
                                      placeholder="e.g., Bachelor's, Master's, PhD"
                                    />
                                  </GridItem>

                                  <GridItem>
                                    <EditLabel>Field of Study</EditLabel>
                                    <ModernInput
                                      name="field_of_study"
                                      value={edu.field_of_study}
                                      onChange={(e) =>
                                        handleEducationHistoryChange(
                                          index,
                                          'field_of_study',
                                          e.target.value
                                        )
                                      }
                                      placeholder="e.g., Computer Science, Business"
                                    />
                                  </GridItem>

                                  <GridItem>
                                    <EditLabel>Institution</EditLabel>
                                    <ModernInput
                                      name="institution"
                                      value={edu.institution}
                                      onChange={(e) =>
                                        handleEducationHistoryChange(
                                          index,
                                          'institution',
                                          e.target.value
                                        )
                                      }
                                      placeholder="e.g., University of Michigan"
                                    />
                                  </GridItem>

                                  <GridItem>
                                    <EditLabel>Graduation Date</EditLabel>
                                    <ModernInput
                                      name="graduation_date"
                                      type="month"
                                      value={edu.graduation_date || ''}
                                      onChange={(e) =>
                                        handleEducationHistoryChange(
                                          index,
                                          'graduation_date',
                                          e.target.value
                                        )
                                      }
                                    />
                                  </GridItem>
                                </div>
                              </WorkHistoryItem>
                            </div>
                          ))}
                        </GridContainer>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px',
                            marginTop: '10px'
                          }}
                        >
                          <CancelButton onClick={handleEditEducation}>
                            Cancel
                          </CancelButton>
                          <AddButton onClick={addEducation}>
                            Add Education
                          </AddButton>
                          <SaveButton onClick={handleSaveEducation}>
                            Save
                          </SaveButton>
                        </div>
                      </>
                    ) : (
                      <>
                        {profile?.education.map((edu, index) => (
                          <WorkHistoryItem key={index}>
                            <IconWrapper>
                              <GraduationCap size={20} />
                            </IconWrapper>
                            <div>
                              <p>
                                {edu.degree_type} in {edu.field_of_study} from{' '}
                                {edu.institution}
                              </p>
                            </div>
                          </WorkHistoryItem>
                        ))}
                      </>
                    )}
                  </ProfileCard>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Top Strengths</SectionTitle>
                      {!editingStrengths && (
                        <EditIconButton
                          onClick={() => setEditingStrengths(true)}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    {editingStrengths ? (
                      <div>
                        <p style={{ marginBottom: '1rem', color: '#6b7280' }}>
                          Select up to 3 strengths that best represent you:
                        </p>

                        {/* Available strengths */}
                        <div style={{ marginBottom: '1.5rem' }}>
                          <h4
                            style={{ marginBottom: '0.5rem', color: '#4b5563' }}
                          >
                            Available Strengths:
                          </h4>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '0.5rem'
                            }}
                          >
                            {strengthsOptions.map((strength) => (
                              <StrengthBubble
                                key={strength}
                                onClick={() => addStrength(strength)}
                                style={{
                                  cursor:
                                    selectedStrengths.length >= 3 ||
                                    selectedStrengths.includes(strength)
                                      ? 'not-allowed'
                                      : 'pointer',
                                  opacity: selectedStrengths.includes(strength)
                                    ? 0.5
                                    : 1,
                                  backgroundColor: selectedStrengths.includes(
                                    strength
                                  )
                                    ? '#b799d4'
                                    : '#caaae1'
                                }}
                              >
                                {strength}
                              </StrengthBubble>
                            ))}
                          </div>
                        </div>

                        {/* Selected strengths */}
                        <div style={{ marginBottom: '1.5rem' }}>
                          <h4
                            style={{ marginBottom: '0.5rem', color: '#4b5563' }}
                          >
                            Your Selected Strengths ({selectedStrengths.length}
                            /3):
                          </h4>
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '0.5rem'
                            }}
                          >
                            {Array.isArray(selectedStrengths) &&
                              selectedStrengths.map((strength) => (
                                <StrengthBubble
                                  key={strength}
                                  style={{
                                    backgroundColor: '#caaae1',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  {strength}
                                  <DeleteButton
                                    onClick={() => removeStrength(strength)}
                                    style={{ marginLeft: '0.5rem' }}
                                  >
                                    <X size={16} />
                                  </DeleteButton>
                                </StrengthBubble>
                              ))}
                            {selectedStrengths.length === 0 && (
                              <p
                                style={{
                                  color: '#6b7280',
                                  fontStyle: 'italic'
                                }}
                              >
                                No strengths selected yet
                              </p>
                            )}
                          </div>
                        </div>

                        <ButtonRow>
                          <CancelButton
                            onClick={() => setEditingStrengths(false)}
                          >
                            <X size={16} />
                            Cancel
                          </CancelButton>
                          <SaveButton
                            onClick={handleSaveStrengths}
                            disabled={selectedStrengths.length === 0}
                            style={{
                              opacity: selectedStrengths.length === 0 ? 0.5 : 1,
                              cursor:
                                selectedStrengths.length === 0
                                  ? 'not-allowed'
                                  : 'pointer'
                            }}
                          >
                            <Save size={16} />
                            Save
                          </SaveButton>
                        </ButtonRow>
                      </div>
                    ) : (
                      <div>
                        {Array.isArray(selectedStrengths) &&
                        selectedStrengths.length > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '0.5rem'
                            }}
                          >
                            {selectedStrengths.map((strength) => (
                              <StrengthBubble key={strength}>
                                {strength}
                              </StrengthBubble>
                            ))}
                          </div>
                        ) : (
                          <p style={{ color: '#6b7280', fontStyle: 'italic' }}>
                            No strengths added yet. Click edit to add your top
                            strengths.
                          </p>
                        )}
                      </div>
                    )}
                  </ProfileCard>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Portfolio</SectionTitle>
                      <EditIconButton onClick={() => setEditingPortfolio(true)}>
                        <Edit size={16} /> Edit
                      </EditIconButton>
                    </HeaderRow>
                    {editingPortfolio ? (
                      <>
                        <PortfolioContainer>
                          <PortfolioScroller ref={portfolioRef}>
                            {portfolioItems.map((item, index) => (
                              <PortfolioItem key={index}>
                                {item.imageUrl ? (
                                  <PortfolioImage src={item.imageUrl} />
                                ) : (
                                  <FileInputLabel>
                                    <Upload size={16} />
                                    Upload Image
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handlePortfolioImageUpload(e, index)
                                      }
                                      style={{ display: 'none' }}
                                    />
                                  </FileInputLabel>
                                )}
                                <ModernInput
                                  value={item.title}
                                  onChange={(e) =>
                                    updatePortfolioItem(
                                      index,
                                      'title',
                                      e.target.value
                                    )
                                  }
                                  placeholder="Title"
                                />
                                <ModernInput
                                  value={item.link}
                                  onChange={(e) =>
                                    updatePortfolioItem(
                                      index,
                                      'link',
                                      e.target.value
                                    )
                                  }
                                  placeholder="Project URL"
                                />
                                <DeleteButton
                                  onClick={() => removePortfolioItem(index)}
                                >
                                  <X size={16} />
                                </DeleteButton>
                              </PortfolioItem>
                            ))}
                          </PortfolioScroller>
                        </PortfolioContainer>
                        {portfolioItems.length < 3 && (
                          <AddButton onClick={addPortfolioItem}>
                            Add Portfolio Item
                          </AddButton>
                        )}
                        <ButtonRow>
                          <SaveButton onClick={handleSavePortfolio}>
                            <Save size={16} /> Save
                          </SaveButton>
                          <CancelButton
                            onClick={() => setEditingPortfolio(false)}
                          >
                            <X size={16} /> Cancel
                          </CancelButton>
                        </ButtonRow>
                      </>
                    ) : (
                      <PortfolioContainer>
                        {Array.isArray(portfolioItems) &&
                        portfolioItems.length > 0 ? (
                          <PortfolioScroller ref={portfolioRef}>
                            {portfolioItems.map((item, index) => (
                              <PortfolioItem key={index}>
                                <a
                                  href={item.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <PortfolioImage src={item.imageUrl} />
                                  <PortfolioTitle>{item.title}</PortfolioTitle>
                                </a>
                              </PortfolioItem>
                            ))}
                          </PortfolioScroller>
                        ) : (
                          <p>No portfolio items added yet.</p>
                        )}
                      </PortfolioContainer>
                    )}
                  </ProfileCard>
                </MainContent>
                <Sidebar>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Additional Details</SectionTitle>
                      {!editingAdditionalDetails && (
                        <EditIconButton
                          onClick={() => setEditingAdditionalDetails(true)}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    <DetailParagraph>
                      <IconContainer>
                        <Mail size={18} />
                      </IconContainer>
                      {user?.email} {/* Email is no longer editable */}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <MapPinIcon size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Location</EditLabel>
                          <ModernInput
                            name="city"
                            value={profile?.city || ''}
                            onChange={(e) =>
                              handleProfileChange('city', e.target.value)
                            } // pass event correctly
                            placeholder="City"
                          />
                          <ModernInput
                            name="state"
                            value={profile?.state || ''}
                            onChange={(e) =>
                              handleProfileChange('state', e.target.value)
                            } // pass event correctly
                            placeholder="State"
                          />
                        </>
                      ) : profile?.city && profile?.state ? (
                        `${profile?.city}, ${profile?.state}`
                      ) : (
                        'Location not provided'
                      )}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <Globe size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Languages</EditLabel>
                          <CheckboxContainer>
                            {languageOptions.map((lang) => (
                              <CheckboxLabel key={lang}>
                                <CheckboxInput
                                  type="checkbox"
                                  value={lang}
                                  checked={
                                    profile?.languages?.includes(lang) || false
                                  } // Fallback to false
                                  onChange={(e) =>
                                    handleCheckboxChange(e, 'languages')
                                  }
                                />
                                {lang}
                              </CheckboxLabel>
                            ))}
                          </CheckboxContainer>
                        </>
                      ) : (
                        `Languages: ${
                          profile?.languages?.join(', ') || 'Not specified'
                        }` // Fallback if undefined
                      )}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <Clock size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Years of Experience</EditLabel>
                          <ModernSelect
                            name="yearsExperience"
                            value={profile?.years_experience || ''} // Fallback to empty string
                            onChange={(e) =>
                              handleProfileDetailChange(e, 'years_experience')
                            }
                          >
                            <option value="">Select experience</option>
                            {experienceLevels.map((exp) => (
                              <option key={exp} value={exp}>
                                {exp} experience
                              </option>
                            ))}
                          </ModernSelect>
                        </>
                      ) : (
                        `${
                          profile?.years_experience || 'Not specified'
                        } experience`
                      )}
                    </DetailParagraph>

                    {/* <DetailParagraph>
                      <IconContainer>
                        <Landmark size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Work Preferences</EditLabel>
                          <CheckboxContainer>
                            {workPreferences.map((option) => (
                              <CheckboxLabel key={option}>
                                <CheckboxInput
                                  type="checkbox"
                                  value={option}
                                  checked={
                                    Array.isArray(
                                      profile?.preferred_telework_policy
                                    ) &&
                                    profile.preferred_telework_policy.includes(
                                      option
                                    )
                                  } // Ensure it's an array before calling .includes()
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      'preferred_telework_policy'
                                    )
                                  }
                                />
                                {option}
                              </CheckboxLabel>
                            ))}
                          </CheckboxContainer>
                        </>
                      ) : profile?.preferred_telework_policy &&
                        Array.isArray(profile.preferred_telework_policy) ? (
                        `Prefers ${profile.preferred_telework_policy.join(
                          ', '
                        )}`
                      ) : (
                        'Not specified'
                      )}
                    </DetailParagraph> */}

                    <DetailParagraph>
                      <IconContainer>
                        <LineChart size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Startup Experience</EditLabel>
                          <ModernSelect
                            name="startupExperience"
                            value={
                              profile?.startup_experience?.toString() || ''
                            } // Fallback to empty string
                            onChange={(e) =>
                              setProfile((prev) => ({
                                ...prev,
                                startup_experience: e.target.value === 'true'
                              }))
                            }
                          >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </ModernSelect>
                        </>
                      ) : (
                        `Startup Experience: ${
                          profile?.startup_experience ? 'Yes' : 'No'
                        }`
                      )}
                    </DetailParagraph>

                    <DetailParagraph>
                      <IconContainer>
                        <Users size={18} />
                      </IconContainer>
                      {editingAdditionalDetails ? (
                        <>
                          <EditLabel>Job Level</EditLabel>
                          <ModernSelect
                            name="job_level"
                            value={profile?.job_level || ''} // Fallback to empty string
                            onChange={(e) =>
                              handleProfileDetailChange(e, 'job_level')
                            }
                          >
                            <option value="">Select job level</option>
                            {currentLevels.map((level) => (
                              <option key={level} value={level}>
                                {level}
                              </option>
                            ))}
                          </ModernSelect>
                        </>
                      ) : (
                        `Job Level: ${profile?.job_level || 'Not specified'}`
                      )}
                    </DetailParagraph>

                    {/* Save and Cancel Buttons */}
                    {editingAdditionalDetails && (
                      <ButtonRow>
                        <SaveButton onClick={handleSaveAdditionalDetails}>
                          <Save size={16} /> Save
                        </SaveButton>
                        <CancelButton onClick={handleCancelAdditionalDetails}>
                          <X size={16} /> Cancel
                        </CancelButton>
                      </ButtonRow>
                    )}
                  </ProfileCard>
                  <ProfileCard>
                    <HeaderRow>
                      <SectionTitle>Links</SectionTitle>
                      {!editingSections.links && (
                        <EditIconButton
                          onClick={() => handleEditSection('links')}
                        >
                          <Edit size={16} /> Edit
                        </EditIconButton>
                      )}
                    </HeaderRow>

                    {editingSections.links ? (
                      <>
                        <DetailParagraph>
                          <EditLabel>LinkedIn</EditLabel>
                          <ModernInput
                            name="linkedin"
                            value={profile?.linkedin || ''}
                            onChange={(e) =>
                              handleProfileChange('linkedin', e.target.value)
                            }
                            placeholder="LinkedIn URL"
                          />
                        </DetailParagraph>

                        <DetailParagraph>
                          <EditLabel>GitHub</EditLabel>
                          <ModernInput
                            name="github"
                            value={profile?.github || ''}
                            onChange={(e) =>
                              handleProfileChange('github', e.target.value)
                            }
                            placeholder="GitHub URL"
                          />
                        </DetailParagraph>

                        <DetailParagraph>
                          <EditLabel>Personal Website</EditLabel>
                          <ModernInput
                            name="personal_website"
                            value={profile?.personal_website || ''}
                            onChange={(e) =>
                              handleProfileChange(
                                'personal_website',
                                e.target.value
                              )
                            }
                            placeholder="Personal Website URL"
                          />
                        </DetailParagraph>

                        <DetailParagraph>
                          <EditLabel>Portfolio</EditLabel>{' '}
                          {/* New Portfolio link */}
                          <ModernInput
                            name="portfolio"
                            value={profile?.portfolio || ''}
                            onChange={(e) =>
                              handleProfileChange('portfolio', e.target.value)
                            }
                            placeholder="Portfolio URL"
                          />
                        </DetailParagraph>

                        <DetailParagraph>
                          <EditLabel>Additional Website</EditLabel>
                          <ModernInput
                            name="additional_website_2"
                            value={profile?.additional_website_2 || ''}
                            onChange={(e) =>
                              handleProfileChange(
                                'additional_website_2',
                                e.target.value
                              )
                            }
                            placeholder="Additional Website URL"
                          />
                        </DetailParagraph>

                        <ButtonRow>
                          <SaveButton onClick={handleSaveLinks}>
                            <Save size={16} /> Save
                          </SaveButton>
                          <CancelButton
                            onClick={() => handleEditSection('links')}
                          >
                            <X size={16} /> Cancel
                          </CancelButton>
                        </ButtonRow>
                      </>
                    ) : (
                      <>
                        {profile?.linkedin && (
                          <DetailParagraph>
                            <IconContainer>
                              <Linkedin size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.linkedin.startsWith('http')
                                  ? profile.linkedin
                                  : `https://${profile.linkedin}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              LinkedIn
                            </Link>
                          </DetailParagraph>
                        )}

                        {profile?.github && (
                          <DetailParagraph>
                            <IconContainer>
                              <Github size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.github.startsWith('http')
                                  ? profile.github
                                  : `https://${profile.github}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              GitHub
                            </Link>
                          </DetailParagraph>
                        )}

                        {profile?.personal_website && (
                          <DetailParagraph>
                            <IconContainer>
                              <Globe size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.personal_website.startsWith('http')
                                  ? profile.personal_website
                                  : `https://${profile.personal_website}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Personal Website
                            </Link>
                          </DetailParagraph>
                        )}

                        {profile?.portfolio && (
                          <DetailParagraph>
                            <IconContainer>
                              <FileText size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.portfolio.startsWith('http')
                                  ? profile.portfolio
                                  : `https://${profile.portfolio}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Portfolio
                            </Link>
                          </DetailParagraph>
                        )}

                        {profile?.additional_website_2 && (
                          <DetailParagraph>
                            <IconContainer>
                              <Globe size={18} />
                            </IconContainer>
                            <Link
                              href={
                                profile.additional_website_2.startsWith('http')
                                  ? profile.additional_website_2
                                  : `https://${profile.additional_website_2}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Additional Website
                            </Link>
                          </DetailParagraph>
                        )}
                      </>
                    )}
                  </ProfileCard>
                </Sidebar>
              </ProfileContent>
            </>
          )}
        </ContentContainer>
      </PageContainer>
    </PageWrapper>
  )
}
