import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { auth } from '../firebase'
import { createJobPosting } from '../models/JobPosting'
import { getEmployerProfile } from '../models/EmployerProfile'
import EmployerNav from './EmployerNav'

import {
  Heart,
  Calendar,
  GraduationCap,
  Laptop,
  Smile,
  Monitor,
  Users,
  Train,
  Coffee,
  BanknoteIcon,
  Shield,
  Plus,
  X,
  ArrowLeft
} from 'lucide-react'

// Styled Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f4f8;
  color: #2d3748;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 999;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e2e8f0;
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  background: none;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    background: #f1f5f9;
    color: #334155;
  }
`

const FormCard = styled.form`
  padding: 2rem;
`

const FormTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 3rem;
`

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }

  &::placeholder {
    color: #94a3b8;
  }
`

const SalaryInputGroup = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const SalaryInput = styled(Input)`
  width: 200px;
`

const TextFormatGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
`

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  min-height: 120px;
  font-family: 'Inter', sans-serif;
  resize: vertical;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }

  &::placeholder {
    color: #94a3b8;
  }
`

const Select = styled.select`
  width: 80%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-left: 20px;
  font-size: 0.875rem;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s;

  &:hover {
    background: #f8fafc;
  }

  input {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 4px;
    cursor: pointer;
  }
`

const RadioGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: all 0.2s;
  background: ${(props) => (props.checked ? '#f8f4fb' : 'white')};
  border: 1px solid ${(props) => (props.checked ? '#caaae1' : '#e2e8f0')};

  &:hover {
    background: #f8f4fb;
  }

  input {
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
`

const SkillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
`

const SkillTag = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f8f4fb;
  color: #805ad5;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
`

const RemoveButton = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  color: #805ad5;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;

  &:hover {
    color: #553c9a;
  }
`
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #caaae1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #b38fd1;
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-top: 3rem;
`

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
`

const BenefitCard = styled.div`
  position: relative;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const BenefitIcon = styled.div`
  width: 48px;
  height: 48px;
  background-color: #f8f4fb;
  color: #caaae1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(2px);
`
const FormSection = styled.div`
  margin-bottom: 2.5rem;
  position: relative;
`
const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
  color: #475569;

  ${(props) =>
    props.required &&
    `
    &::after {
      content: '*';
      color: #e53e3e;
      margin-left: 4px;
    }
  `}
`

// Constants
const employmentTypes = [
  'Full-Time',
  'Part-time',
  'Internship',
  'Contract',
  'Hourly'
]

const levelOptions = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]

const workPolicyOptions = ['Remote', 'In-Office', 'Hybrid']

const benefitCategories = [
  { value: 'Healthcare', label: 'Healthcare', icon: Heart },
  { value: 'Time Off', label: 'Time Off', icon: Calendar },
  {
    value: 'Learning & Development',
    label: 'Learning & Development',
    icon: GraduationCap
  },
  { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
  { value: 'Wellness', label: 'Wellness', icon: Smile },
  { value: 'Equipment', label: 'Equipment', icon: Monitor },
  { value: 'Family', label: 'Family', icon: Users },
  { value: 'Commuter', label: 'Commuter', icon: Train },
  { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
  { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
  { value: 'Insurance', label: 'Insurance', icon: Shield }
]

// Main Component
const JobPostForm = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [employerProfile, setEmployerProfile] = useState(null)
  const [showBenefitModal, setShowBenefitModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newBenefit, setNewBenefit] = useState({
    category: '',
    description: ''
  })

  const [formData, setFormData] = useState({
    title: '',
    type: [],
    location: '',
    work_policy: '',
    department: '',
    level: '',
    required_skills: [],
    skillInput: '',
    description: '',
    responsibilities: '',
    requirements: '',
    nice_to_haves: '',
    benefits: [],
    salary_type: 'yearly', // 'yearly', 'hourly', 'unpaid', or 'credit'
    salary_range_start: '40000',
    salary_range_end: '150000',
    willing_to_sponsor: false
  })

  useEffect(() => {
    const fetchEmployerProfile = async () => {
      try {
        const profile = await getEmployerProfile(auth.currentUser.uid)
        setEmployerProfile(profile)
        if (profile.benefits) {
          setFormData((prev) => ({
            ...prev,
            benefits: profile.benefits
          }))
        }
        setLoading(false)
      } catch (error) {
        console.error('Error fetching employer profile:', error)
        setLoading(false)
      }
    }

    fetchEmployerProfile()
  }, [])

  const handleSalaryTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      salary_type: type,
      salary_range_start:
        type === 'yearly' ? '40000' : type === 'hourly' ? '10' : '0',
      salary_range_end:
        type === 'yearly' ? '150000' : type === 'hourly' ? '100' : '0'
    }))
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      type: prev.type.includes(type)
        ? prev.type.filter((t) => t !== type)
        : [...prev.type, type]
    }))
  }

  const handleSkillAdd = () => {
    if (formData.skillInput.trim()) {
      setFormData((prev) => ({
        ...prev,
        required_skills: [...prev.required_skills, prev.skillInput.trim()],
        skillInput: ''
      }))
    }
  }

  const handleSkillRemove = (skillToRemove) => {
    setFormData((prev) => ({
      ...prev,
      required_skills: prev.required_skills.filter(
        (skill) => skill !== skillToRemove
      )
    }))
  }

  const handleAddBenefit = () => {
    if (!newBenefit.category || !newBenefit.description) {
      alert('Please fill in all benefit fields')
      return
    }

    setFormData((prev) => ({
      ...prev,
      benefits: [...prev.benefits, newBenefit]
    }))
    setNewBenefit({ category: '', description: '' })
    setShowBenefitModal(false)
  }

  const handleRemoveBenefit = (index) => {
    setFormData((prev) => ({
      ...prev,
      benefits: prev.benefits.filter((_, i) => i !== index)
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true) // Add this state if you haven't already
    try {
      console.log('Starting form submission')

      // Verify user is authenticated
      if (!auth.currentUser) {
        throw new Error('You must be logged in to create a job posting')
      }

      const postingData = {
        title: formData.title,
        type: formData.type,
        location: formData.location,
        work_policy: formData.work_policy,
        department: formData.department,
        level: formData.level,
        description: formData.description,
        responsibilities: formData.responsibilities,
        requirements: formData.requirements,
        nice_to_haves: formData.nice_to_haves || null,
        recommended_skills: formData.required_skills,
        benefits: formData.benefits.map((benefit) => ({
          category: benefit.category,
          description: benefit.description
        })),
        willing_to_sponsor: formData.willing_to_sponsor,
        salary: {
          type: formData.salary_type,
          range_start:
            formData.salary_type === 'unpaid' ||
            formData.salary_type === 'credit'
              ? 0
              : parseInt(formData.salary_range_start),
          range_end:
            formData.salary_type === 'unpaid' ||
            formData.salary_type === 'credit'
              ? 0
              : parseInt(formData.salary_range_end)
        }
      }

      console.log('Prepared posting data:', postingData)

      const jobRef = await createJobPosting(postingData)
      console.log('Job posting created successfully:', jobRef.id)

      navigate('/job-postings')
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      alert(error.message || 'Error creating job posting. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }

  if (loading) return <div>Loading...</div>

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <TopBar>
          <BackButton onClick={() => navigate('/job-postings')}>
            <ArrowLeft size={16} />
            Back to all Jobs
          </BackButton>
        </TopBar>

        <FormCard onSubmit={handleSubmit}>
          <FormTitle>Create Job Posting</FormTitle>

          <FormSection>
            <Label required>Job Title</Label>
            <Input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              placeholder="e.g., Senior Software Engineer"
            />
          </FormSection>

          <FormSection>
            <Label required>Type of Employment</Label>
            <CheckboxGroup>
              {employmentTypes.map((type) => (
                <CheckboxLabel key={type}>
                  <input
                    type="checkbox"
                    checked={formData.type.includes(type)}
                    onChange={() => handleTypeChange(type)}
                    required={formData.type.length === 0}
                  />
                  <span>{type}</span>
                </CheckboxLabel>
              ))}
            </CheckboxGroup>
          </FormSection>

          <GridContainer>
            <FormSection>
              <Label required>Job Location</Label>
              <Input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                required
                placeholder="e.g., New York, NY"
              />
            </FormSection>

            <FormSection>
              <Label required style={{ marginLeft: '20px' }}>
                Work Policy
              </Label>
              <Select
                name="work_policy"
                value={formData.work_policy}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Work Policy</option>
                {workPolicyOptions.map((policy) => (
                  <option key={policy} value={policy}>
                    {policy}
                  </option>
                ))}
              </Select>
            </FormSection>
          </GridContainer>

          <GridContainer>
            <FormSection>
              <Label required>Department</Label>
              <Input
                type="text"
                name="department"
                value={formData.department}
                onChange={handleInputChange}
                required
                placeholder="e.g., Engineering"
              />
            </FormSection>

            <FormSection>
              <Label required style={{ marginLeft: '20px' }}>
                Level
              </Label>
              <Select
                name="level"
                value={formData.level}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Level</option>
                {levelOptions.map((level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
              </Select>
            </FormSection>
          </GridContainer>
          <FormSection>
            <Label>Compensation</Label>
            <RadioGroup>
              <RadioLabel checked={formData.salary_type === 'yearly'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'yearly'}
                  onChange={() => handleSalaryTypeChange('yearly')}
                />
                Yearly Salary
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'hourly'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'hourly'}
                  onChange={() => handleSalaryTypeChange('hourly')}
                />
                Hourly Rate
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'unpaid'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'unpaid'}
                  onChange={() => handleSalaryTypeChange('unpaid')}
                />
                Unpaid
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'credit'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'credit'}
                  onChange={() => handleSalaryTypeChange('credit')}
                />
                School Credit
              </RadioLabel>
            </RadioGroup>

            {(formData.salary_type === 'yearly' ||
              formData.salary_type === 'hourly') && (
              <SalaryInputGroup>
                <SalaryInput
                  type="number"
                  name="salary_range_start"
                  value={formData.salary_range_start}
                  onChange={handleInputChange}
                  placeholder="Starting range"
                />
                <span>to</span>
                <SalaryInput
                  type="number"
                  name="salary_range_end"
                  value={formData.salary_range_end}
                  onChange={handleInputChange}
                  placeholder="Ending range"
                />
                {formData.salary_type === 'hourly' && <span>/hour</span>}
              </SalaryInputGroup>
            )}
          </FormSection>

          <FormSection>
            <Label required>Required Skills</Label>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <Input
                type="text"
                name="skillInput"
                value={formData.skillInput}
                onChange={handleInputChange}
                placeholder="Enter a skill and press Add"
              />
              <Button
                type="button"
                onClick={handleSkillAdd}
                disabled={!formData.skillInput.trim()}
              >
                <Plus size={16} /> Add
              </Button>
            </div>
            <input
              type="hidden"
              required
              value={formData.required_skills.length > 0 ? 'valid' : ''}
            />
            <SkillContainer>
              {formData.required_skills.map((skill, index) => (
                <SkillTag key={index}>
                  {skill}
                  <RemoveButton onClick={() => handleSkillRemove(skill)}>
                    <X size={14} />
                  </RemoveButton>
                </SkillTag>
              ))}
            </SkillContainer>
          </FormSection>

          <FormSection>
            <Label required>Job Summary</Label>
            <Textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              required
              placeholder="Provide a detailed description of the role"
            />
          </FormSection>

          <FormSection>
            <Label required>Responsibilities</Label>
            <Textarea
              name="responsibilities"
              value={formData.responsibilities}
              onChange={handleInputChange}
              required
              placeholder="List the key responsibilities of the role"
            />
          </FormSection>

          <FormSection>
            <Label required>Requirements</Label>
            <Textarea
              name="requirements"
              value={formData.requirements}
              onChange={handleInputChange}
              required
              placeholder="List the requirements for this position"
            />
          </FormSection>

          <FormSection>
            <Label required>Preferred Qualifications</Label>
            <Textarea
              name="nice_to_haves"
              value={formData.nice_to_haves}
              onChange={handleInputChange}
              required
              placeholder="List any preferred qualifications or bonus skills"
            />
          </FormSection>

          <FormSection>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Label required>Benefits</Label>
              <Button type="button" onClick={() => setShowBenefitModal(true)}>
                <Plus size={16} /> Add Benefit
              </Button>
            </div>
            <input
              type="hidden"
              required
              value={formData.benefits.length > 0 ? 'valid' : ''}
            />
            <BenefitsGrid>
              {formData.benefits.map((benefit, index) => {
                const categoryInfo = benefitCategories.find(
                  (cat) => cat.value === benefit.category
                )
                const Icon = categoryInfo?.icon || Plus
                return (
                  <BenefitCard key={index}>
                    <RemoveButton
                      style={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.5rem'
                      }}
                      onClick={() => handleRemoveBenefit(index)}
                    >
                      <X size={16} />
                    </RemoveButton>
                    <BenefitIcon>
                      <Icon size={24} />
                    </BenefitIcon>
                    <h3 style={{ marginBottom: '0.5rem' }}>
                      {categoryInfo?.label}
                    </h3>
                    <p style={{ fontSize: '14px', color: '#666' }}>
                      {benefit.description}
                    </p>
                  </BenefitCard>
                )
              })}
            </BenefitsGrid>
          </FormSection>

          <FormSection>
            <Label required>Willing to sponsor work visa?</Label>
            <RadioGroup>
              <RadioLabel checked={formData.willing_to_sponsor === true}>
                <input
                  type="radio"
                  name="willing_to_sponsor"
                  required
                  checked={formData.willing_to_sponsor === true}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: 'willing_to_sponsor',
                        value: true
                      }
                    })
                  }
                />
                Yes
              </RadioLabel>
              <RadioLabel checked={formData.willing_to_sponsor === false}>
                <input
                  type="radio"
                  name="willing_to_sponsor"
                  required
                  checked={formData.willing_to_sponsor === false}
                  onChange={(e) =>
                    handleInputChange({
                      target: {
                        name: 'willing_to_sponsor',
                        value: false
                      }
                    })
                  }
                />
                No
              </RadioLabel>
            </RadioGroup>
          </FormSection>

          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Posting...' : 'Post Job'}
          </SubmitButton>
        </FormCard>
      </MainContent>

      {showBenefitModal && (
        <>
          <Modal>
            <FormTitle>Add Benefit</FormTitle>
            <FormSection>
              <Label>Benefit Category</Label>
              <Select
                value={newBenefit.category}
                onChange={(e) =>
                  setNewBenefit((prev) => ({
                    ...prev,
                    category: e.target.value
                  }))
                }
              >
                <option value="">Select a category</option>
                {benefitCategories.map((category) => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </Select>
            </FormSection>

            <FormSection>
              <Label>Description</Label>
              <Textarea
                value={newBenefit.description}
                onChange={(e) =>
                  setNewBenefit((prev) => ({
                    ...prev,
                    description: e.target.value
                  }))
                }
                placeholder="Enter benefit description"
              />
            </FormSection>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1rem'
              }}
            >
              <Button
                type="button"
                onClick={() => setShowBenefitModal(false)}
                style={{ backgroundColor: '#e2e8f0', color: '#4a5568' }}
              >
                Cancel
              </Button>
              <Button type="button" onClick={handleAddBenefit}>
                Add Benefit
              </Button>
            </div>
          </Modal>
          <ModalOverlay onClick={() => setShowBenefitModal(false)} />
        </>
      )}
    </Container>
  )
}

export default JobPostForm
