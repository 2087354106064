// src/models/User.js

import { db } from '../firebase'
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  query, // Add this
  where, // Add this
  getDocs // Add this
} from 'firebase/firestore'

const USERS_COLLECTION = 'users'

// User data structure
const createUserObject = (userData) => ({
  email: userData.email,
  firebase_uid: userData.firebase_uid,
  role: userData.role,
  first_name: userData.first_name,
  last_name: userData.last_name,
  company_name: userData.company_name || null,
  subscription_end_date: userData.subscription_end_date || null,
  event_code: userData.event_code || null,
  coupon_code: userData.coupon_code || null,
  coupon_id: userData.coupon_id || null,
  created_at: serverTimestamp(),
  updated_at: serverTimestamp()
})

// Create a new user
export const createUser = async (userData) => {
  const userRef = doc(collection(db, USERS_COLLECTION), userData.firebase_uid)
  await setDoc(userRef, createUserObject(userData))
  return userRef
}

// Get a user by firebase_uid
export const getUserByFirebaseUid = async (firebase_uid) => {
  const userRef = doc(db, USERS_COLLECTION, firebase_uid)
  const userSnap = await getDoc(userRef)
  if (userSnap.exists()) {
    return { id: userSnap.id, ...userSnap.data() }
  } else {
    return null
  }
}

export const getUserById = async (userId) => {
  const userRef = doc(db, USERS_COLLECTION, userId)
  const userSnap = await getDoc(userRef)

  if (userSnap.exists()) {
    return { id: userSnap.id, ...userSnap.data() }
  } else {
    console.log('No user found with ID:', userId)
    return null
  }
}

// Update a user
export const updateUser = async (firebase_uid, updateData) => {
  const userRef = doc(db, USERS_COLLECTION, firebase_uid)
  await updateDoc(userRef, { ...updateData, updated_at: serverTimestamp() })
}

// Example of how to handle associations (EmployerProfile)
export const createEmployerProfile = async (firebase_uid, profileData) => {
  const employerProfileRef = doc(
    db,
    USERS_COLLECTION,
    firebase_uid,
    'profiles',
    'employer'
  )
  await setDoc(employerProfileRef, {
    ...profileData,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp()
  })
}
export const getEmployers = async () => {
  try {
    const employersQuery = query(
      collection(db, USERS_COLLECTION),
      where('role', '==', 'employer')
    )

    console.log('Fetching employers...')
    const employersSnapshot = await getDocs(employersQuery)
    console.log(`Found ${employersSnapshot.size} employers`)

    const employers = []

    for (const docSnapshot of employersSnapshot.docs) {
      const userData = docSnapshot.data()
      console.log(
        `Processing employer: ${userData.company_name}`,
        'User Data:',
        userData
      )

      try {
        // Get employer profile
        const profileRef = doc(
          db,
          USERS_COLLECTION,
          docSnapshot.id,
          'profiles',
          'employer'
        )
        const profileSnap = await getDoc(profileRef)
        const profileData = profileSnap.exists() ? profileSnap.data() : {}
        console.log('Profile data:', profileData)

        // Try both the document ID and the employer_id from user data
        const employerId = userData.employer_id || docSnapshot.id
        console.log('Checking job postings for IDs:', {
          docId: docSnapshot.id,
          employerId: employerId
        })

        // Query using OR condition for both possible IDs
        const jobsQuery = query(
          collection(db, 'jobPostings'),
          where('employer_id', 'in', [docSnapshot.id, employerId]),
          where('status', '==', 'active')
        )

        console.log('Fetching job postings for:', employerId)
        const jobsSnapshot = await getDocs(jobsQuery)

        // Log all found job postings for debugging
        jobsSnapshot.docs.forEach((jobDoc) => {
          console.log('Found job posting:', jobDoc.data())
        })

        const openRoles = jobsSnapshot.size
        console.log(
          `Found ${openRoles} open roles for ${userData.company_name}`
        )

        // Only include if profile is complete or has open roles
        if (profileData.intake_completed || openRoles > 0) {
          employers.push({
            id: docSnapshot.id,
            name: userData.company_name || 'Company Name Not Set',
            logo: profileData.company_logo_url || null,
            website: profileData.company_website || null,
            openRoles: openRoles,
            employerId: employerId // Add this for debugging
          })
          console.log(`Added ${userData.company_name} with ${openRoles} roles`)
        }
      } catch (profileError) {
        console.error(
          'Error processing employer:',
          docSnapshot.id,
          profileError
        )
      }
    }

    console.log('Final employers list:', employers)
    return employers.sort((a, b) => b.openRoles - a.openRoles)
  } catch (error) {
    console.error('Error in getEmployers:', error)
    return []
  }
}

export async function getJobSeekers() {
  try {
    // Query users with role 'jobseeker'
    const jobSeekersQuery = query(
      collection(db, 'users'),
      where('role', '==', 'jobseeker')
    )

    const jobSeekersSnapshot = await getDocs(jobSeekersQuery)

    const jobSeekers = []

    for (const docSnapshot of jobSeekersSnapshot.docs) {
      const userData = docSnapshot.data()

      // Add the basic user data without trying to access the profile
      jobSeekers.push({
        id: docSnapshot.id,
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
        created_at: userData.created_at || null,
        updated_at: userData.updated_at || null,
        role: userData.role || 'jobseeker'
      })
    }

    return jobSeekers
  } catch (error) {
    console.error('Error in getJobSeekers:', error)
    return []
  }
}
