import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Search, Save, ChevronDown, ChevronUp, Heart } from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import { getEmployers } from '../models/User'
import { useNavigate } from 'react-router-dom'

import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../models/JobseekerProfile'
import { auth } from '../firebase' // Import Firebase auth hook

import { getEmployerProfile } from '../models/EmployerProfile'

// Constants
const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'E-Sports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}
const workPolicyOptions = ['In-Office', 'Remote', 'Hybrid']
const employeeRanges = [
  { label: '1-10', min: 1, max: 10 },
  { label: '11-50', min: 11, max: 50 },
  { label: '51-200', min: 51, max: 200 },
  { label: '201-500', min: 201, max: 500 },
  { label: '500+', min: 500, max: Infinity }
]

// Styled Components
const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #2d3748;
`

const SearchBar = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const Select = styled.select`
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 1rem;
  color: #4a5568;

  &:focus {
    outline: none;
    border-color: #4a5568;
  }
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #4a5568;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2d3748;
  }
`

const SearchButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #caaae1;

  &:hover {
    background-color: #3182ce;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
`

const SubcategorySection = styled.div`
  margin-left: 1.5rem;
  margin-top: 0.5rem;
`

const ClearFilterButton = styled.button`
  color: #4299e1;
  font-size: 0.875rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`

// Company list styled components
const CompanyList = styled.div`
  flex-grow: 1;
`

const CompanyListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`

const CompanyCard = styled.div`
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1.5rem;
  background-color: white;
  margin-bottom: 1.5rem;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`

const HeartIcon = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
`
const LoadingMessage = styled.div`
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #4a5568;
`

const ErrorMessage = styled.div`
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #e53e3e;
`

const CompanyInfo = styled.div`
  display: flex;
  gap: 1.5rem;
`

const LogoPlaceholder = styled.div`
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7fafc;
  border-radius: 0.375rem;
  color: #a0aec0;
  font-size: 0.875rem;
`

const CompanyLogo = styled.img`
  width: 6rem;
  height: 6rem;
  object-fit: contain;
  border-radius: 0.375rem;
  background-color: #f7fafc;
`

const CompanyDetails = styled.div`
  flex: 1;
`

const CompanyName = styled.h3`
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #2d3748;
`

const JobCount = styled.p`
  font-size: 0.875rem;
  color: #718096;
  margin-bottom: 0.75rem;
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const Tag = styled.span`
  background-color: #edf2f7;
  color: #4a5568;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
`

const PartnerBadge = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #9f7aea;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
`

const Sidebar = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: fit-content;
  position: sticky;
  top: 2rem;
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
`

const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #1a202c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease;

  &:hover {
    color: #caaae1;
  }

  svg {
    transition: transform 0.2s ease;
  }
`

const FilterSection = styled.div`
  margin-bottom: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
`

const FilterContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '300px' : '0')};
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  margin-left: 0.5rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7f7f7;
  }

  input[type='radio'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const CheckboxLabel = styled(RadioLabel)`
  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const FilterCount = styled.span`
  color: #718096;
  font-size: 0.75rem;
  margin-left: auto;
  background-color: #f7fafc;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
`

const ClearButton = styled.button`
  color: #caaae1;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 0.375rem;

  &:hover {
    background-color: #f7f7f7;
  }
`

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

export default function CompanySearch() {
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOption, setSortOption] = useState('recent')
  const [companies, setCompanies] = useState([])
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [selectedSubcategories, setSelectedSubcategories] = useState([])
  const [selectedEmployeeCount, setSelectedEmployeeCount] = useState('')
  const [selectedWorkPolicy, setSelectedWorkPolicy] = useState('')
  const [favorites, setFavorites] = useState([])
  const [viewFavorites, setViewFavorites] = useState(false)
  const [openFilters, setOpenFilters] = useState({
    industry: true,
    employeeCount: true,
    workPolicy: true
  })

  useEffect(() => {
    fetchCompanies()
  }, [])

  useEffect(() => {
    filterCompanies()
  }, [
    searchTerm,
    sortOption,
    companies,
    selectedIndustries,
    selectedSubcategories,
    selectedEmployeeCount,
    selectedWorkPolicy,
    favorites
  ])

  useEffect(() => {
    const userId = auth.currentUser?.uid
    if (userId) {
      fetchProfile(userId)
    }
  }, [])

  const fetchProfile = async (userId) => {
    try {
      const profile = await getJobseekerProfile(userId)
      setFavorites(Array.isArray(profile?.favorites) ? profile.favorites : [])
    } catch (error) {
      console.error('Error fetching profile:', error)
      setFavorites([])
    }
  }

  const handleCompanyClick = (companyId) => {
    navigate(`/employer-profile-view/${companyId}`)
  }

  const toggleFavorite = async (event, companyId) => {
    event.stopPropagation() // Prevent the card click event from firing
    const userId = auth.currentUser?.uid
    if (!userId) return

    const updatedFavorites = favorites.includes(companyId)
      ? favorites.filter((id) => id !== companyId)
      : [...favorites, companyId]

    setFavorites(updatedFavorites)
    await updateJobseekerProfile(userId, { favorites: updatedFavorites })
  }
  // Helper function to get employee range
  const getEmployeeRange = (count) => {
    if (!count) return null
    const numCount = parseInt(count)
    if (isNaN(numCount)) return null

    if (numCount <= 10) return '1-10'
    if (numCount <= 50) return '11-50'
    if (numCount <= 200) return '51-200'
    if (numCount <= 500) return '201-500'
    return '500+'
  }

  // Update getFilterCount to handle arrays
  const getFilterCount = (filterType, value) => {
    return companies.filter((company) => {
      switch (filterType) {
        case 'industry':
          return company.industries && company.industries.includes(value)
        case 'subcategory':
          return company.subIndustries && company.subIndustries.includes(value)
        case 'employeeCount':
          return company.employeeRange === value
        case 'workPolicy':
          return company.workPolicy === value
        default:
          return false
      }
    }).length
  }

  const toggleFilter = (filterName) => {
    setOpenFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName]
    }))
  }

  const clearFilters = () => {
    setSelectedIndustries([])
    setSelectedSubcategories([])
    setSelectedEmployeeCount('')
    setSelectedWorkPolicy('')
  }

  const handleIndustryChange = (industry) => {
    setSelectedIndustries((prev) => {
      const newIndustries = prev.includes(industry)
        ? prev.filter((i) => i !== industry)
        : [...prev, industry]

      // Remove subcategories that don't belong to selected industries
      setSelectedSubcategories((prev) =>
        prev.filter((sub) =>
          newIndustries.some((ind) => allSubcategories[ind]?.includes(sub))
        )
      )

      return newIndustries
    })
  }

  const fetchCompanies = async () => {
    try {
      setLoading(true)
      const employersData = await getEmployers()

      const companiesWithProfiles = await Promise.all(
        employersData.map(async (employer) => {
          try {
            const profile = await getEmployerProfile(employer.id)

            // Handle array values for industry and work policy
            const industries = Array.isArray(profile?.industry)
              ? profile.industry
              : profile?.industry
              ? [profile.industry]
              : []

            const subIndustries = Array.isArray(profile?.sub_industry)
              ? profile.sub_industry
              : profile?.sub_industry
              ? [profile.sub_industry]
              : []

            // Handle work policy exactly as it appears in the database
            const workPolicy = profile?.work_policy || 'Not specified'

            return {
              id: employer.id,
              name: employer.name || profile?.company_name || 'Unnamed Company',
              logo: profile?.company_logo_url || null,
              description:
                profile?.company_description ||
                employer.website ||
                'No description available',
              location:
                profile?.location ||
                employer.location ||
                'Location not specified',
              jobCount: profile?.number_of_open_jobs || employer.openRoles || 0,
              isArenaPartner: profile?.plan_type === 'partner' || false,
              isHiring:
                profile?.number_of_open_jobs > 0 || employer.openRoles > 0,
              workPolicy: workPolicy,
              website: profile?.company_website || profile?.website || null,
              industries: industries,
              subIndustries: subIndustries,
              employeeCount: profile?.full_time_employees || null,
              employeeRange: getEmployeeRange(profile?.full_time_employees),
              // Store raw data for debugging
              rawProfile: profile
            }
          } catch (error) {
            console.error(`Error processing employer ${employer.id}:`, error)
            return {
              id: employer.id,
              name: employer.name || 'Unnamed Company',
              description: employer.website || 'No description available',
              location: employer.location || 'Location not specified',
              jobCount: employer.openRoles || 0,
              isHiring: employer.openRoles > 0,
              industries: [],
              subIndustries: [],
              workPolicy: 'Not specified'
            }
          }
        })
      )
      const validCompanies = companiesWithProfiles.filter(Boolean)

      setCompanies(validCompanies)
      setFilteredCompanies(validCompanies)
      setError(null)
    } catch (err) {
      console.error('Error fetching companies:', err)
      setError('Failed to load companies. Please try again later.')
    } finally {
      setLoading(false)
    }
  }

  // Update filterCompanies to handle work policy
  const filterCompanies = () => {
    console.log('Filtering with criteria:', {
      selectedIndustries,
      selectedSubcategories,
      selectedWorkPolicy,
      searchTerm
    })

    let filtered = companies.filter((company) => {
      // Search term matching
      const matchesSearch =
        !searchTerm ||
        (company.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        (company.description || '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase())

      let filtered = companies.filter((company) => {
        // Add filter condition for favorites if viewFavorites is true
        if (viewFavorites && !favorites.includes(company.id)) return false
        // ... (other filter conditions)
      })
      setFilteredCompanies(filtered)
      // Industry matching
      const matchesIndustry =
        selectedIndustries.length === 0 ||
        (company.industries &&
          selectedIndustries.some((selected) =>
            company.industries.includes(selected)
          ))

      // Subcategory matching
      const matchesSubcategory =
        selectedSubcategories.length === 0 ||
        (company.subIndustries &&
          selectedSubcategories.some((selected) =>
            company.subIndustries.includes(selected)
          ))

      // Work policy matching
      const matchesWorkPolicy =
        !selectedWorkPolicy || company.workPolicy === selectedWorkPolicy

      const matches =
        matchesSearch &&
        matchesIndustry &&
        matchesSubcategory &&
        matchesWorkPolicy

      // Debug logging for non-matching companies
      if (!matches) {
        console.log('Company filtered out:', company.name, {
          workPolicy: company.workPolicy,
          selectedWorkPolicy,
          matchesWorkPolicy,
          matchesIndustry,
          matchesSubcategory,
          matchesSearch
        })
      }

      return matches
    })

    setFilteredCompanies(filtered)
  }

  const CompanyCardContent = ({ company }) => {
    const handleCardClick = () => {
      navigate(`/employer-profile-view/${company.id}`)
    }

    return (
      <CompanyCard onClick={handleCardClick}>
        <CompanyInfo>
          {company.logo ? (
            <CompanyLogo src={company.logo} alt={`${company.name} logo`} />
          ) : (
            <LogoPlaceholder>No Logo</LogoPlaceholder>
          )}
          <CompanyDetails>
            <CompanyName>{company.name}</CompanyName>
            <JobCount>
              {company.jobCount || 0} Jobs •{' '}
              {company.location || 'Location not specified'}
            </JobCount>
            <TagList>
              {company.industries &&
                company.industries.map((industry, index) => (
                  <Tag key={`industry-${index}`}>Industry: {industry}</Tag>
                ))}
              {company.subIndustries &&
                company.subIndustries.map((subIndustry, index) => (
                  <Tag key={`subindustry-${index}`}>Type: {subIndustry}</Tag>
                ))}
              {company.workPolicy && company.workPolicy !== 'Not specified' && (
                <Tag style={{ backgroundColor: '#E9D8FD', color: '#553C9A' }}>
                  {company.workPolicy}
                </Tag>
              )}
            </TagList>
          </CompanyDetails>
        </CompanyInfo>
        <HeartIcon onClick={(e) => toggleFavorite(e, company.id)}>
          <Heart
            size={24}
            fill={favorites.includes(company.id) ? '#caaae1' : 'none'}
            color={favorites.includes(company.id) ? '#caaae1' : '#718096'}
          />
        </HeartIcon>
      </CompanyCard>
    )
  }
  return (
    <PageWrapper>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>

      <MainContent>
        <Header>
          <Title>🏢 Browse Companies</Title>
        </Header>

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search by company name or keyword"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="recent">Most Recent Job Postings</option>
            <option value="alphabetical">Alphabetical</option>
          </Select>
          <SearchButton onClick={filterCompanies}>
            <Search size={18} />
            Search
          </SearchButton>
        </SearchBar>

        {loading ? (
          <LoadingMessage>Loading companies...</LoadingMessage>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <ContentWrapper>
            <Sidebar>
              <FilterHeader>
                <h3>Filters</h3>
                {(selectedIndustries.length > 0 ||
                  selectedSubcategories.length > 0 ||
                  selectedEmployeeCount ||
                  selectedWorkPolicy) && (
                  <ClearFilterButton onClick={clearFilters}>
                    Clear all
                  </ClearFilterButton>
                )}
              </FilterHeader>

              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('industry')}>
                  Industry
                  {openFilters.industry ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                {openFilters.industry &&
                  industries.map((industry) => (
                    <div key={industry}>
                      <CheckboxLabel>
                        <input
                          type="checkbox"
                          checked={selectedIndustries.includes(industry)}
                          onChange={() => handleIndustryChange(industry)}
                        />
                        {industry}
                        <FilterCount>
                          ({getFilterCount('industry', industry)})
                        </FilterCount>
                      </CheckboxLabel>

                      {selectedIndustries.includes(industry) && (
                        <SubcategorySection>
                          {allSubcategories[industry]?.map((subcategory) => (
                            <CheckboxLabel key={subcategory}>
                              <input
                                type="checkbox"
                                checked={selectedSubcategories.includes(
                                  subcategory
                                )}
                                onChange={() => {
                                  setSelectedSubcategories((prev) =>
                                    prev.includes(subcategory)
                                      ? prev.filter((s) => s !== subcategory)
                                      : [...prev, subcategory]
                                  )
                                }}
                              />
                              {subcategory}
                              <FilterCount>
                                ({getFilterCount('subcategory', subcategory)})
                              </FilterCount>
                            </CheckboxLabel>
                          ))}
                        </SubcategorySection>
                      )}
                    </div>
                  ))}
              </FilterSection>

              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('employeeCount')}>
                  Company Size
                  {openFilters.employeeCount ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                {openFilters.employeeCount &&
                  employeeRanges.map((range) => (
                    <RadioLabel key={range.label}>
                      <input
                        type="radio"
                        name="employeeCount"
                        checked={selectedEmployeeCount === range.label}
                        onChange={() => setSelectedEmployeeCount(range.label)}
                      />
                      {range.label}
                      <FilterCount>
                        ({getFilterCount('employeeCount', range.label)})
                      </FilterCount>
                    </RadioLabel>
                  ))}
              </FilterSection>

              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('workPolicy')}>
                  Work Policy
                  {openFilters.workPolicy ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.workPolicy}>
                  {workPolicyOptions.map((policy) => (
                    <RadioLabel key={policy}>
                      <input
                        type="radio"
                        name="workPolicy"
                        checked={selectedWorkPolicy === policy}
                        onChange={() => setSelectedWorkPolicy(policy)}
                      />
                      {policy}
                      <FilterCount>
                        ({getFilterCount('workPolicy', policy)})
                      </FilterCount>
                    </RadioLabel>
                  ))}
                  {selectedWorkPolicy && (
                    <Button
                      onClick={() => setSelectedWorkPolicy('')}
                      style={{ marginTop: '0.5rem', fontSize: '0.8rem' }}
                    >
                      Clear Work Policy Filter
                    </Button>
                  )}
                </FilterContent>
              </FilterSection>
            </Sidebar>

            <CompanyList>
              <CompanyListHeader>
                <h2>All Companies ({filteredCompanies.length})</h2>
              </CompanyListHeader>
              {filteredCompanies.map((company) => (
                <CompanyCard key={company.id}>
                  {company.isArenaPartner && (
                    <PartnerBadge>Arena Partner</PartnerBadge>
                  )}
                  <CompanyCardContent company={company} />
                </CompanyCard>
              ))}
            </CompanyList>
          </ContentWrapper>
        )}
      </MainContent>
    </PageWrapper>
  )
}
