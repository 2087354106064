// src/models/JobseekerProfile.js

import { db } from '../firebase'
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore'

const USERS_COLLECTION = 'users'
const PROFILE_SUBCOLLECTION = 'profiles'
const JOBSEEKER_PROFILE_DOC = 'jobseeker'

// Create a job seeker profile object structure
export const createJobseekerProfileObject = (profileData) => ({
  intake_completed: profileData.intake_completed || false,
  demographics_completed: profileData.demographics_completed || false,
  favorites: profileData.favorites || null,

  // Personal Info
  date_of_birth: profileData.date_of_birth || null,
  pronouns: profileData.pronouns || null,
  street_address: profileData.street_address || null,
  city: profileData.city || null,
  state: profileData.state || null,
  zip_code: profileData.zip_code || null,
  phone: profileData.phone || null,

  // Work History (including multiple employers)
  work_history: (profileData.work_history || []).map((job) => ({
    title: job.title || null,
    employer: job.employer || null,
    start_date: job.start_date || null,
    end_date: job.end_date || null,
    details: job.details || null
  })),

  // Athletic Status and History
  athlete_status: profileData.is_athlete === 'yes',
  athletic_history: (profileData.athletic_history || []).map((history) => ({
    sport: history.sport || null,
    league: history.league || null,
    team: history.team || null,
    position: history.position || null,
    start_date: history.start_date || null,
    end_date: history.end_date || null,
    achievements: history.achievements || []
  })),

  // Education
  highest_education: profileData.highest_education || null,
  education: (profileData.education_history || []).map((edu) => ({
    institution: edu.institution || null,
    degree_type: edu.degree_type || null,
    field_of_study: edu.field_of_study || null,
    date_of_graduation: edu.date_of_graduation || null
  })),

  // Experience and Level
  years_experience: profileData.years_experience || null,
  job_level: profileData.current_level || null,
  startup_experience: profileData.has_startup_experience === 'yes',

  // Preferences and Strengths
  top_strengths: profileData.strengths || [],
  preferred_telework_policy: profileData.work_preference || null,
  job_search_challenge: profileData.job_search_challenge || null,
  company_culture_preferences: profileData.culture_preferences || [],
  languages: profileData.languages || [],
  portfolio_profile_links: profileData.portfolio_profile_links || [],

  // Social Links
  linkedin: profileData.social_links?.linkedin || null,
  portfolio: profileData.social_links?.portfolio || null,
  github: profileData.social_links?.github || null,
  personal_website: profileData.social_links?.additional1 || null,
  additional_website_2: profileData.social_links?.additional2 || null,

  // Files
  profile_picture: profileData.profile_picture || null,
  resume_url: profileData.resume_url || null,

  // Additional Optional Fields
  open_to_work: profileData.open_to_work || null,
  gender: profileData.gender || null,
  race: profileData.race || null,
  sexual_orientation: profileData.sexual_orientation || null,
  veteran_status: profileData.veteran_status || null,
  disability_status: profileData.disability_status || null,
  accommodation_needed: profileData.accommodation_needed || null,
  accommodation_details: profileData.accommodation_details || null,
  block_current_employer: profileData.block_current_employer || false,
  starting_salary: profileData.starting_salary || null,
  ending_salary: profileData.ending_salary || null,
  company_ratings: profileData.company_ratings || null,
  jobs_applied: profileData.jobs_applied || [],
  videos: profileData.videos || [],

  // Plan Type
  plan_type: profileData.plan_type || 'freetrial',

  // Timestamps
  created_at: profileData.created_at || serverTimestamp(),
  updated_at: profileData.updated_at || serverTimestamp()
})

// Create or Update Jobseeker Profile
export const createOrUpdateJobseekerProfile = async (userId, profileData) => {
  try {
    console.log('Creating/Updating Jobseeker Profile with userId:', userId)
    console.log('Profile Data being sent:', profileData)

    const profileRef = doc(
      db,
      USERS_COLLECTION,
      userId,
      PROFILE_SUBCOLLECTION,
      JOBSEEKER_PROFILE_DOC
    )

    await setDoc(
      profileRef,
      {
        ...profileData,
        job_level: profileData.job_level || null,
        preferred_telework_policy:
          profileData.preferred_telework_policy || null,
        top_strengths: profileData.top_strengths || []
      },
      { merge: true }
    )

    const savedDoc = await getDoc(profileRef)
    const savedData = savedDoc.data()
    console.log('Profile successfully created/updated:', savedData)

    return savedData
  } catch (error) {
    console.error('Error in createOrUpdateJobseekerProfile:', error.message)
    throw error
  }
}

// Get a Jobseeker Profile by userId
// Get a Jobseeker Profile by userId
export const getJobseekerProfile = async (userId) => {
  try {
    const profileRef = doc(
      db,
      USERS_COLLECTION,
      userId,
      PROFILE_SUBCOLLECTION,
      JOBSEEKER_PROFILE_DOC
    )
    const profileSnap = await getDoc(profileRef)
    if (profileSnap.exists()) {
      return profileSnap.data()
    } else {
      console.warn(`No profile found for user: ${userId}`)
      return null
    }
  } catch (error) {
    console.error('Error fetching jobseeker profile:', error.message)
    throw error
  }
}

// Update Jobseeker Profile fields
export const updateJobseekerProfile = async (userId, updateData) => {
  try {
    if (!userId) {
      throw new Error('User ID is required for updating the profile')
    }

    const profileRef = doc(
      db,
      USERS_COLLECTION,
      userId,
      PROFILE_SUBCOLLECTION,
      JOBSEEKER_PROFILE_DOC
    )

    await updateDoc(profileRef, {
      ...updateData,
      updated_at: serverTimestamp()
    })
    console.log(`Jobseeker profile for user ${userId} successfully updated`)
  } catch (error) {
    console.error('Error in updateJobseekerProfile:', error.message)
    throw error
  }
}

export const getUserByFirebaseUid = async (firebase_uid) => {
  const userRef = doc(db, USERS_COLLECTION, firebase_uid)
  const userSnap = await getDoc(userRef)

  if (userSnap.exists()) {
    const userData = { id: userSnap.id, ...userSnap.data() }

    if (userData.role === 'jobseeker') {
      const jobseekerProfileRef = doc(
        db,
        USERS_COLLECTION,
        firebase_uid,
        PROFILE_SUBCOLLECTION,
        JOBSEEKER_PROFILE_DOC
      )
      const jobseekerProfileSnap = await getDoc(jobseekerProfileRef)

      if (jobseekerProfileSnap.exists()) {
        const jobseekerProfileData = jobseekerProfileSnap.data()
        return {
          ...userData,
          jobseekerProfile: jobseekerProfileData
        }
      }
    }

    return userData
  } else {
    return null
  }
}
// // Get all Job Seekers with optional keyword search
// export const getAllJobSeekers = async (keyword = '') => {
//   const jobSeekers = []
//   try {
//     const usersCollectionRef = collection(db, USERS_COLLECTION)
//     const jobseekerQuery = query(
//       usersCollectionRef,
//       where('role', '==', 'jobseeker')
//     )

//     const querySnapshot = await getDocs(jobseekerQuery)

//     for (const userDoc of querySnapshot.docs) {
//       const userData = userDoc.data()

//       // Get the jobseeker profile from the subcollection
//       const profileRef = doc(
//         db,
//         USERS_COLLECTION,
//         userDoc.id,
//         PROFILE_SUBCOLLECTION,
//         JOBSEEKER_PROFILE_DOC
//       )
//       const profileSnap = await getDoc(profileRef)
//       const profileData = profileSnap.exists() ? profileSnap.data() : {}

//       // Combine user and profile data
//       const jobseeker = {
//         id: userDoc.id,
//         name: `${userData.first_name} ${userData.last_name}`.trim(),
//         email: userData.email,
//         pronouns: profileData.pronouns,
//         location: `${profileData.city}, ${profileData.state}`,
//         current_employer: profileData.current_employer,
//         current_title: profileData.current_title,
//         work_experience: profileData.years_experience,
//         current_level: profileData.job_level,
//         willing_to_relocate: profileData.open_to_work?.includes(
//           'willing_to_relocate'
//         ),
//         startup_experience: profileData.startup_experience,
//         languages: profileData.languages || [],
//         strengths: profileData.top_strengths || [],
//         athlete_status: profileData.athlete_status,
//         veteran_status: profileData.veteran_status,
//         gender: profileData.gender,
//         race: profileData.race,
//         education: (profileData.education || []).map((edu) => ({
//           university: edu.institution,
//           degree: edu.degree_type,
//           major: edu.field_of_study
//         }))
//       }

//       // Perform keyword search on jobseeker profile fields
//       if (keyword) {
//         const searchFields = [
//           jobseeker.name,
//           jobseeker.email,
//           jobseeker.pronouns,
//           jobseeker.location,
//           jobseeker.current_employer,
//           jobseeker.current_title,
//           jobseeker.languages.join(' '),
//           jobseeker.strengths.join(' '),
//           jobseeker.education
//             .map((edu) => `${edu.university} ${edu.degree} ${edu.major}`)
//             .join(' ')
//         ]

//         const matchesKeyword = searchFields.some(
//           (field) =>
//             field && field.toLowerCase().includes(keyword.toLowerCase())
//         )
//         if (!matchesKeyword) {
//           continue // Skip this jobseeker if no match
//         }
//       }

//       // Add the matching jobseeker to the list
//       jobSeekers.push(jobseeker)
//     }
//   } catch (error) {
//     console.error('Error fetching job seekers:', error.message)
//     throw error
//   }

//   return jobSeekers
// }
export const getAllJobSeekerProfiles = async (userIds) => {
  const profiles = {}
  const BATCH_SIZE = 30 // Firestore's limit for 'in' queries

  try {
    // Split userIds into batches of 30
    for (let i = 0; i < userIds.length; i += BATCH_SIZE) {
      const batch = userIds.slice(i, i + BATCH_SIZE)

      // Process each batch concurrently
      const batchPromises = batch.map(async (userId) => {
        try {
          const profileRef = doc(db, 'users', userId, 'profiles', 'jobseeker')
          const profileSnap = await getDoc(profileRef)

          if (profileSnap.exists()) {
            const data = profileSnap.data()

            profiles[userId] = {
              pronouns: data.pronouns || null,
              city: data.city || null,
              state: data.state || null,
              current_employer:
                data.current_employer ||
                (data.work_history && data.work_history[0]?.employer) ||
                null,
              current_title:
                data.current_title ||
                (data.work_history && data.work_history[0]?.title) ||
                null,
              years_experience: data.years_experience || null,
              work_history: data.work_history || [],
              education:
                data.education?.map((edu) => ({
                  university: edu.institution || edu.university || null,
                  degree: edu.degree_type || edu.degree || null,
                  major: edu.field_of_study || edu.major || null,
                  is_hbcu: edu.is_hbcu || false
                })) || [],
              top_strengths: data.top_strengths || [],
              languages: data.languages || [],
              athlete_status: data.athlete_status || null,
              startup_experience: data.startup_experience || false,
              open_to_work: data.open_to_work || [],
              favorited_companies: data.favorited_companies || [],
              has_profile: true,
              veteran_status: data.veteran_status || null,
              favorites: data.favorites || [],
              gender: data.gender || null,
              race: data.race || [],
              profile_picture: data.profile_picture || null,
              willing_to_relocate:
                data.open_to_work?.includes('willing_to_relocate') || false,
              location:
                data.city && data.state ? `${data.city}, ${data.state}` : null
            }
          }
        } catch (error) {
          if (error.code === 'permission-denied') {
            console.warn(`Permission denied for user ${userId}`)
          } else {
            console.error(`Error fetching profile for user ${userId}:`, error)
          }
        }
      })

      // Wait for current batch to complete
      await Promise.all(batchPromises)
    }

    return profiles
  } catch (error) {
    console.error('Error in getAllJobSeekerProfiles:', error)
    return profiles
  }
}
