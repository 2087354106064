// firebase.js

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyBTMFYCmfaveGvVJtR2CN6ArYiWIJuMPf4',
  authDomain: 'msbc-db053.firebaseapp.com',
  projectId: 'msbc-db053',
  storageBucket: 'msbc-db053.appspot.com',
  messagingSenderId: '752925851138',
  appId: '1:752925851138:web:cea26901e71e101282b11b',
  measurementId: 'G-TTV52MLXSG'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase services
const analytics = getAnalytics(app)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const functions = getFunctions(app)

export { app, analytics, auth, db, storage, functions }
