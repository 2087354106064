import React, { useState, useEffect } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components'
import {
  Inbox,
  Search,
  Building,
  FileCheck,
  MessageSquare,
  Settings,
  LogOut,
  User,
  QrCodeIcon,
  HelpCircle
} from 'lucide-react'
import { auth } from '../firebase' // Adjust the path if needed
import { useNavigate, Link } from 'react-router-dom'

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 16rem;
  background-color: #f3f4f6;
  border-right: 1px solid #e5e7eb;

  @media (max-width: 768px) {
    width: 4rem;
  }
`

const LogoContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
`

const Logo = styled.img`
  width: 120px;
  height: 100px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

const Nav = styled.nav`
  flex: 1;
`

const NavButton = styled(Link)`
  display: flex;
  width: 80%;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e5e7eb;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
`

const IconWrapper = styled.span`
  margin-right: 0.75rem;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

const ButtonText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`

const BottomSection = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  color: #dc2626;
  background: none;
  border: none;
  padding: 0.5rem 1rem;
  width: 80%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #fee2e2;
    color: #b91c1c;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
`

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const PopupContent = styled.div`
  background-color: white;
  padding: 2rem 1rem;
  padding-top: 0rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

const QRCloseButton = styled.button`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  background-color: white;
  border-radius: 8px;
  border: none;
  color: #718096;
  cursor: pointer;

  &:hover {
    background-color: #f3f4f6;
  }
`

const CloseQRNavSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 0.5rem;
`

const HelpDropDown = styled.div`
  position: relative;
`

const HelpDropDownContent = styled.div`
  position: absolute;
  background-color: #f1f1f1;
  width: 80%;
  padding: 0.5rem 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;

  @media (max-width: 768px) {
    width: 200px;
    left: 100%;
    top: 0;
  }
`

const DropDownLink = styled.a`
  padding: 10px 0px;
  display: block;
  color: #4a90e2;
  font-weight: 600;
  font-size: 0.87em;
  text-decoration: none;
  transition: color 0.2s;
`

const LinkText = styled.span`
  &:hover {
    color: #63b3ed;
    text-decoration: underline;
  }
`

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const Tooltip = styled.div`
  position: absolute;
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
  padding: 8px 16px;
  background-color: #18181b;
  color: white;
  border-radius: 8px;
  font-size: 0.875rem;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.show ? 'block' : 'none')};

  @media (max-width: 768px) {
    left: auto;
    right: calc(100% + 12px);
    font-size: 0.75rem;
  }
`

const DisabledNavButton = styled(NavButton)`
  color: #a1a1aa;

  &:hover {
    background-color: transparent;
    cursor: default;
  }
`

function EmployerNav() {
  //   const [isQRPopupOpen, setIsQRPopupOpen] = useState(false)
  const [isHelpDropDownOpen, setIsHelpDropDownOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const navigate = useNavigate()
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  //   const handleQR = () => {
  //     setIsQRPopupOpen((prev) => !prev)
  //   }

  const handleHelpDropDown = () => {
    setIsHelpDropDownOpen((prev) => !prev)
  }

  const handleLogout = async () => {
    try {
      await auth.signOut()
      navigate('/login')
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  return (
    <NavContainer>
      <LogoContainer>
        <Logo src="/images/logo-black.png" alt="Company Logo" />
      </LogoContainer>
      <Nav>
        <NavButton to="/employer-dashboard">
          <IconWrapper>
            <Inbox size={20} />
          </IconWrapper>
          <ButtonText>Dashboard</ButtonText>
        </NavButton>
        <NavButton to="/employer-profile">
          <IconWrapper>
            <User size={20} />
          </IconWrapper>
          <ButtonText>Profile</ButtonText>
        </NavButton>
        <NavButton to="/candidate-sourcing">
          <IconWrapper>
            <Search size={20} />
          </IconWrapper>
          <ButtonText>Search Candidates</ButtonText>
        </NavButton>

        <NavButton to="/job-postings">
          <IconWrapper>
            <Building size={20} />
          </IconWrapper>
          <ButtonText>Jobs</ButtonText>
        </NavButton>
      </Nav>
      <BottomSection>
        <HelpDropDown>
          <NavButton onClick={handleHelpDropDown}>
            <IconWrapper>
              <HelpCircle size={20} />
            </IconWrapper>
            <ButtonText>Need Help?</ButtonText>
          </NavButton>
          {isHelpDropDownOpen && (
            <HelpDropDownContent>
              <DropDownLink href="https://calendly.com/adriene-arena/arena-demo">
                📞 <LinkText>Book a demo</LinkText>
              </DropDownLink>
              <DropDownLink href="https://www.notion.so/10be576b478b80bd9cccf2f67671881a?pvs=21">
                📘 <LinkText>Help & quick start guide</LinkText>
              </DropDownLink>
              <DropDownLink href="mailto:support@arenatalent.com">
                <LinkText>Email us</LinkText>
              </DropDownLink>
            </HelpDropDownContent>
          )}
        </HelpDropDown>

        {/* <NavButton onClick={handleQR}>
          <IconWrapper>
            <QrCodeIcon size={20} />
          </IconWrapper>
          <ButtonText>QR Code</ButtonText>
        </NavButton> */}
        <NavButton to="/account">
          <IconWrapper>
            <Settings size={20} />
          </IconWrapper>
          <ButtonText>Account</ButtonText>
        </NavButton>
        <LogoutButton onClick={handleLogout}>
          <IconWrapper>
            <LogOut size={20} />
          </IconWrapper>
          <ButtonText>Logout</ButtonText>
        </LogoutButton>

        {/* {isQRPopupOpen && (
          <PopupOverlay>
            <PopupContent>
              <CloseQRNavSection>
                <QRCloseButton onClick={handleQR}>x</QRCloseButton>
              </CloseQRNavSection>
              <QRCodeSVG value="https://reactjs.org/" size={254} />
            </PopupContent>
          </PopupOverlay>
        )} */}
      </BottomSection>
    </NavContainer>
  )
}

export default EmployerNav
