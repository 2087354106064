import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { updateJobseekerProfile } from '../models/JobseekerProfile'
import JobSeekerNav from './JobSeekerNav'
import styled, { createGlobalStyle } from 'styled-components'

// Constants
const raceOptions = [
  'Asian',
  'Black',
  'Hispanic',
  'Middle Eastern/Arab',
  'Native American',
  'Pacific Islander',
  'White',
  'Prefer not to say'
]

const genderOptions = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender Male',
  'Transgender Female',
  'Prefer not to say'
]

const veteranStatusOptions = [
  'Veteran',
  'Active Duty',
  'Reservist',
  'Not A Veteran',
  'Prefer not to say'
]

const sexualOrientationOptions = [
  'Heterosexual/Straight',
  'Gay',
  'Lesbian',
  'Bisexual',
  'Pansexual',
  'Asexual',
  'Queer',
  'Prefer not to say'
]

const disabilityOptions = ['Yes', 'No', 'Prefer not to say']

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

  body {
    font-family: 'Open Sans', sans-serif;
  }
`

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#CAAAE1',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

// Layout Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${softColors.background};
  color: ${softColors.text};
  font-family: 'Open Sans', sans-serif;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
`

const FormCard = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: ${softColors.card};
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

// Typography Components
const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${softColors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
`

const IntroText = styled.div`
  color: ${softColors.text};
  line-height: 1.6;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: ${softColors.background};
  border-radius: 0.5rem;

  strong {
    color: ${softColors.primary};
  }
`

const Note = styled.p`
  color: ${softColors.textLight};
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 2rem;
`

// Form Components
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`

const AnimatedFormGroup = styled(FormGroup)`
  max-height: ${(props) => (props.isVisible ? '500px' : '0')};
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin-bottom: ${(props) => (props.isVisible ? '1.5rem' : '0')};
`

const Section = styled.div`
  margin-bottom: 2rem;
`

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: ${softColors.text};
  font-size: 0.9rem;
`

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: all 0.2s;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.border};
  }

  input[type='radio'] {
    margin-right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    accent-color: ${softColors.primary};
  }
`
// Progress Components
const ProgressBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: ${softColors.border};
  border-radius: 1rem;
  margin: 2rem 0;
  overflow: hidden;
`

const ProgressFill = styled.div`
  height: 100%;
  width: ${(props) => (props.progress / 3) * 100}%;
  background-color: ${softColors.primary};
  transition: width 0.3s ease;
`

// Button Components
const Button = styled.button`
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: ${softColors.primary};
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.icontext};
    transform: translateY(-1px);
  }

  &:disabled {
    background-color: ${softColors.border};
    cursor: not-allowed;
    transform: none;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
`
const MultiSelectContainer = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: ${softColors.card};
  max-height: 200px;
  overflow-y: auto;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: ${softColors.border};
  }

  input[type='checkbox'] {
    margin-right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
    accent-color: ${softColors.primary};
  }
`

const SelectedCount = styled.span`
  color: ${softColors.textLight};
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: block;
`
const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: all 0.2s;
  resize: vertical;
  min-height: 100px;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }

  &::placeholder {
    color: ${softColors.textLight};
  }
`
const DemographicIntake = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [formData, setFormData] = useState({
    gender: '',
    race: [], // Changed from string to array
    sexual_orientation: '',
    veteran_status: '',
    disability_status: '',
    accommodation_needed: '',
    accommodation_details: '', // Add this new field
    opt_out: false
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const handleRaceChange = (option) => {
    setFormData((prev) => {
      // If selecting "Prefer not to say", clear all other selections
      if (option === 'Prefer not to say') {
        return {
          ...prev,
          race: prev.race.includes(option) ? [] : [option]
        }
      }

      // If selecting another option while "Prefer not to say" is selected,
      // remove "Prefer not to say"
      let newRace = prev.race.includes(option)
        ? prev.race.filter((item) => item !== option)
        : [...prev.race, option]

      // Remove "Prefer not to say" if selecting other options
      newRace = newRace.filter(
        (item) => option === 'Prefer not to say' || item !== 'Prefer not to say'
      )

      return {
        ...prev,
        race: newRace
      }
    })
  }

  const renderStep1 = () => (
    <>
      <Title>Demographic Information</Title>
      <IntroText>
        Why we ask these demographic questions: At Arena, we are committed to
        promoting equity and diversity within the sports, media, and
        entertainment industries. By collecting this demographic information, we
        generate valuable insights that help employers understand the
        composition of their applicant pools. This data enables organizations to
        assess and improve their diversity and inclusion efforts.
        <br />
        <br />
        By providing this information, you're not just helping yourself - you're
        empowering employers to assess and enhance their diversity and inclusion
        efforts, potentially creating better work environments for all.Your
        input is valuable in our mission to create more equitable opportunities
        in sports, media, and entertainment.
        <br />
        <br />
        <strong>Note:</strong> Individual responses may be used by employers to
        better understand their candidate pool and improve their diversity and
        inclusion efforts. All questions are optional with an option to opt-out
        if preferred. If you choose to opt-out, it will not hurt your chances of
        getting seen by recruiters.
      </IntroText>
      <FormGroup>
        <Label>
          Would you like to proceed with answering demographic questions?
        </Label>
        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="proceed"
              value="yes"
              checked={!formData.opt_out}
              onChange={() =>
                setFormData((prev) => ({ ...prev, opt_out: false }))
              }
            />
            Yes, I'll answer the questions
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="proceed"
              value="no"
              checked={formData.opt_out}
              onChange={() =>
                setFormData((prev) => ({ ...prev, opt_out: true }))
              }
            />
            No, I prefer to opt out
          </RadioLabel>
        </RadioGroup>
      </FormGroup>
    </>
  )

  const renderStep2 = () => (
    <>
      <Title>Personal Demographics</Title>

      <Section>
        <FormGroup>
          <Label>What is your gender identity?</Label>
          <Select
            value={formData.gender}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, gender: e.target.value }))
            }
          >
            <option value="">Select gender identity</option>
            {genderOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>
            What is your racial/ethnic background? (Select all that apply)
          </Label>
          <MultiSelectContainer>
            {raceOptions.map((option) => (
              <CheckboxLabel key={option}>
                <input
                  type="checkbox"
                  checked={formData.race.includes(option)}
                  onChange={() => handleRaceChange(option)}
                  // If "Prefer not to say" is selected, disable other options
                  disabled={
                    option !== 'Prefer not to say' &&
                    formData.race.includes('Prefer not to say')
                  }
                />
                {option}
              </CheckboxLabel>
            ))}
          </MultiSelectContainer>
          <SelectedCount>{formData.race.length} selected</SelectedCount>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>What is your sexual orientation?</Label>
          <Select
            value={formData.sexual_orientation}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                sexual_orientation: e.target.value
              }))
            }
          >
            <option value="">Select sexual orientation</option>
            {sexualOrientationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>
      </Section>
    </>
  )
  const renderStep3 = () => (
    <>
      <Title>Additional Information</Title>

      <Section>
        <FormGroup>
          <Label>What is your veteran status?</Label>
          <Select
            value={formData.veteran_status}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                veteran_status: e.target.value
              }))
            }
          >
            <option value="">Select veteran status</option>
            {veteranStatusOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>Do you identify as a person with a disability?</Label>
          <Select
            value={formData.disability_status}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                disability_status: e.target.value,
                // Reset accommodation fields if disability status changes to No
                ...(e.target.value !== 'Yes' && {
                  accommodation_needed: '',
                  accommodation_details: ''
                })
              }))
            }
          >
            <option value="">Select option</option>
            {disabilityOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>

        <AnimatedFormGroup isVisible={formData.disability_status === 'Yes'}>
          <Label>
            Do you require any accommodations in an interview process
          </Label>
          <RadioGroup>
            <RadioLabel>
              <input
                type="radio"
                name="accommodation"
                value="yes"
                checked={formData.accommodation_needed === 'yes'}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    accommodation_needed: e.target.value,
                    // Reset details if changing to no
                    ...(e.target.value === 'no' && {
                      accommodation_details: ''
                    })
                  }))
                }
              />
              Yes
            </RadioLabel>
            <RadioLabel>
              <input
                type="radio"
                name="accommodation"
                value="no"
                checked={formData.accommodation_needed === 'no'}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    accommodation_needed: e.target.value,
                    accommodation_details: '' // Clear details when selecting no
                  }))
                }
              />
              No
            </RadioLabel>
          </RadioGroup>

          <AnimatedFormGroup
            isVisible={formData.accommodation_needed === 'yes'}
          >
            <Label>Please describe any accommodations you need:</Label>
            <TextArea
              value={formData.accommodation_details || ''}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  accommodation_details: e.target.value
                }))
              }
              placeholder="Please describe any specific accommodations that would help you in an interview process (ie. accessible building entrance and room, specialized communication tools)"
              rows={4}
            />
          </AnimatedFormGroup>
        </AnimatedFormGroup>
      </Section>
    </>
  )

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!formData.opt_out && currentStep < 3) {
      setCurrentStep((prev) => prev + 1)
      return
    }

    try {
      setIsLoading(true)
      setError(null)

      const userId = auth.currentUser.uid

      await updateJobseekerProfile(userId, {
        ...formData,
        demographics_completed: true
      })

      navigate('/jobseeker-dashboard')
    } catch (error) {
      console.error('Error updating profile:', error)
      setError('There was an error saving your information. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <GlobalStyle />
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>
      <MainContent>
        <FormCard>
          <ProgressBar>
            <ProgressFill progress={currentStep} />
          </ProgressBar>
          <Note>Step {currentStep} of 3</Note>

          <Form onSubmit={handleSubmit}>
            {formData.opt_out ? (
              <>
                <Title>Demographics Opted Out</Title>
                <IntroText>
                  You have chosen to opt out of providing demographic
                  information. This will not affect your ability to use Arena or
                  be seen by recruiters.
                </IntroText>
              </>
            ) : (
              <>
                {currentStep === 1 && renderStep1()}
                {currentStep === 2 && renderStep2()}
                {currentStep === 3 && renderStep3()}
              </>
            )}

            {error && (
              <IntroText
                style={{ color: softColors.danger, marginTop: '1rem' }}
              >
                {error}
              </IntroText>
            )}

            <ButtonGroup>
              {currentStep > 1 && !formData.opt_out && (
                <Button
                  type="button"
                  onClick={() => setCurrentStep((prev) => prev - 1)}
                >
                  Previous
                </Button>
              )}
              <Button type="submit" disabled={isLoading}>
                {formData.opt_out
                  ? 'Submit'
                  : currentStep === 3
                  ? isLoading
                    ? 'Submitting...'
                    : 'Submit'
                  : 'Next'}
              </Button>
            </ButtonGroup>
          </Form>
        </FormCard>
      </MainContent>
    </Container>
  )
}

export default DemographicIntake
