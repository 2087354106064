// JobseekerIntakeForm.js
import React, { useState } from 'react'
import { ChevronDown, ChevronUp, Upload, Info } from 'lucide-react'
import styled, { createGlobalStyle } from 'styled-components'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { serverTimestamp } from 'firebase/firestore'
import { storage, auth } from '../firebase'
import { useNavigate } from 'react-router-dom'
import { createOrUpdateJobseekerProfile } from '../models/JobseekerProfile'
import JobSeekerNav from './JobSeekerNav'

// Colors
const colors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#CAAAE1',
  primaryHover: '#C859FF',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  error: '#fc8181'
}

// Data Constants
const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]

const pronounOptions = [
  'he/him',
  'she/her',
  'they/them',
  'prefer not to say',
  'other'
]

const educationLevels = [
  'Some high school or less',
  'High school diploma or equivalent',
  'Vocational certificate or credential',
  'Some college',
  'Associates degree',
  'Bachelors degree',
  'Masters degree',
  'PhD',
  'MD, OD or related',
  'DDS, DMD or related',
  'JD',
  'Other'
]

const experienceLevels = [
  'None/Student',
  '1-2 years',
  '3-5 years',
  '6-10 years',
  '11-15 years',
  '16+ years'
]

const currentLevels = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]

const leagues = [
  'NCAA Division I (National Collegiate Athletics Association)',
  'NCAA Division II (National Collegiate Athletics Association)',
  'NCAA Division III (National Collegiate Athletics Association)',
  'NAIA (National Association of Intercollegiate Athletics)',
  'NJCAA (National Junior College Athletic Association)',
  'AHL (American Hockey League)',
  'APP (Association of Pickleball Professionals)',
  'ATP (Association of Tennis Professionals)',
  'AUDL (American Ultimate Disc League)',
  'ECHL (East Coast Hockey League)',
  'IFL (Indoor Football League)',
  'LOVB (League One Volleyball)',
  'LPGA (Ladies Professional Golf Association)',
  'MLB (Major League Baseball)',
  'MLL (Major League Lacrosse)',
  'MLP (Major League Pickleball)',
  'MLR (Major League Rugby)',
  'MLS (Major League Soccer)',
  'NBA (National Basketball Association)',
  'G League (NBA G League)',
  'NFL (National Football League)',
  'NHL (National Hockey League)',
  'NISA (National Independent Soccer Association)',
  'NLL (National Lacrosse League)',
  'NPF (National Pro Fastpitch)',
  'NPL (National Pickleball League)',
  "NWSL (National Women's Soccer League)",
  'PBA (Professional Bowlers Association)',
  'PBR (Professional Bull Riders)',
  'PGA (Professional Golf Association)',
  'PHF (Premier Hockey Federation)',
  'PLL (Premier Lacrosse League)',
  'PPA (Professional Pickleball Association)',
  'PVL (Pro Volleyball League)',
  "PWBA (Professional Women's Bowling Association)",
  'USL (United Soccer League)',
  "WFA (Women's Football Alliance)",
  "WNBA (Women's National Basketball Association)",
  "WPLL (Women's Professional Lacrosse League)",
  "WTA (Women's Tennis Association)",
  'XFL (Xtreme Football League)',
  'Olympic Team Member',
  'Olympic Trials Qualifier',
  'Olympic National Team Member',
  'Paralympic Team Member',
  'Paralympic National Team Member',
  'Paralympic Trials Qualifier',
  'Olympic Junior National Team',
  'Youth Olympic Games',
  'Other'
]

const achievements = [
  'All-Star',
  'MVP',
  'Championship',
  'Olympic Medalist',
  'All-League Selection',
  'All-American',
  'Conference Player of the Year',
  'Rookie of the Year',
  'Hall of Fame',
  'Other'
]

const strengths = [
  'Communication',
  'Leadership',
  'Problem Solving',
  'Teamwork',
  'Adaptability',
  'Creativity',
  'Time Management',
  'Critical Thinking',
  'Attention to Detail',
  'Emotional Intelligence',
  'Strategic Planning',
  'Networking',
  'Decision Making',
  'Conflict Resolution',
  'Analytical Skills',
  'Project Management',
  'Negotiation',
  'Public Speaking',
  'Customer Service',
  'Technical Proficiency',
  'Data Analysis',
  'Research',
  'Writing',
  'Multitasking',
  'Sales',
  'Marketing',
  'Budgeting',
  'Coaching',
  'Innovation',
  'Interpersonal Skills'
].sort()

const workPreferences = ['Fully Remote', 'In-Office', 'Hybrid']

const jobSearchChallenges = [
  {
    value: 'figuring_out_fit',
    label: 'Figuring out what jobs I would be a top candidate for'
  },
  { value: 'ghosting', label: 'Ghosting/not hearing back from recruiters' },
  { value: 'lack_of_jobs', label: 'Hard to find jobs/lack of jobs' },
  { value: 'no_offers', label: 'Interviewing but not getting offers' },
  { value: 'salary_transparency', label: 'Lack of salary transparency' },
  { value: 'networking', label: 'Network/getting in front of people' },
  {
    value: 'time_consuming',
    label: 'Time-consuming process/not having enough time'
  }
]

const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'E-Sports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}
const culturePreferenceOptions = [
  'Collaborative',
  'Innovative',
  'Inclusive',
  'Transparent',
  'Flexible',
  'Diverse',
  'Growth-Oriented',
  'Team-Driven',
  'Results-Focused',
  'Customer-Centric',
  'Work-Life Balance',
  'Empathetic',
  'Fast-Paced',
  'Accountability',
  'Autonomous',
  'Creative',
  'Supportive',
  'Entrepreneurial',
  'Sustainability-Focused',
  'High-Performance',
  'Trustworthy',
  'Purpose-Driven',
  'Agile',
  'Open-Minded',
  'Data-Driven'
].sort()

const languageOptions = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'French',
  'German',
  'Japanese',
  'Russian',
  'Portuguese',
  'Arabic',
  'Hindi',
  'Italian',
  'Korean',
  'Dutch',
  'Turkish',
  'Swedish',
  'Polish',
  'Greek',
  'Vietnamese',
  'Hebrew',
  'Thai',
  'Indonesian',
  'Danish',
  'Finnish',
  'Norwegian',
  'Czech'
].sort()

// Styled Components
// Styled Components

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

  body {
    font-family: 'Open Sans', sans-serif;
  }
`

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#CAAAE1',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

// Core Layout Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${softColors.background};
  color: ${softColors.text};
  font-family: 'Open Sans', sans-serif;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
`

const FormCard = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: ${softColors.card};
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

// Typography Components
const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${softColors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
`

const SubTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${softColors.text};
  margin-bottom: 1.5rem;
`

const SectionTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${softColors.text};
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const Note = styled.p`
  color: ${softColors.textLight};
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 2rem;
`

const HelperText = styled.p`
  color: ${softColors.textLight};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`

// Form Components
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: ${softColors.text};
  font-size: 0.9rem;
`

const RequiredLabel = styled.span`
  color: ${softColors.danger};
  margin-left: 0.25rem;
`

const Input = styled.input`
  width: 80%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: all 0.2s;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }
`

// Grid and Layout Components
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Section = styled.div`
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid ${softColors.border};
`

// Checkbox and Radio Components
const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.border};
  }
`

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  accent-color: ${softColors.primary};
`

const RadioGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.background};
  }

  input[type='radio'] {
    accent-color: ${softColors.primary};
    width: 1.25rem;
    height: 1.25rem;
  }
`

// Button Components
const Button = styled.button`
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: ${softColors.primary};
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.icontext};
    transform: translateY(-1px);
  }

  &:disabled {
    background-color: ${softColors.border};
    cursor: not-allowed;
    transform: none;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
`

const AddButton = styled(Button)`
  background-color: ${softColors.success};

  &:hover {
    background-color: ${softColors.success}dd;
  }
`

const RemoveButton = styled(Button)`
  background-color: ${softColors.danger};

  &:hover {
    background-color: ${softColors.danger}dd;
  }
`

// Collapsible Components
const CollapsibleSection = styled.div`
  margin-bottom: 1.5rem;
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  overflow: hidden;
`

const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.border};
  }
`

const CollapsibleContent = styled.div`
  padding: ${(props) => (props.isOpen ? '1rem' : '0')};
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-color: ${softColors.card};
`

// Multi-select Components
const MultiSelect = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 1rem;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${softColors.card};
`

const BubbleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const Bubble = styled.div`
  background-color: ${(props) =>
    props.selected ? softColors.primary : softColors.background};
  color: ${(props) => (props.selected ? 'white' : softColors.text)};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: all 0.2s;

  &:hover {
    background-color: ${(props) =>
      props.selected ? softColors.icontext : softColors.border};
  }
`

// File Upload Components
const DropzoneContainer = styled.div`
  border: 2px dashed ${softColors.border};
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: ${softColors.primary};
    background-color: ${softColors.background};
  }
`

const FilePreview = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
`

const PreviewImage = styled.img`
  max-width: 150px;
  max-height: 150px;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
`

const FileInput = styled.input`
  display: none;
`

// Error Message Components
const ErrorMessage = styled.div`
  color: ${softColors.danger};
  background-color: ${softColors.danger}11;
  border: 1px solid ${softColors.danger};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 0.875rem;
`

// Link Components
const LinkInput = styled(Input)`
  padding-left: 2.5rem;
  background-image: url('/link-icon.svg');
  background-repeat: no-repeat;
  background-position: 0.75rem center;
  background-size: 1rem;
`

// Progress Components
const ProgressBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: ${softColors.border};
  border-radius: 1rem;
  margin: 2rem 0;
  overflow: hidden;
`

const ProgressFill = styled.div`
  height: 100%;
  width: ${(props) => (props.progress / 8) * 100}%;
  background-color: ${softColors.primary};
  transition: width 0.3s ease;
`

const WorkHistoryCard = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: ${softColors.background};
`

const FileUploadArea = styled.div`
  border: 2px dashed ${softColors.border};
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${(props) =>
    props.isDragging ? softColors.background : 'transparent'};

  &:hover {
    border-color: ${softColors.primary};
    background-color: ${softColors.background};
  }
`

const FadeInFormGroup = styled(FormGroup)`
  animation: fadeIn 0.5s ease-in;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

// Add this Tooltip styled component for the tooltip behavior
const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`

const TooltipText = styled.span`
  visibility: hidden;
  width: 240px;
  background-color: ${softColors.text};
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -120px;
  opacity: 0;
  transition: opacity 0.3s;

  /* Arrow */
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${softColors.text} transparent transparent transparent;
  }
`

// JobseekerIntakeForm Component
const JobseekerIntake = () => {
  // State
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [openSections, setOpenSections] = useState({})

  const [formData, setFormData] = useState({
    // Personal Info
    date_of_birth: '',
    pronouns: '',
    street_address: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',

    // Work History
    is_athlete: 'no',
    athletic_history: [],
    work_history: [
      {
        title: '',
        employer: '',
        start_date: '',
        end_date: ''
      }
    ],

    // Education
    highest_education: '',
    education_history: [
      {
        institution: '',
        degree_type: '',
        field_of_study: '',
        graduation_date: ''
      }
    ],

    // Experience
    years_experience: '',
    current_level: '',
    has_startup_experience: '',
    industries: [],
    subcategories: {},

    // Get to Know You
    strengths: [],
    work_preference: '',
    job_search_challenge: '',
    culture_preferences: [],
    languages: [],
    social_links: {
      linkedin: '',
      portfolio: '',
      github: '',
      additional1: '',
      additional2: ''
    },
    photo: null,
    photo_preview: null,
    resume: null
  })

  const [isLoading, setIsLoading] = useState(false)

  const toggleSection = (sectionId) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }))
  }

  // Handlers
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleWorkHistoryChange = (index, field, value) => {
    const newWorkHistory = [...formData.work_history]
    if (field === 'present') {
      newWorkHistory[index] = {
        ...newWorkHistory[index],
        present: value,
        end_date: value ? null : newWorkHistory[index].end_date // Clear end_date if "Present" is checked
      }
    } else {
      newWorkHistory[index] = {
        ...newWorkHistory[index],
        [field]: value === '' ? null : value // Keep null if value is blank
      }
    }
    setFormData({
      ...formData,
      work_history: newWorkHistory
    })
  }

  const addWorkHistory = () => {
    if (formData.work_history.length < 4) {
      setFormData({
        ...formData,
        work_history: [
          ...formData.work_history,
          { title: '', employer: '', start_date: '', end_date: '' }
        ]
      })
    }
  }

  const removeWorkHistory = (index) => {
    const newWorkHistory = formData.work_history.filter((_, i) => i !== index)
    setFormData({
      ...formData,
      work_history: newWorkHistory
    })
  }

  const handleAthleteHistoryChange = (index, field, value) => {
    const newAthleteHistory = [...formData.athletic_history]
    newAthleteHistory[index] = {
      ...newAthleteHistory[index],
      [field]: value
    }
    setFormData({
      ...formData,
      athletic_history: newAthleteHistory
    })
  }

  const addAthleteHistory = () => {
    setFormData({
      ...formData,
      athletic_history: [
        ...formData.athletic_history,
        {
          sport: '',
          league: '',
          team: '',
          position: '',
          start_date: '',
          end_date: '',
          achievements: []
        }
      ]
    })
  }

  const removeAthleteHistory = (index) => {
    const newAthleteHistory = formData.athletic_history.filter(
      (_, i) => i !== index
    )
    setFormData({
      ...formData,
      athletic_history: newAthleteHistory
    })
  }

  const handleEducationHistoryChange = (index, field, value) => {
    const newEducationHistory = [...formData.education_history]
    newEducationHistory[index] = {
      ...newEducationHistory[index],
      [field]: value
    }
    setFormData({
      ...formData,
      education_history: newEducationHistory
    })
  }

  const addEducation = () => {
    if (formData.education_history.length < 3) {
      setFormData({
        ...formData,
        education_history: [
          ...formData.education_history,
          {
            institution: '',
            degree_type: '',
            field_of_study: '',
            graduation_date: '' // Add graduation_date field
          }
        ]
      })
    }
  }

  const removeEducation = (index) => {
    const newEducationHistory = formData.education_history.filter(
      (_, i) => i !== index
    )
    setFormData({
      ...formData,
      education_history: newEducationHistory
    })
  }

  const handleIndustryChange = (industry) => {
    const newIndustries = formData.industries.includes(industry)
      ? formData.industries.filter((i) => i !== industry)
      : [...formData.industries, industry]

    const newSubcategories = { ...formData.subcategories }
    if (!newIndustries.includes(industry)) {
      delete newSubcategories[industry]
    }

    setFormData({
      ...formData,
      industries: newIndustries,
      subcategories: newSubcategories
    })
  }

  const handleSubcategoryChange = (industry, subcategory) => {
    const currentSubcategories = formData.subcategories[industry] || []
    const newSubcategories = currentSubcategories.includes(subcategory)
      ? currentSubcategories.filter((s) => s !== subcategory)
      : [...currentSubcategories, subcategory]

    setFormData({
      ...formData,
      subcategories: {
        ...formData.subcategories,
        [industry]: newSubcategories
      }
    })
  }

  const handleStrengthChange = (strength) => {
    const newStrengths = formData.strengths.includes(strength)
      ? formData.strengths.filter((s) => s !== strength)
      : [...formData.strengths]

    if (!formData.strengths.includes(strength) && newStrengths.length < 3) {
      newStrengths.push(strength)
    }

    setFormData({
      ...formData,
      strengths: newStrengths
    })
  }

  // Add these new handler functions
  const handleCulturePreferenceChange = (preference) => {
    const newPreferences = formData.culture_preferences.includes(preference)
      ? formData.culture_preferences.filter((p) => p !== preference)
      : [...formData.culture_preferences]

    if (
      !formData.culture_preferences.includes(preference) &&
      newPreferences.length < 3
    ) {
      newPreferences.push(preference)
    }

    setFormData({
      ...formData,
      culture_preferences: newPreferences
    })
  }

  const handleLanguageChange = (language) => {
    const newLanguages = formData.languages.includes(language)
      ? formData.languages.filter((l) => l !== language)
      : [...formData.languages, language]

    setFormData({
      ...formData,
      languages: newLanguages
    })
  }

  const handleFileUpload = (type, file) => {
    if (type === 'photo') {
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setFormData({
            ...formData,
            photo: file,
            photo_preview: reader.result
          })
        }
        reader.readAsDataURL(file)
      } else {
        alert('Please upload an image file')
      }
    } else if (type === 'resume') {
      if (
        file &&
        (file.type === 'application/pdf' ||
          file.type === 'application/msword' ||
          file.type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
      ) {
        setFormData({
          ...formData,
          resume: file
        })
      } else {
        alert('Please upload a PDF or Word document')
      }
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (type, e) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.dataTransfer.files[0]
    handleFileUpload(type, file)
  }

  const nextStep = () => {
    setCurrentStep((curr) => Math.min(curr + 1, 8))
  }

  const prevStep = () => {
    setCurrentStep((curr) => Math.max(curr - 1, 1))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (currentStep !== 8) {
      nextStep()
      return
    }

    // Validate required files
    if (!formData.photo || !formData.resume) {
      alert('Please upload both a profile photo and resume')
      return
    }

    setIsLoading(true)
    let photoUrl = null
    let resumeUrl = null

    try {
      // Handle photo upload
      if (formData.photo) {
        const fileExtension = formData.photo.name.split('.').pop().toLowerCase()

        // Validate photo type
        if (!['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
          throw new Error('Invalid image format. Please use JPG, PNG, or GIF.')
        }

        // Validate photo size (5MB)
        if (formData.photo.size > 5 * 1024 * 1024) {
          throw new Error('Profile photo must be less than 5MB.')
        }

        const timestamp = Date.now()
        const photoPath = `profile-photos/${auth.currentUser.uid}/${timestamp}.${fileExtension}`
        const photoRef = ref(storage, photoPath)

        await uploadBytes(photoRef, formData.photo)
        photoUrl = await getDownloadURL(photoRef)
      }

      // Handle resume upload
      if (formData.resume) {
        const fileExtension = formData.resume.name
          .split('.')
          .pop()
          .toLowerCase()

        // Validate resume type
        if (!['pdf', 'doc', 'docx'].includes(fileExtension)) {
          throw new Error(
            'Invalid resume format. Please use PDF or Word document.'
          )
        }

        // Validate resume size (10MB)
        if (formData.resume.size > 10 * 1024 * 1024) {
          throw new Error('Resume must be less than 10MB.')
        }

        const userId = auth.currentUser.uid
        const timestamp = Date.now()
        const resumePath = `resumes/${userId}/${timestamp}.${fileExtension}`
        const resumeRef = ref(storage, resumePath)

        await uploadBytes(resumeRef, formData.resume)
        resumeUrl = await getDownloadURL(resumeRef)
      }

      // Log the critical fields before creating profile data
      console.log('Critical form fields:', {
        strengths: formData.strengths,
        current_level: formData.current_level,
        work_preference: formData.work_preference
      })

      // Prepare profile data
      const profileData = {
        intake_completed: true,

        // Personal Info
        date_of_birth: formData.date_of_birth,
        pronouns: formData.pronouns,
        street_address: formData.street_address,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zip_code,
        phone: formData.phone,

        // Work History
        work_history: (formData.work_history || []).map((job) => ({
          title: job.title || null,
          employer: job.employer || null,
          start_date: job.start_date || null,
          end_date: job.end_date || null
        })),

        // Athletic Status
        athlete_status: formData.is_athlete === 'yes',
        athletic_history: (formData.athletic_history || []).map((history) => ({
          sport: history.sport || null,
          league: history.league || null,
          team: history.team || null,
          position: history.position || null,
          start_date: history.start_date || null,
          end_date: history.end_date || null,
          achievements: history.achievements || []
        })),

        // Education
        education: (formData.education_history || []).map((edu) => ({
          institution: edu.institution || null,
          degree_type: edu.degree_type || null,
          field_of_study: edu.field_of_study || null
        })),

        // Experience and Level
        years_experience: formData.years_experience,
        job_level: formData.current_level, // Map current_level to job_level
        startup_experience: formData.has_startup_experience === 'yes',

        // Preferences and Strengths - Critical fields explicitly mapped
        top_strengths: formData.strengths || [], // Map strengths to top_strengths
        preferred_telework_policy: formData.work_preference, // Map work_preference to preferred_telework_policy
        job_search_challenge: formData.job_search_challenge,
        company_culture_preferences: formData.culture_preferences || [],
        languages: formData.languages || [],

        // Social Links
        linkedin: formData.social_links?.linkedin || null,
        portfolio: formData.social_links?.portfolio || null,
        github: formData.social_links?.github || null,
        personal_website: formData.social_links?.additional1 || null,
        additional_website_2: formData.social_links?.additional2 || null,

        // Files
        profile_picture: photoUrl,
        resume_url: resumeUrl,

        // Additional fields with defaults
        block_current_employer: false,
        plan_type: 'freetrial',
        accommodation_needed: null,
        company_ratings: null,
        disability_status: null,
        gender: null,
        job_search_motivation: null,
        open_to_work: null,
        race: null,
        sexual_orientation: null,
        starting_salary: null,
        ending_salary: null,
        veteran_status: null,
        jobs_applied: [],
        videos: [],

        created_at: serverTimestamp(),
        updated_at: serverTimestamp()
      }

      // Log the critical fields in the prepared profile data
      console.log('Critical fields in profile data:', {
        top_strengths: profileData.top_strengths,
        job_level: profileData.job_level,
        preferred_telework_policy: profileData.preferred_telework_policy
      })

      // Submit to Firestore
      await createOrUpdateJobseekerProfile(auth.currentUser.uid, profileData)

      // Log success
      console.log('Profile successfully updated')

      // Navigate to dashboard on success
      navigate('/jobseeker-dashboard')
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  // Render step functions
  const renderPersonalInfo = () => (
    <Section>
      <SectionTitle>Personal Information</SectionTitle>
      <FormGroup>
        <Label htmlFor="date_of_birth">
          Date of Birth <RequiredLabel>*</RequiredLabel>
          <TooltipContainer>
            <Info
              size={16}
              style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
            />
            <TooltipText className="tooltip-text">
              We collect birthdate information to measure our progress in
              promoting age-inclusive hiring. This information is kept
              confidential and is never shared with employers or used in job
              matching.
            </TooltipText>
          </TooltipContainer>
        </Label>
        <Input
          type="date"
          id="date_of_birth"
          name="date_of_birth"
          value={formData.date_of_birth}
          onChange={handleChange}
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="pronouns">
          Pronouns <RequiredLabel>*</RequiredLabel>
        </Label>
        <Select
          id="pronouns"
          name="pronouns"
          value={formData.pronouns}
          onChange={handleChange}
          required
        >
          <option value="">Select your pronouns</option>
          {pronounOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="street_address">
          Street Address <RequiredLabel>*</RequiredLabel>
        </Label>
        <Input
          type="text"
          id="street_address"
          name="street_address"
          value={formData.street_address}
          onChange={handleChange}
          required
        />
      </FormGroup>

      <GridContainer>
        <FormGroup>
          <Label htmlFor="city">
            City <RequiredLabel>*</RequiredLabel>
          </Label>
          <Input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="state">
            State <RequiredLabel>*</RequiredLabel>
          </Label>
          <Select
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          >
            <option value="">Select state</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
        </FormGroup>
      </GridContainer>

      <GridContainer>
        <FormGroup>
          <Label htmlFor="zip_code">
            ZIP Code <RequiredLabel>*</RequiredLabel>
          </Label>
          <Input
            type="text"
            id="zip_code"
            name="zip_code"
            value={formData.zip_code}
            onChange={handleChange}
            pattern="[0-9]{5}"
            placeholder="12345"
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="phone">
            Phone Number <RequiredLabel>*</RequiredLabel>
          </Label>
          <Input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="123-456-7890"
            required
          />
        </FormGroup>
      </GridContainer>
    </Section>
  )

  const renderWorkHistory = () => (
    <Section>
      <SectionTitle>Professional Background</SectionTitle>

      <FormGroup>
        <Label>
          Are you currently or were you formerly a collegiate or professional
          athlete?
          <RequiredLabel>*</RequiredLabel>
        </Label>
        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="is_athlete"
              value="yes"
              checked={formData.is_athlete === 'yes'}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  is_athlete: e.target.value,
                  athletic_history: e.target.value === 'yes' ? [{}] : []
                })
              }}
            />
            Yes
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="is_athlete"
              value="no"
              checked={formData.is_athlete === 'no'}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  is_athlete: e.target.value,
                  athletic_history: []
                })
              }}
            />
            No
          </RadioLabel>
        </RadioGroup>
      </FormGroup>

      {formData.is_athlete === 'yes' && (
        <>
          <SubTitle>Athletic History</SubTitle>
          {formData.athletic_history.map((history, index) => (
            <CollapsibleSection key={index}>
              <CollapsibleHeader
                onClick={() => toggleSection(`athletic-${index}`)}
              >
                <Label>
                  {history.sport || `Athletic History ${index + 1}`}
                </Label>
                {openSections[`athletic-${index}`] ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                )}
              </CollapsibleHeader>
              <CollapsibleContent isOpen={openSections[`athletic-${index}`]}>
                <FormGroup>
                  <Label>
                    Sport <RequiredLabel>*</RequiredLabel>
                  </Label>
                  <Input
                    type="text"
                    value={history.sport || ''}
                    onChange={(e) =>
                      handleAthleteHistoryChange(index, 'sport', e.target.value)
                    }
                    placeholder="What sport did/do you participate in?"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    League <RequiredLabel>*</RequiredLabel>
                  </Label>
                  <Select
                    value={history.league || ''}
                    onChange={(e) =>
                      handleAthleteHistoryChange(
                        index,
                        'league',
                        e.target.value
                      )
                    }
                    required
                  >
                    <option value="">Select league</option>
                    {leagues.map((league) => (
                      <option key={league} value={league}>
                        {league}
                      </option>
                    ))}
                  </Select>
                </FormGroup>

                {/* Continue with other athletic history fields... */}
                {index > 0 && (
                  <RemoveButton
                    type="button"
                    onClick={() => removeAthleteHistory(index)}
                  >
                    Remove Athletic History
                  </RemoveButton>
                )}
              </CollapsibleContent>
            </CollapsibleSection>
          ))}

          <AddButton type="button" onClick={addAthleteHistory}>
            Add Another Athletic History
          </AddButton>
        </>
      )}

      <SubTitle>Work History</SubTitle>
      {formData.work_history.map((job, index) => (
        <CollapsibleSection key={index}>
          <CollapsibleHeader onClick={() => toggleSection(`work-${index}`)}>
            <Label>{job.title || `Job ${index + 1}`}</Label>
            {openSections[`work-${index}`] ? <ChevronUp /> : <ChevronDown />}
          </CollapsibleHeader>
          <CollapsibleContent isOpen={openSections[`work-${index}`]}>
            <GridContainer>
              <FormGroup>
                <Label>
                  Job Title <RequiredLabel>*</RequiredLabel>
                </Label>
                <Input
                  type="text"
                  value={job.title}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'title', e.target.value)
                  }
                  placeholder="Job Title"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Employer <RequiredLabel>*</RequiredLabel>
                </Label>
                <Input
                  type="text"
                  value={job.employer}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'employer', e.target.value)
                  }
                  placeholder="Employer"
                  required
                />
              </FormGroup>
            </GridContainer>

            <GridContainer>
              <FormGroup>
                <Label>
                  Start Date <RequiredLabel>*</RequiredLabel>
                </Label>
                <Input
                  type="date"
                  value={job.start_date}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'start_date', e.target.value)
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>End Date</Label>
                <Input
                  type="date"
                  value={job.end_date}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'end_date', e.target.value)
                  }
                  placeholder="Leave blank if current"
                  disabled={job.present} // Disable if "Present" is checked
                />
                <CheckboxLabel>
                  <Checkbox
                    checked={job.present || false}
                    onChange={() =>
                      handleWorkHistoryChange(index, 'present', !job.present)
                    }
                  />
                  <span>Present</span>
                </CheckboxLabel>
              </FormGroup>
            </GridContainer>

            {index > 0 && (
              <RemoveButton
                type="button"
                onClick={() => removeWorkHistory(index)}
              >
                Remove Job
              </RemoveButton>
            )}
          </CollapsibleContent>
        </CollapsibleSection>
      ))}

      {formData.work_history.length < 4 && (
        <AddButton type="button" onClick={addWorkHistory}>
          Add Another Job
        </AddButton>
      )}
    </Section>
  )

  const renderEducation = () => (
    <>
      <FormGroup>
        <Label>Highest Level of Education</Label>
        <Select
          name="highest_education"
          value={formData.highest_education}
          onChange={handleChange}
          required
        >
          <option value="">Select education level</option>
          {educationLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </Select>
      </FormGroup>

      {formData.education_history.map((edu, index) => (
        <WorkHistoryCard key={index}>
          <FormGroup>
            <Label>Institution Name</Label>
            <Input
              type="text"
              value={edu.institution}
              onChange={(e) =>
                handleEducationHistoryChange(
                  index,
                  'institution',
                  e.target.value
                )
              }
              placeholder="Institution name"
            />
          </FormGroup>

          <GridContainer>
            <FormGroup>
              <Label>Degree Type</Label>
              <Input
                type="text"
                value={edu.degree_type}
                onChange={(e) =>
                  handleEducationHistoryChange(
                    index,
                    'degree_type',
                    e.target.value
                  )
                }
                placeholder="e.g., BA, BS, MA"
              />
            </FormGroup>

            <FormGroup>
              <Label>Field of Study</Label>
              <Input
                type="text"
                value={edu.field_of_study}
                onChange={(e) =>
                  handleEducationHistoryChange(
                    index,
                    'field_of_study',
                    e.target.value
                  )
                }
                placeholder="Major/Concentration"
              />
            </FormGroup>

            <FormGroup>
              <Label>Graduation Date</Label>
              <Input
                type="month"
                value={edu.graduation_date || ''}
                onChange={(e) =>
                  handleEducationHistoryChange(
                    index,
                    'graduation_date',
                    e.target.value
                  )
                }
                placeholder="Select graduation date"
              />
            </FormGroup>
          </GridContainer>

          {index > 0 && (
            <RemoveButton type="button" onClick={() => removeEducation(index)}>
              Remove Education
            </RemoveButton>
          )}
        </WorkHistoryCard>
      ))}

      {formData.education_history.length < 3 && (
        <AddButton type="button" onClick={addEducation}>
          Add Another Education
        </AddButton>
      )}
    </>
  )

  const renderExperience = () => (
    <>
      <FormGroup>
        <Label>Years of Experience</Label>
        <Select
          name="years_experience"
          value={formData.years_experience}
          onChange={handleChange}
          required
        >
          <option value="">Select experience level</option>
          {experienceLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <Label>Current Level</Label>
        <Select
          name="current_level"
          value={formData.current_level}
          onChange={handleChange}
        >
          <option value="">Select current level</option>
          {currentLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <Label>Do you have startup experience?</Label>
        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="has_startup_experience"
              value="yes"
              checked={formData.has_startup_experience === 'yes'}
              onChange={handleChange}
            />
            <span>Yes</span>
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="has_startup_experience"
              value="no"
              checked={formData.has_startup_experience === 'no'}
              onChange={handleChange}
            />
            <span>No</span>
          </RadioLabel>
        </RadioGroup>
      </FormGroup>

      <FormGroup>
        <Label>Industries you have experience working in</Label>
        <MultiSelect>
          <CheckboxGroup>
            {industries.map((industry) => (
              <div key={industry}>
                <CheckboxLabel>
                  <Checkbox
                    checked={formData.industries.includes(industry)}
                    onChange={() => handleIndustryChange(industry)}
                  />
                  <span>{industry}</span>
                </CheckboxLabel>

                {formData.industries.includes(industry) && (
                  <CheckboxGroup style={{ marginLeft: '1.5rem' }}>
                    {allSubcategories[industry].map((sub) => (
                      <CheckboxLabel key={sub}>
                        <Checkbox
                          checked={(
                            formData.subcategories[industry] || []
                          ).includes(sub)}
                          onChange={() =>
                            handleSubcategoryChange(industry, sub)
                          }
                        />
                        <span>{sub}</span>
                      </CheckboxLabel>
                    ))}
                  </CheckboxGroup>
                )}
              </div>
            ))}
          </CheckboxGroup>
        </MultiSelect>
      </FormGroup>
    </>
  )

  const renderGetToKnowYou = () => (
    <>
      <FormGroup>
        <Label>
          Select Your Top Three Strengths{' '}
          <span style={{ color: colors.error }}>*</span>
        </Label>
        <MultiSelect>
          <CheckboxGroup>
            {strengths.map((strength) => (
              <CheckboxLabel key={strength}>
                <Checkbox
                  checked={formData.strengths.includes(strength)}
                  onChange={() => handleStrengthChange(strength)}
                  disabled={
                    !formData.strengths.includes(strength) &&
                    formData.strengths.length >= 3
                  }
                />
                <span>{strength}</span>
              </CheckboxLabel>
            ))}
          </CheckboxGroup>
        </MultiSelect>
        <HelperText>Selected: {formData.strengths.length}/3</HelperText>
      </FormGroup>

      <FormGroup>
        <Label>
          How do you prefer to work?{' '}
          <span style={{ color: colors.error }}>*</span>
        </Label>
        <Select
          name="work_preference"
          value={formData.work_preference}
          onChange={handleChange}
          required
        >
          <option value="">Select work preference</option>
          {workPreferences.map((pref) => (
            <option key={pref} value={pref}>
              {pref}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FadeInFormGroup>
        <Label>
          What has been the #1 most challenging part of your job search so far?{' '}
          <span style={{ color: colors.error }}>*</span>
        </Label>
        <Select
          name="job_search_challenge"
          value={formData.job_search_challenge}
          onChange={handleChange}
          required
        >
          <option value="">Select a challenge</option>
          {jobSearchChallenges.map((challenge) => (
            <option key={challenge.value} value={challenge.value}>
              {challenge.label}
            </option>
          ))}
        </Select>
      </FadeInFormGroup>
    </>
  )

  const renderCultureAndLanguages = () => (
    <>
      <FormGroup>
        <Label>
          Select Your Top Three Company Culture Preferences{' '}
          <span style={{ color: colors.error }}>*</span>
        </Label>
        <MultiSelect>
          <CheckboxGroup>
            {culturePreferenceOptions.map((preference) => (
              <CheckboxLabel key={preference}>
                <Checkbox
                  checked={formData.culture_preferences.includes(preference)}
                  onChange={() => handleCulturePreferenceChange(preference)}
                  disabled={
                    !formData.culture_preferences.includes(preference) &&
                    formData.culture_preferences.length >= 3
                  }
                />
                <span>{preference}</span>
              </CheckboxLabel>
            ))}
          </CheckboxGroup>
        </MultiSelect>
        <HelperText>
          Selected: {formData.culture_preferences.length}/3
        </HelperText>
      </FormGroup>

      <FormGroup>
        <Label>
          Languages You Speak <span style={{ color: colors.error }}>*</span>
        </Label>
        <MultiSelect>
          <CheckboxGroup>
            {languageOptions.map((language) => (
              <CheckboxLabel key={language}>
                <Checkbox
                  checked={formData.languages.includes(language)}
                  onChange={() => handleLanguageChange(language)}
                />
                <span>{language}</span>
              </CheckboxLabel>
            ))}
          </CheckboxGroup>
        </MultiSelect>
      </FormGroup>
    </>
  )

  const renderPersonalLinks = () => (
    <>
      <FormGroup>
        <Label>LinkedIn Profile</Label>
        <LinkInput
          type="text"
          name="linkedin"
          value={formData.social_links.linkedin}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                linkedin: e.target.value
              }
            })
          }
          placeholder="https://linkedin.com/in/your-profile"
        />
      </FormGroup>

      <FormGroup>
        <Label>Portfolio Website</Label>
        <LinkInput
          type="text"
          name="portfolio"
          value={formData.social_links.portfolio}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                portfolio: e.target.value
              }
            })
          }
          placeholder="https://your-portfolio.com"
        />
      </FormGroup>

      <FormGroup>
        <Label>GitHub Profile</Label>
        <LinkInput
          type="text"
          name="github"
          value={formData.social_links.github}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: { ...formData.social_links, github: e.target.value }
            })
          }
          placeholder="https://github.com/your-username"
        />
      </FormGroup>

      <FormGroup>
        <Label>Additional Website 1</Label>
        <LinkInput
          type="text"
          name="additional1"
          value={formData.social_links.additional1}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                additional1: e.target.value
              }
            })
          }
          placeholder="https://"
        />
      </FormGroup>

      <FormGroup>
        <Label>Additional Website 2</Label>
        <LinkInput
          type="text"
          name="additional2"
          value={formData.social_links.additional2}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                additional2: e.target.value
              }
            })
          }
          placeholder="https://"
        />
      </FormGroup>
    </>
  )

  const renderUploads = () => (
    <>
      <FormGroup>
        <Label>
          Profile Photo <span style={{ color: colors.error }}>*</span>
        </Label>
        <FileUploadArea
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop('photo', e)}
          onClick={() => document.getElementById('photo-upload').click()}
          isDragging={false}
        >
          <p>Drag and drop your photo here, or click to select</p>
          <HelperText>
            Accepted formats: JPG, PNG, GIF (Max size: 5MB)
          </HelperText>
          {formData.photo_preview && (
            <PreviewImage src={formData.photo_preview} alt="Preview" />
          )}
        </FileUploadArea>
        <FileInput
          id="photo-upload"
          type="file"
          accept="image/*"
          onChange={(e) => handleFileUpload('photo', e.target.files[0])}
        />
      </FormGroup>

      <FormGroup>
        <Label>
          Resume <span style={{ color: colors.error }}>*</span>
        </Label>
        <FileUploadArea
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop('resume', e)}
          onClick={() => document.getElementById('resume-upload').click()}
          isDragging={false}
        >
          <p>Drag and drop your resume here, or click to select</p>
          <HelperText>
            Accepted formats: PDF, DOC, DOCX (Max size: 10MB)
          </HelperText>
          {formData.resume && <p>Selected file: {formData.resume.name}</p>}
        </FileUploadArea>
        <FileInput
          id="resume-upload"
          type="file"
          accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={(e) => handleFileUpload('resume', e.target.files[0])}
        />
      </FormGroup>
    </>
  )

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return renderPersonalInfo()
      case 2:
        return renderWorkHistory()
      case 3:
        return renderEducation()
      case 4:
        return renderExperience()
      case 5:
        return renderGetToKnowYou()
      case 6:
        return renderCultureAndLanguages()
      case 7:
        return renderPersonalLinks()
      case 8:
        return renderUploads()
      default:
        return null
    }
  }

  return (
    <Container>
      <GlobalStyle />
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>
      <MainContent>
        <FormCard>
          <Title>Let's Build Your Profile..</Title>
          <ProgressBar>
            <ProgressFill progress={currentStep} />
          </ProgressBar>
          <Note>Step {currentStep} of 8</Note>

          <Form onSubmit={handleSubmit}>
            {renderStep()}

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <ButtonGroup>
              {currentStep > 1 && (
                <Button type="button" onClick={prevStep}>
                  Previous
                </Button>
              )}
              <Button type="submit" disabled={isLoading}>
                {currentStep === 8
                  ? isLoading
                    ? 'Submitting...'
                    : 'Submit'
                  : 'Next'}
              </Button>
            </ButtonGroup>
          </Form>
        </FormCard>
      </MainContent>
    </Container>
  )
}

export default JobseekerIntake
