import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Lock, Eye, EyeOff } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth'
import {
  doc,
  getDoc,
  setDoc,
  updateDoc, // Add this import
  query,
  collection,
  where,
  getDocs
} from 'firebase/firestore'
import { auth, db } from '../firebase' // Ensure this path is correct

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6366f1 0%, #a855f7 100%);
  padding: 20px;
`

const ContentBox = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  animation: ${fadeIn} 0.5s ease-out;
`

const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin: 0 auto 1.5rem;
  animation: ${slideUp} 0.5s ease-out;
`

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #3730a3;
  margin-bottom: 1rem;
  animation: ${slideUp} 0.5s ease-out 0.1s both;
`

const SubTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  color: #4f46e5;
  margin-bottom: 1.5rem;
  animation: ${slideUp} 0.5s ease-out 0.2s both;
`

const Button = styled.button`
  background-color: #8b5cf6;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 9999px;
  border: none;
  width: 100%;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  animation: ${slideUp} 0.5s ease-out 0.3s both;

  &:hover {
    background-color: #7c3aed;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(139, 92, 246, 0.25);
  }
`

const InfoBox = styled.div`
  background-color: #e0e7ff;
  border-radius: 12px;
  padding: 1rem;
  margin-top: 1.5rem;
  animation: ${slideUp} 0.5s ease-out 0.4s both;
`

const InfoText = styled.p`
  font-size: 0.875rem;
  color: #3730a3;

  a {
    color: #4f46e5;
    text-decoration: none;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  animation: ${slideUp} 0.5s ease-out;
`

const InputGroup = styled.div`
  position: relative;
`

const Label = styled.label`
  font-weight: 500;
  color: #4b5563;
  display: block;
  margin-bottom: 0.5rem;
  text-align: left;
`

const Input = styled.input`
  width: 80%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 9999px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #8b5cf6;
    box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.1);
  }
`

const InputIcon = styled.span`
  position: absolute;
  left: 1rem;
  top: 2.4rem;
  color: #a0aec0;
`

const PasswordInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const TogglePasswordVisibility = styled.button`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #a0aec0;
`

const ErrorMessage = styled.div`
  color: #ef4444;
  margin-top: 1rem;
  font-size: 0.875rem;
  text-align: center;
`
const LinkText = styled.button`
  background: none;
  border: none;
  color: #4f46e5;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0;
  margin: 0.5rem 0;

  &:hover {
    color: #3730a3;
  }
`

const TermsText = styled.p`
  font-size: 0.75rem;
  color: #6b7280;
  margin-top: 1rem;
  text-align: center;

  a {
    color: #4f46e5;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

export default function SplashPage() {
  const [showLogin, setShowLogin] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [eventCode, setEventCode] = useState('') // For MSBC event code
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const [resetSuccess, setResetSuccess] = useState(false)
  const [showResetPassword, setShowResetPassword] = useState(false)

  const handleResetPassword = async (e) => {
    e.preventDefault()
    try {
      await sendPasswordResetEmail(auth, email)
      setResetSuccess(true)
      setError('')
    } catch (error) {
      setError('Error sending reset email. Please try again.')
      setResetSuccess(false)
    }
  }
  const migrateTeleworkPolicy = async (userId) => {
    try {
      const profileRef = doc(db, 'users', userId, 'profiles', 'jobseeker')

      const profileSnap = await getDoc(profileRef)

      if (profileSnap.exists()) {
        const profileData = profileSnap.data()

        // Check if preferred_telework_policy is a string
        if (
          typeof profileData.preferred_telework_policy === 'string' &&
          profileData.preferred_telework_policy !== ''
        ) {
          // Convert string to array
          await updateDoc(profileRef, {
            preferred_telework_policy: [profileData.preferred_telework_policy]
          })

          console.log(`Migrated telework policy for user ${userId}`)
        }
      }
    } catch (error) {
      console.error(
        `Error migrating telework policy for user ${userId}:`,
        error
      )
    }
  }

  if (showResetPassword) {
    return (
      <PageContainer>
        <ContentBox>
          <Logo src="/images/logo-black.png" alt="Arena x MSBC Logo" />
          <Title>Reset Password</Title>
          <Form onSubmit={handleResetPassword}>
            <InputGroup>
              <Label htmlFor="resetEmail">Email</Label>
              <Input
                id="resetEmail"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <InputIcon>
                <Lock size={16} />
              </InputIcon>
            </InputGroup>
            <Button type="submit">Send Reset Link</Button>
            <Button onClick={() => setShowResetPassword(false)}>Back</Button>
          </Form>
          {resetSuccess && (
            <InfoBox>
              <InfoText>
                Password reset email sent! Please check your inbox.
              </InfoText>
            </InfoBox>
          )}
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ContentBox>
      </PageContainer>
    )
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      // Step 1: Authenticate the user with Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user = userCredential.user

      // Step 2: Check the users collection for the user's role
      const userDoc = await getDoc(doc(db, 'users', user.uid))

      if (userDoc.exists()) {
        const userData = userDoc.data()
        const { role, first_name, last_name, company_name } = userData

        // Run migration for jobseeker roles
        if (role === 'jobseeker') {
          await migrateTeleworkPolicy(user.uid)
          navigate('/jobseeker-dashboard', {
            state: { firstName: first_name, lastName: last_name }
          })
        } else if (role === 'employer' || role === 'employerMember') {
          navigate('/employer-dashboard', {
            state: {
              firstName: first_name,
              lastName: last_name,
              companyName: company_name
            }
          })
        } else {
          setError('Unknown user role')
        }
      } else {
        setError('User data not found')
      }
    } catch (error) {
      console.error('Error logging in: ', error)
      setError(
        'Wrong Credentials. Please try again or contact us at support@arenatalent.com'
      )
    }
  }

  const handleSignUp = async (e) => {
    e.preventDefault()

    if (password !== confirmPassword) {
      setError("Passwords don't match")
      return
    }

    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user = userCredential.user

      // Now the user is authenticated, create user document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email,
        role: 'jobseeker',
        first_name: firstName,
        last_name: lastName,
        event_code: eventCode, // Store the MSBC event code
        createdAt: new Date()
      })

      // Automatically sign in the user after sign-up
      navigate('/jobseeker-dashboard', { state: { firstName, lastName } })
    } catch (error) {
      setError(error.message)
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <PageContainer>
      <ContentBox>
        <Logo src="/images/logo-black.png" alt="Arena x MSBC Logo" />
        <Title>Arena x MSBC</Title>
        {!showLogin && !showSignUp ? (
          <>
            <SubTitle>Click Below to Get Started</SubTitle>
            <Button onClick={() => setShowLogin(true)}>
              Partner & Student Login
            </Button>
            <Button onClick={() => setShowSignUp(true)}>Student Sign Up</Button>
            <InfoBox>
              <InfoText>
                Have any questions? Email us at{' '}
                <a href="mailto:support@arenatalent.com">
                  support@arenatalent.com
                </a>
              </InfoText>
            </InfoBox>
          </>
        ) : showLogin ? (
          <Form onSubmit={handleLogin}>
            <InputGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <InputIcon>
                <Lock size={16} />
              </InputIcon>
            </InputGroup>
            <InputGroup>
              <Label htmlFor="password">Password</Label>
              <PasswordInputWrapper>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <TogglePasswordVisibility
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                </TogglePasswordVisibility>
              </PasswordInputWrapper>
            </InputGroup>
            <Button type="submit">Log In</Button>

            <Button onClick={() => setShowLogin(false)}>Back</Button>
            <LinkText onClick={() => setShowResetPassword(true)}>
              Forgot Password?
            </LinkText>
            <TermsText>
              By logging in, you agree to Arena's{' '}
              <a
                href="https://www.arenatalent.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>{' '}
              and{' '}
              <a
                href="https://www.arenatalent.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </TermsText>
          </Form>
        ) : (
          <Form onSubmit={handleSignUp}>
            <InputGroup>
              <Label htmlFor="firstName">First Name</Label>
              <Input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="signupEmail">Email</Label>
              <Input
                id="signupEmail"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="signupPassword">Password</Label>
              <PasswordInputWrapper>
                <Input
                  id="signupPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <TogglePasswordVisibility
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                </TogglePasswordVisibility>
              </PasswordInputWrapper>
            </InputGroup>
            <InputGroup>
              <Label htmlFor="confirmPassword">Confirm Password</Label>
              <PasswordInputWrapper>
                <Input
                  id="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </PasswordInputWrapper>
            </InputGroup>
            <InputGroup>
              <Label htmlFor="eventCode">MSBC Event Code</Label>
              <Input
                id="eventCode"
                type="text"
                value={eventCode}
                onChange={(e) => setEventCode(e.target.value)}
              />
            </InputGroup>
            <Button type="submit">Sign Up</Button>
            <Button onClick={() => setShowSignUp(false)}>Back</Button>
            <TermsText>
              By signing up, you agree to Arena's{' '}
              <a
                href="https://www.arenatalent.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>{' '}
              and{' '}
              <a
                href="https://www.arenatalent.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </TermsText>
          </Form>
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </ContentBox>
    </PageContainer>
  )
}
