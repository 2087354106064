import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import EmployerNav from './EmployerNav'
import { auth } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import {
  Calendar,
  Mail,
  UserPlus,
  Briefcase,
  Users,
  PlusCircle,
  ChevronDown,
  BarChart2,
  Clock,
  Award,
  ExternalLink,
  Lock
} from 'lucide-react'

import {
  getEmployerProfile,
  getEmployerMembers,
  getJobSeekers
} from '../models/EmployerProfile'
import {
  getEmployerJobPostings,
  getEmployerJobPostingsWithApplicants
} from '../models/JobPosting'
import { getAllJobSeekerProfiles } from '../models/JobseekerProfile'
import DemographicInsights from './DemographicInsights'

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${softColors.background};
  color: ${softColors.text};
  font-family: 'Inter', sans-serif;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
`

const WelcomeHeader = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: ${softColors.text};
`

const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`

const Card = styled.div`
  background-color: ${softColors.card};
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const CardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: ${softColors.text};
`

const Link = styled.a`
  color: white;
  background-color: ${softColors.icontext};
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s;
  border: 1px solid ${softColors.icontext};
  width: auto;
  padding: 10px;
  border-radius: 5px;

  &:hover {
    background-color: white;
    color: ${softColors.icontext};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`

const StatCard = styled(Card)`
  position: relative;
`

const StatNumber = styled.p`
  font-size: 2.25rem;
  font-weight: 700;
  color: ${(props) => props.color || softColors.primary};
  margin-bottom: 0.5rem;
`

const StatLabel = styled.p`
  font-size: 0.875rem;
  color: ${softColors.textLight};
`

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 0.875rem;
  color: ${softColors.text};
  margin-top: 0.5rem;
`

const OnboardingSection = styled(Card)`
  margin-bottom: 1.5rem;
  height: auto;
`

const ApplicantInfo = styled.div`
  flex: 1;
  cursor: pointer;
`

const ApplicantCard = styled.div`
  background-color: ${softColors.card};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    background-color: ${softColors.background};
  }
`

const ApplicantName = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  color: ${softColors.text};
  margin: 0 0 0.25rem 0;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const ReviewButton = styled(Link)`
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  padding: 0.5rem;
  margin-left: -10px;
`
const FilterDropdown = styled(Select)`
  margin-bottom: 1rem;
`

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SectionIcon = styled.span`
  margin-right: 0.5rem;
`

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: -0.5rem;
  color: ${softColors.text};
`

const SectionProgressBar = styled.div`
  width: 100%;
  background-color: ${softColors.border};
  border-radius: 9999px;
  height: 0.5rem;
  margin: 0.5rem 0;
  position: relative;
`

const SectionProgress = styled.div`
  height: 0.5rem;
  border-radius: 9999px;
  width: ${(props) => props.width};
  background-color: ${(props) =>
    props.started ? softColors.success : softColors.danger};
`

const SectionContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  overflow: ${(props) => (props.isOpen ? 'show' : 'hidden')};
  transition: max-height 0.1s ease-in-out;
`

const SectionDescription = styled.p`
  font-size: 0.875rem;
  color: ${softColors.textLight};
  line-height: 1.5;
`

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor || softColors.primary};
  color: white;
  margin-bottom: 0.5rem;
`

const EventLink = styled.a`
  color: ${softColors.primary};
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const LockIcon = styled(Lock)`
  color: white;
  margin-right: 0.5rem;
`

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: ${softColors.text};
  color: ${softColors.card};
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 10;
`

const MatchWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${softColors.success};
  padding: 5px;
  border-radius: 9999px;
  padding-left: 6px;
  padding-right: 6px;
  width: 100px;
  align-items: center;
  cursor: pointer;

  &:hover ${Tooltip} {
    opacity: 1;
  }
`

const MatchText = styled.span`
  color: white;
  font-weight: 600;
`

const ComingSoonSection = styled(Card)`
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
`

const GlowingDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: ${softColors.icontext};
  border-radius: 50%;
  margin-left: 4px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.5;
    }
  }
`

const Tab = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
  color: ${softColors.textLight};
  font-size: 0.875rem;
  cursor: not-allowed;
  opacity: 0.7;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`

const PlaceholderContent = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${softColors.textLight};
  font-style: italic;
  background: linear-gradient(
    45deg,
    ${softColors.background},
    ${softColors.card},
    ${softColors.background}
  );
  background-size: 200% 200%;
  animation: shimmer 3s infinite;

  @keyframes shimmer {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const TabsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${softColors.border};
`

const TabButton = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  color: ${(props) =>
    props.isActive ? softColors.icontext : softColors.textLight};
  border-bottom: 2px solid
    ${(props) => (props.isActive ? softColors.icontext : 'transparent')};
  font-weight: ${(props) => (props.isActive ? '600' : '400')};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${softColors.icontext};
  }
`

const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`

const PaginationButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? softColors.background : softColors.primary};
  color: ${(props) => (props.disabled ? softColors.textLight : 'white')};
  border: 1px solid ${softColors.border};
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 0.25rem;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? softColors.background : softColors.icontext};
  }
`

const DisabledLink = styled.div`
  color: white;
  background-color: #caaae1;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  width: auto;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
`

const CustomTooltip = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${softColors.text};
  color: ${softColors.card};
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 10;

  ${DisabledLink}:hover & {
    opacity: 1;
  }
`
const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  border-bottom: 1px solid ${softColors.border};
  padding-bottom: 1rem;
`

const DemographicTab = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: ${(props) =>
    props.isActive ? softColors.icontext : softColors.background};
  color: ${(props) => (props.isActive ? 'white' : softColors.textLight)};
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.isActive ? softColors.icontext : '#e2e8f0'};
  }
`

const EmployerDash = () => {
  const [employerProfile, setEmployerProfile] = useState(null)
  const [employerMembers, setEmployerMembers] = useState([])
  const [employerJobs, setEmployerJobs] = useState([])
  const [activeJobsCount, setActiveJobsCount] = useState(0)
  const [totalApplicants, setTotalApplicants] = useState(0)
  const [totalHired, setTotalHired] = useState(0)
  const [activeTab, setActiveTab] = useState('all')
  const [selectedJob, setSelectedJob] = useState('all')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [user, setUser] = useState(null)
  const navigate = useNavigate()
  const [jobApplicants, setJobApplicants] = useState([])
  const [jobSeekers, setJobSeekers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [employerIdMapping, setEmployerIdMapping] = useState({}) // Add this new state

  const totalPages = Math.ceil(jobSeekers.length / itemsPerPage)
  const [demographicTab, setDemographicTab] = useState('all') // Add this with your other state declarations
  const [demographicData, setDemographicData] = useState({
    gender: {},
    race: {},
    athlete_status: {},
    veteran_status: {},
    job_level: {}
  })

  const [isNewProfile, setIsNewProfile] = useState(false)
  const [openSections, setOpenSections] = useState({
    profile: false,
    postJob: false,
    survey: false
  })
  const [selectedRole, setSelectedRole] = useState('All Roles')

  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }))
  }
  useEffect(() => {
    const fetchEmployerJobsWithApplicants = async () => {
      if (!user) return

      try {
        const jobsWithApplicantCount =
          await getEmployerJobPostingsWithApplicants(user.uid)
        setEmployerJobs(jobsWithApplicantCount)

        // Calculate stats based on applicant count directly from job objects
        const activeJobs = jobsWithApplicantCount.filter(
          (job) => job.status === 'active'
        )
        const totalApplicantsCount = jobsWithApplicantCount.reduce(
          (total, job) => total + job.applicant_count,
          0
        )

        setActiveJobsCount(activeJobs.length)
        setTotalApplicants(totalApplicantsCount) // Set total applicants from real-time counts
        setTotalHired(
          jobsWithApplicantCount.filter((job) => job.status === 'hired').length
        )
      } catch (error) {
        console.error('Error fetching employer jobs:', error)
      }
    }

    if (user) {
      fetchEmployerJobsWithApplicants()
    }
  }, [user])

  useEffect(() => {
    const fetchApplicants = async () => {
      if (employerJobs.length === 0) return

      try {
        // Implement your logic to fetch applicants for all jobs
        // This would depend on your database structure
        const applicants = []
        setJobApplicants(applicants)
      } catch (error) {
        console.error('Error fetching applicants:', error)
      }
    }

    fetchApplicants()
  }, [employerJobs])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser)
      if (!currentUser) {
        setLoading(false)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const fetchEmployerData = async () => {
      if (!user) {
        setLoading(false)
        return
      }

      try {
        const profileData = await getEmployerProfile(user.uid)

        if (
          profileData.plan_type === 'freetrial' &&
          profileData.company_name === 'Company Name Not Set'
        ) {
          setIsNewProfile(true)
        }

        const membersData = await getEmployerMembers(user.uid)

        setEmployerProfile(profileData)
        setEmployerMembers(membersData)
      } catch (err) {
        if (err.message === 'User not found') {
          setError('User account not found. Please contact support.')
        } else {
          setError(`Failed to load employer data: ${err.message}`)
        }
      } finally {
        setLoading(false)
      }
    }

    if (user) {
      fetchEmployerData()
    }
  }, [user])

  useEffect(() => {
    const fetchJobSeekers = async () => {
      try {
        const jobSeekersData = await getJobSeekers(900)
        // Log the raw data to see exactly what we're getting
        console.log('Raw Job Seekers Data:', jobSeekersData)

        // Verify each jobseeker's favorites field
        jobSeekersData.forEach((js) => {
          console.log(`JobSeeker ${js.id} favorites:`, js.favorites)
        })

        setJobSeekers(jobSeekersData)
      } catch (error) {
        console.error('Error fetching job seekers:', error)
        setError(error.message)
      }
    }

    if (user) {
      fetchJobSeekers()
    }
  }, [user])

  const getFavoritedJobSeekers = () => {
    if (!user || !jobSeekers) return []

    const favoritedSeekers = jobSeekers.filter((js) => {
      // Check and log each job seeker's favorites field
      console.log(`Job Seeker ID: ${js.id}, Favorites:`, js.favorites)
      return js.favorites && js.favorites.includes(user.uid)
    })

    console.log('Favorited Seekers:', favoritedSeekers)
    return favoritedSeekers
  }

  const renderFavoritesTab = () => {
    const favoritedSeekers = getFavoritedJobSeekers()

    if (favoritedSeekers.length === 0) {
      return (
        <div className="p-4 text-center">
          <p className="text-gray-600">
            No job seekers have favorited your company yet.
          </p>
        </div>
      )
    }

    return favoritedSeekers.map((jobSeeker) => (
      <ApplicantCard key={jobSeeker.id}>
        <ApplicantInfo>
          <ApplicantName>{jobSeeker.name}</ApplicantName>
          <div style={{ fontSize: '0.875rem', color: softColors.textLight }}>
            {jobSeeker.role || 'Role not specified'}
          </div>
        </ApplicantInfo>
        <DisabledLink
          onClick={() => handleNavigateToJobSeekerProfile(jobSeeker.id)}
        >
          View Profile
          <CustomTooltip>Will be available Oct 31st</CustomTooltip>
        </DisabledLink>
      </ApplicantCard>
    ))
  }

  useEffect(() => {
    const fetchDemographics = async () => {
      try {
        let userIds = []

        switch (demographicTab) {
          case 'applicants':
            // Get applicant IDs from jobs
            userIds = employerJobs.flatMap((job) =>
              job.applicants ? Object.keys(job.applicants) : []
            )
            break

          case 'favorites':
            // Get users who favorited this employer
            userIds = jobSeekers
              .filter((js) =>
                js.favorited_companies?.includes(employerProfile?.id)
              )
              .map((js) => js.id)
            break

          default:
            // Get all jobseeker IDs
            userIds = jobSeekers.map((seeker) => seeker.id)
        }

        if (userIds.length === 0) {
          setDemographicData({
            gender: [],
            race: [],
            athlete_status: [],
            veteran_status: [],
            job_level: []
          })
          return
        }

        const { demographicsData } = await getAllJobSeekerProfiles(userIds)

        const transformedData = {
          gender: demographicsData.gender
            ? Object.entries(demographicsData.gender).map(([name, value]) => ({
                name,
                value: value || 0
              }))
            : [],

          race: demographicsData.race
            ? Object.entries(demographicsData.race).map(([name, value]) => ({
                name,
                value: value || 0
              }))
            : [],

          athlete_status: Object.entries(
            demographicsData.athlete_status || {}
          ).map(([name, value]) => ({
            name,
            value: value || 0
          })),

          veteran_status: demographicsData.veteran_status
            ? Object.entries(demographicsData.veteran_status).map(
                ([name, value]) => ({
                  name,
                  value: value || 0
                })
              )
            : [],

          job_level: demographicsData.job_level
            ? Object.entries(demographicsData.job_level).map(
                ([name, value]) => ({
                  name,
                  value: value || 0
                })
              )
            : []
        }

        setDemographicData(transformedData)
      } catch (error) {
        console.error('Error fetching demographic data:', error)
        setDemographicData({
          gender: [],
          race: [],
          athlete_status: [],
          veteran_status: [],
          job_level: []
        })
      }
    }

    if (jobSeekers.length > 0 && employerProfile?.id) {
      fetchDemographics()
    }
  }, [demographicTab, jobSeekers, employerJobs, employerProfile?.id]) // Dependencies
  const handlePostJob = () => {
    navigate('/job-post-form')
  }

  useEffect(() => {
    const fetchDemographics = async () => {
      try {
        let userIds = []

        switch (demographicTab) {
          case 'applicants':
            // Get applicant IDs from jobs
            userIds = employerJobs.flatMap((job) =>
              job.applicants ? Object.keys(job.applicants) : []
            )
            break

          case 'favorites':
            // Get users who favorited this employer
            userIds = jobSeekers
              .filter((js) =>
                js.favorited_companies?.includes(employerProfile?.id)
              )
              .map((js) => js.id)
            break

          default:
            // Get all jobseeker IDs
            userIds = jobSeekers.map((seeker) => seeker.id)
        }

        if (userIds.length === 0) {
          setDemographicData({
            gender: [],
            race: [],
            athlete_status: [],
            veteran_status: [],
            job_level: []
          })
          return
        }

        const { demographicsData } = await getAllJobSeekerProfiles(userIds)

        const transformedData = {
          gender: demographicsData.gender
            ? Object.entries(demographicsData.gender).map(([name, value]) => ({
                name,
                value: value || 0
              }))
            : [],

          race: demographicsData.race
            ? Object.entries(demographicsData.race).map(([name, value]) => ({
                name,
                value: value || 0
              }))
            : [],

          athlete_status: Object.entries(
            demographicsData.athlete_status || {}
          ).map(([name, value]) => ({
            name,
            value: value || 0
          })),

          veteran_status: demographicsData.veteran_status
            ? Object.entries(demographicsData.veteran_status).map(
                ([name, value]) => ({
                  name,
                  value: value || 0
                })
              )
            : [],

          job_level: demographicsData.job_level
            ? Object.entries(demographicsData.job_level).map(
                ([name, value]) => ({
                  name,
                  value: value || 0
                })
              )
            : []
        }

        setDemographicData(transformedData)
      } catch (error) {
        console.error('Error fetching demographic data:', error)
        setDemographicData({
          gender: [],
          race: [],
          athlete_status: [],
          veteran_status: [],
          job_level: []
        })
      }
    }

    if (jobSeekers.length > 0 && employerProfile?.id) {
      fetchDemographics()
    }
  }, [demographicTab, jobSeekers, employerJobs, employerProfile?.id]) // Dependencies

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const paginatedJobSeekers = jobSeekers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const renderProfileCompletionSection = () => {
    if (!employerProfile?.intake_completed) {
      return (
        <OnboardingSection onClick={() => toggleSection('profile')}>
          <SectionHeader>
            <SectionTitle>
              <SectionIcon>🔥</SectionIcon>
              Complete your profile
            </SectionTitle>
            <ChevronDown
              size={20}
              style={{
                transform: openSections.profile ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.3s',
                cursor: 'pointer'
              }}
            />
          </SectionHeader>
          <SectionProgressBar>
            <SectionProgress width="30%" started={true} />
          </SectionProgressBar>
          <SectionContent isOpen={openSections.profile}>
            <SectionDescription>
              This information helps our AI personalize its talent matches to
              meet your top hiring goals. Once you complete this intake form -
              we will create your profile for you!
              <br></br> Note: You will not see your talent matches until you
              complete your profile.
            </SectionDescription>
            <Link href="/employer-intake">Edit Profile</Link>
          </SectionContent>
        </OnboardingSection>
      )
    }
    return null
  }

  const renderPostJobSection = () => {
    const hasJobs = employerJobs.length > 0

    if (!hasJobs) {
      return (
        <OnboardingSection onClick={() => toggleSection('postJob')}>
          <SectionHeader>
            <SectionTitle>
              <SectionIcon>📝</SectionIcon>
              Post a job
            </SectionTitle>
            <ChevronDown
              size={20}
              style={{
                transform: openSections.postJob ? 'rotate(180deg)' : 'none',
                transition: 'transform 0.3s',
                cursor: 'pointer'
              }}
            />
          </SectionHeader>
          <SectionProgressBar>
            <SectionProgress width="20%" started={false} />
          </SectionProgressBar>
          <SectionContent isOpen={openSections.postJob}>
            <SectionDescription>
              Post your first job to our curated talent community and receive
              top candidate matches instantly!
            </SectionDescription>
            <Link onClick={handlePostJob}>Post a Job</Link>
          </SectionContent>
        </OnboardingSection>
      )
    }
    return null
  }

  const activeJobs = employerJobs.filter((job) => job.status === 'active')

  const handleNavigateToJobSeekerProfile = (jobseekerId) => {
    navigate(`/jobseeker-profile-view/${jobseekerId}`)
  }

  const handleNavigateToSourcing = () => {
    navigate('/candidate-sourcing')
  }

  const roles = ['All Roles', ...new Set(jobSeekers.map((js) => js.role))]

  const filteredJobSeekers =
    selectedRole === 'All Roles'
      ? jobSeekers
      : jobSeekers.filter((js) => js.role === selectedRole)

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  if (!employerProfile) {
    return <div>No employer profile found.</div>
  }

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <WelcomeHeader>
          🏟 Welcome to Arena for MSBC, {employerProfile.company_name}!
        </WelcomeHeader>
        <Card style={{ marginBottom: '1.5rem' }}>
          <p>
            Your one-stop-shop to top talent in sports, media and entertainment
            is here. As the official Recruiting Sponsor for the Michigan Sport
            Business Conference, we're excited to help you connect with
            promising candidates.
          </p>
          <SectionTitle>What you can do:</SectionTitle>
          <ul>
            <li>🔎 View and source attendees from MSBC</li>
            <li>📣 Post a job directly to MSBC attendees</li>
            <li>
              🤝 Access AI-powered candidate matches ranked for your needs
            </li>
          </ul>
          <SectionTitle>Important Notes:</SectionTitle>
          <ul>
            <li>
              👀 You currently have access to view MSBC-specific talent on
              Arena.
            </li>

            <li>⏰ Your Arena for MSBC trial expires in on Nov. 30th, 2024.</li>
          </ul>
          <SectionTitle>Need Help?</SectionTitle>
          <ul>
            <li>📧 Email us: support@arenatalent.com</li>
          </ul>
          <p>
            🚀 Ready to find your next star for your team? Let's get started!
          </p>
        </Card>
        {renderProfileCompletionSection()}
        {renderPostJobSection()}
        <Grid>
          <StatCard>
            <div className="job-icon-div">
              <div>
                <IconWrapper bgColor={softColors.icons}>
                  <Briefcase size={20} />
                </IconWrapper>
              </div>
            </div>
            <div>
              <StatNumber color={softColors.icontext}>
                {activeJobsCount}
              </StatNumber>
              <StatLabel>Open Positions</StatLabel>
            </div>
            <ReviewButton href="/review-recommendations">
              View & Post Jobs
            </ReviewButton>
          </StatCard>
          <StatCard>
            <IconWrapper bgColor={softColors.icons}>
              <Users size={20} />
            </IconWrapper>
            <div>
              <StatNumber color={softColors.icontext}>
                {totalApplicants}
              </StatNumber>
              <StatLabel>Total Applicants</StatLabel>
            </div>
          </StatCard>

          <StatCard>
            <IconWrapper bgColor={softColors.icons}>
              <Award size={20} />
            </IconWrapper>
            <div>
              <StatNumber color={softColors.icontext}>{totalHired}</StatNumber>
              <StatLabel>Total Hires</StatLabel>
            </div>
          </StatCard>
        </Grid>
        <ComingSoonSection>
          <SectionHeader>
            <CardTitle>
              Demographics
              <GlowingDot />
            </CardTitle>
          </SectionHeader>

          <TabContainer>
            <DemographicTab
              isActive={demographicTab === 'all'}
              onClick={() => setDemographicTab('all')}
            >
              All MSBC Attendees
            </DemographicTab>
            <DemographicTab
              isActive={demographicTab === 'applicants'}
              onClick={() => setDemographicTab('applicants')}
            >
              Applicants
            </DemographicTab>
            {/* <DemographicTab
              isActive={demographicTab === 'favorites'}
              onClick={() => setDemographicTab('favorites')}
            >
              People who have favorited {employerProfile.company_name}
            </DemographicTab> */}
          </TabContainer>

          {demographicTab === 'all' ? (
            <DemographicInsights demographicData={demographicData} />
          ) : (
            <div
              style={{
                padding: '2rem',
                textAlign: 'center',
                color: softColors.textLight,
                fontSize: '0.875rem',
                fontStyle: 'italic'
              }}
            >
              {demographicTab === 'applicants'
                ? "To protect Job Seeker's privacy, we only provide demographic data if there are more than 5 applicants"
                : "To protect Job Seeker's privacy, we only provide demographic data if there are more than 5 applicants who have favorited your company"}
            </div>
          )}
        </ComingSoonSection>

        <Card style={{ marginTop: '2rem' }}>
          <CardTitle>MSBC Attendees</CardTitle>
          <TabsContainer>
            <TabButton
              isActive={activeTab === 'all'}
              onClick={() => setActiveTab('all')}
            >
              All Attendees
            </TabButton>
            {/* <TabButton
              isActive={activeTab === 'applicants'}
              onClick={() => setActiveTab('applicants')}
            >
              Your Applicants
            </TabButton>
            <TabButton
              isActive={activeTab === 'favorites'}
              onClick={() => setActiveTab('favorites')}
            >
              Favorites
            </TabButton> */}
          </TabsContainer>
          {/* Only show job filter in applicants tab */}
          {activeTab === 'applicants' && (
            <FilterContainer>
              <FilterDropdown
                value={selectedJob}
                onChange={(e) => setSelectedJob(e.target.value)}
              >
                <option value="all">All Jobs</option>
                {activeJobs.map((job) => (
                  <option key={job.id} value={job.id}>
                    {job.title}
                  </option>
                ))}
              </FilterDropdown>
            </FilterContainer>
          )}
          {/* All Attendees Tab */}
          {activeTab === 'all' && (
            <>
              {paginatedJobSeekers.map((jobSeeker) => (
                <ApplicantCard key={jobSeeker.id}>
                  <ApplicantInfo>
                    <ApplicantName>{jobSeeker.name}</ApplicantName>
                  </ApplicantInfo>

                  {/* Navigate to job seeker profile on click */}
                  <DisabledLink
                    onClick={() =>
                      handleNavigateToJobSeekerProfile(jobSeeker.id)
                    }
                  >
                    View Profile
                  </DisabledLink>
                </ApplicantCard>
              ))}
              {/* Pagination Controls */}
              <PaginationContainer>
                <PaginationButton
                  onClick={() => handlePageChange('prev')}
                  disabled={currentPage === 1}
                >
                  Previous
                </PaginationButton>
                <PaginationButton
                  onClick={() => handlePageChange('next')}
                  disabled={currentPage === totalPages}
                >
                  Next
                </PaginationButton>
              </PaginationContainer>
            </>
          )}
          {/* Applicants Tab
          {activeTab === 'applicants' && (
            <>
              {selectedJob === 'all'
                ? // Show all applicants for active jobs in a flat list
                  jobApplicants
                    .filter((applicant) =>
                      activeJobs.some((job) => job.id === applicant.jobId)
                    )
                    .map((applicant) => (
                      <ApplicantCard key={applicant.id}>
                        <ApplicantInfo>
                          <ApplicantName>{applicant.name}</ApplicantName>
                          <div
                            style={{
                              fontSize: '0.875rem',
                              color: softColors.textLight
                            }}
                          >
                            Applied for:{' '}
                            {
                              activeJobs.find(
                                (job) => job.id === applicant.jobId
                              )?.title
                            }
                          </div>
                        </ApplicantInfo>
                        <MatchWrapper>
                          <LockIcon size={16} />
                          <MatchText>% Match</MatchText>
                          <Tooltip>
                            AI Matches will be available on Thursday October
                            31st
                          </Tooltip>
                        </MatchWrapper>
                      </ApplicantCard>
                    ))
                : // Show applicants for specific active job
                  jobApplicants
                    .filter((applicant) => applicant.jobId === selectedJob)
                    .map((applicant) => (
                      <ApplicantCard key={applicant.id}>
                        <ApplicantInfo>
                          <ApplicantName>{applicant.name}</ApplicantName>
                        </ApplicantInfo>
                        <MatchWrapper>
                          <LockIcon size={16} />
                          <MatchText>% Match</MatchText>
                          <Tooltip>
                            AI Matches will be available on Thursday October
                            31st
                          </Tooltip>
                        </MatchWrapper>
                        <CustomTooltip>
                          Will be available Oct 31st
                        </CustomTooltip>
                      </ApplicantCard>
                    ))}
            </>
          )}

          {/* Favorites Tab */}
          {/* {activeTab === 'favorites' && renderFavoritesTab()} */}
          <DisabledLink
            style={{
              display: 'inline-block',
              marginTop: '1.5rem',
              position: 'relative'
            }}
            onClick={handleNavigateToSourcing}
          >
            View All Attendees
          </DisabledLink>
        </Card>
        <Card style={{ marginTop: '2rem' }}>
          <CardTitle>How Our AI Works</CardTitle>
          <p
            style={{
              fontSize: '0.875rem',
              color: softColors.textLight,
              lineHeight: '1.5'
            }}
          >
            Our AI-powered matching system uses advanced algorithms to analyze
            candidate profiles and job requirements. It considers factors such
            as skills, experience, education, and cultural fit to provide
            accurate match percentages. The system continuously learns and
            improves based on hiring outcomes and feedback.
          </p>
        </Card>
      </MainContent>
    </Container>
  )
}

export default EmployerDash
